import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeSelectedAccount } from "../reducer";
import { useTranslation } from "react-i18next";
import ServiceProvider from "@client.services/provider";
import { get } from "lodash";
import DataTable from "@client.components/DataTable";
import DeviceTypes from "@client.enums/deviceTypes";
import "./Details.scss";

const DevicesTab = () => {
  const selectedAccount = useSelector(makeSelectedAccount);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    if (selectedAccount.Id) {
      setLoading(true);
      ServiceProvider.Device.getAll(selectedAccount.Id)
        .then((resp) => {
          setDevices(get(resp, "data.devices.results", []));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedAccount.Id]);

  const COLUMNS = [
    {
      field: "deviceName",
      tooltipField: "DeviceName",
      headerName: t("pages.Devices.columns.name"),
    },
    {
      field: "deviceName",
      tooltipField: "DeviceName",
      headerName: t("pages.Devices.columns.name"),
    },
    {
      field: "deviceType",
      headerName: t("pages.Devices.columns.type"),
      cellClass: "data-table-cell",
      // headerClass: "align-center", // Optional, align header text
      cellRenderer: (data) => {
        let label = "pages.Devices.na";
        if (data.data.type) {
          label =
            data.data.type === DeviceTypes.Dynamic
              ? "pages.Devices.type.dynamic"
              : "pages.Devices.type.static";
        }
        return t(label);
      },
    },
    {
      field: "isOnline",
      headerName: t("pages.Devices.columns.status"),
      cellClass: "data-table-cell",
      cellRenderer: (data) => {
        const statusClassName = data.data.isOnline ? "on" : "off";
        const status = t(
          data.data.isOnline
            ? "pages.Devices.status.online"
            : "pages.Devices.status.offline"
        );
        return <span id="statusSpan"
         className={`status ${statusClassName}`}>{status}</span>;
      },
    },
  ];

  return (
    <div className="devices-tab">
      <DataTable columns={COLUMNS} data={devices} isLoading={loading} />
    </div>
  );
};

DevicesTab.propTypes = {};

export default DevicesTab;
