import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ServiceProvider from "@client.services/provider";

export const getAllUserLogsAsync = createAsyncThunk(
  "userLogs/getAll",
  async (params) => {
    const response = await ServiceProvider.UserLogService.getAll(params);
    return response.data.userLogs;
  }
);

export const createUserLogAsync = createAsyncThunk(
  "userLogs/create",
  async (userLogData) => {
    const response = await ServiceProvider.UserLogService.create(userLogData);
    return response.data.createUserLog;
  }
);

const userLogsSlice = createSlice({
  name: "userLogs",
  initialState: {
    results: [],
    count: 0,
    loading: false,
    creatingLog: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUserLogsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUserLogsAsync.fulfilled, (state, action) => {
        state.results = action.payload.results;
        state.count = action.payload.count;
        state.loading = false;
      })
      .addCase(getAllUserLogsAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createUserLogAsync.pending, (state) => {
        state.creatingLog = true;
      })
      .addCase(createUserLogAsync.fulfilled, (state, action) => {
        state.creatingLog = false;
        state.results.push(action.payload.userLog);
      })
      .addCase(createUserLogAsync.rejected, (state) => {
        state.creatingLog = false;
      });
  },
});

export const makeUserLogs = (state) => state.userLogs;

export default userLogsSlice.reducer;
