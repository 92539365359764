




// // Blob


// import React, { useEffect, useState } from "react";
// import SvgIcon from "@client.core.components/SvgIcon";
// import NoDataPlaceholder from "@client.core.components/NoDataPlaceholder";

// export const MediaFile = (params) => {
//   const [videoBlobUrl, setVideoBlobUrl] = useState(null); // Holds the generated Blob URL
//   const [isError, setIsError] = useState(false); // Error state
//   const { src, fallbackElement, isNotFound, onEnded, ...mediaProps } = params;

//   useEffect(() => {
//     if (src) {
//       // Fetch the video from the server and convert it to a Blob URL
//       fetch(src)
//         .then((response) => {
//           console.log("Response:", response);
//           if (!response.ok) {
//             throw new Error(`HTTP error! Status: ${response.status}`);
//           }
//           return response.blob(); // Convert the response to a Blob
//         })
//         .then((blob) => {
//           const url = URL.createObjectURL(blob); // Create a Blob URL
//           setVideoBlobUrl(url); // Save the Blob URL for rendering
//         })
//         .catch((error) => {
//           console.error("Error fetching video:", error);
//           setIsError(true); // Update error state
//         });
//     }
//   }, [src]);

//   // Render fallback if video fails
//   if (isError || isNotFound) {
//     return fallbackElement ? (
//       fallbackElement()
//     ) : (
//       <SvgIcon name="file-not-found" className={params.className} />
//     );
//   }

//   // Render video if Blob URL is ready
//   return videoBlobUrl ? (
//     <video
//       controls
//       src={videoBlobUrl} // Use the generated Blob URL
//       onEnded={onEnded}
//       {...mediaProps}
//     >
//       Your browser does not support the video tag.
//     </video>
//   ) : (
//     <p>Loading video...</p> // Loading state
//   );
// };





















// import React, { useEffect, useRef, useState } from "react";
// import SvgIcon from "@client.core.components/SvgIcon";
// import NoDataPlaceholder from "@client.core.components/NoDataPlaceholder";

// export const MediaFile = (params) => {
//   // console.log("From MediaFile.js params:", params);

//   const [isImageNotFound, setIsImageNotFound] = useState(false);
//   const {
//     fallbackElement,
//     isNotFound,
//     onLoad,
//     showThumbnail,
//     src,
//     ...mediaProps
//   } = params;
//   // const videoRef = useRef(null);
//   const videoRef = useRef(null);
//   const getFileExtension = (url) => {
//     if (!url) {
//       return null;
//     }

//     const urlParts = url.split(".");
//     const extension = urlParts[urlParts.length - 1].toLowerCase();

//     return extension;
//   };

//   const isVideoFile = (url) => ["mp4"].includes(getFileExtension(url));

//   useEffect(() => {
//     if (isVideoFile(src) && videoRef.current && !showThumbnail) {
//       videoRef.current.load();
//       videoRef.current.play();
//     }
//   }, [src]);

//   const renderMediaFile = () => {
//     if (!src) {
//       return <NoDataPlaceholder description="No source provided" />;
//     }

//     const fileExt = getFileExtension(src);
//     if (!["mp4", "jpg", "jpeg", "png", "gif", "webp", "svg", "bmp", "tiff", "ico", "cur", "avif", "webm"].includes(fileExt)) {
//       return <NoDataPlaceholder description={`Unsupported format: ${fileExt}`} />;
//     }

//     return isVideoFile(src) ? (
//       <video
//         ref={videoRef}
//         muted
//         autoPlay={!showThumbnail}
//         loop={!showThumbnail}
//         preload="metadata"
//         onLoadedMetadata={(e) => {
//           if (onLoad) {
//             onLoad({
//               width: e.target.videoWidth,
//               height: e.target.videoHeight,
//             });
//           }
//         }}
//         onError={() => {
//           setIsImageNotFound(true);
//           console.error("Video playback error for source:", src);
//           if (params.onError) params.onError();
//         }}
//         {...mediaProps}
//       >
//         <source
//           src={showThumbnail ? `${src}#t=1` : src}
//           type="video/mp4"
//         />
//         Your browser does not support the video tag.
//       </video>
//     ) : (
//       <img
//         src={src}
//         {...mediaProps}
//         onLoad={(e) => {
//           if (onLoad) {
//             onLoad({
//               width: e.target.naturalWidth,
//               height: e.target.naturalHeight,
//             });
//           }
//         }}
//         onError={() => {
//           setIsImageNotFound(true);
//           console.error("Image load error for source:", src);
//           if (params.onError) params.onError();
//         }}
//       />
//     );
//   };

//   return isNotFound || isImageNotFound ? (
//     fallbackElement ? (
//       fallbackElement()
//     ) : (
//       <SvgIcon name="file-not-found" className={params.className} />
//     )
//   ) : (
//     renderMediaFile(mediaProps)
//   );
// };






















import React, { useEffect, useRef, useState } from "react";
import SvgIcon from "@client.core.components/SvgIcon";
import NoDataPlaceholder from "@client.core.components/NoDataPlaceholder";

export const MediaFile = (params) => {
  // console.log("From MediaFile.js params:", params);
  const [isImageNotFound, setIsImageNotFound] = useState(false);
  const {
    fallbackElement,
    isNotFound,
    onLoad,
    showThumbnail,
    src,
    ...mediaProps
  } = params;
  // const videoRef = useRef(null);
  const videoRef = useRef(null);
  const getFileExtension = (url) => {
    if (!url) {
      return null;
    }

    const urlParts = url.split(".");
    const extension = urlParts[urlParts.length - 1].toLowerCase();

    return extension;
  };

  const isVideoFile = (url) => ["mp4"].includes(getFileExtension(url));

  useEffect(() => {
    if (isVideoFile(src) && videoRef.current && !showThumbnail) {
      videoRef.current.load();
      videoRef.current.play();
    }
  }, [src]);

  const renderMediaFile = (mediaParams) => {
    if (getFileExtension(src) === "avi") {
      if (mediaParams.onError) mediaParams.onError();
      return <NoDataPlaceholder description="AVI format is not supported" />;
    }
    return isVideoFile(src) ? (
      <video
        // ref={videoRef}
        ref={videoRef}
        muted
        autoPlay={!showThumbnail}
        loop={!showThumbnail}
        onLoadedMetadata={(e) => {
          if (onLoad) {
            onLoad({
              width: e.nativeEvent.target.videoWidth,
              height: e.nativeEvent.target.videoHeight,
            });
          }
        }}
        onError={() => {
          setIsImageNotFound(true);
          if (params.onError) params.onError();
        }}
        {...mediaParams}
      >
        <source src={showThumbnail ? `${src}#t=1` : src} type={`video/mp4`} />
        Your browser does not support the video tag.
      </video>
    ) : (
      <img
        src={src}
        {...mediaParams}
        onLoad={(e) => {
          if (onLoad) {
            onLoad({
              width: e.target.naturalWidth,
              height: e.target.naturalHeight,
            });
          }
        }}
        onError={() => {
          setIsImageNotFound(true);
          if (params.onError) params.onError();
        }}
      />
    );
  };

  return isNotFound || isImageNotFound ? (
    fallbackElement ? (
      fallbackElement()
    ) : (
      <SvgIcon name="file-not-found" className={params.className} />
    )
  ) : (
    renderMediaFile(mediaProps)
  );
};
