import React from "react";
import "./Checkbox.scss";

interface CheckboxProps {
  value: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
  className?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  value = false,
  onChange,
  label = "",
  className,
}) => {
  return (
    <div className={`checkbox-container ${className || ""}`}>
      <label className="container">
        {label}
        <input
          type="checkbox"
          checked={value}
          onChange={(event) => onChange(event.target.checked)}
        />
        <span className="checkmark"></span>
      </label>
    </div>
  );
};

export default Checkbox;
