import React, { useEffect, useState } from "react";
import Button from "@client.core.components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDevicesAsync,
  makeDevices,
  makeDevicesLoading,
  processFlickrSignalAsync,
} from "@client.pages/Devices/reducer";
import Loader from "@client.core.components/Loader";
import Dropdown from "@client.core.components/Dropdown";
import useToast from "@client.hooks/useToast";
import "./FlickrDemo.scss";

const FlickrDemoPage = () => {
  const devicesData = useSelector(makeDevices);
  const isLoading = useSelector(makeDevicesLoading);
  const dispatch = useDispatch();
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [selectedMac, setSelectedMac] = useState(null);
  const { showToastAfterRequest } = useToast();

  useEffect(() => {
    dispatch(getAllDevicesAsync());
  }, [dispatch]);

  if (devicesData.loading || isLoading) {
    return (
      <div className="flickr-demo">
        <Loader loading={true} />
      </div>
    );
  }

  const processSignal = async (signal) => {
    const resp = await dispatch(
      processFlickrSignalAsync({ macAddress: selectedMac, signal })
    );
    showToastAfterRequest(resp, "Signal has been sent successfully");
  };

  return (
    <div className="flickr-demo">
      {devicesData.devices && selectedDeviceId !== null ? (
        <span>Mac: {selectedMac}</span>
      ) : (
        <span>&nbsp;</span>
      )}
      <Dropdown
        items={devicesData.devices}
        disabled={devicesData.devices.length === 0}
        defaultValue={selectedDeviceId}
        placeholder="Please select device"
        valueKey="lookupId"
        labelKey="displayName"
        onSelect={(v) => {
          setSelectedDeviceId(v);

          const device = devicesData.devices.find((d) => d.lookupId === v);
          setSelectedMac(device.macAddress);
        }}
      />
      <div className="buttons">
        <Button
          icon="active-campaigns"
          className="left"
          disabled={selectedDeviceId === null}
          onClick={() => processSignal("left")}
        />
        <Button
          icon="active-campaigns"
          className="right"
          disabled={selectedDeviceId === null}
          onClick={() => processSignal("right")}
        />
      </div>
      <Button
        icon="stop"
        className="stop"
        disabled={selectedDeviceId === null}
        onClick={() => processSignal("stop")}
      />

      <Button
        text="Exit"
        disabled={selectedDeviceId === null}
        onClick={() => processSignal("exit")}
      />
    </div>
  );
};

export default FlickrDemoPage;
