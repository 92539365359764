type Listener = (args?: any) => void;

export default class EventEmitterService {
  private events: { [event: string]: Listener[] } = {};

  constructor() {}

  on(event: string, listener: Listener, unique?: boolean): void {
    if (unique) {
      this.removeAllListeners(event);
    }

    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(listener);
  }

  emit(event: string, args?: any): void {
    if (this.events[event]) {
      this.events[event].forEach((listener) => {
        listener(args);
      });
    }
  }

  removeAllListeners(event?: string): void {
    if (event) {
      delete this.events[event];
    } else {
      this.events = {};
    }
  }
}
