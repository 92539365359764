import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CampaignsTabsEnum = Object.freeze({
  AllTheRest: 0,
  Archived: 1,
  Drafts: 2,
});

const CampaignsInnerTabs = ({ value, onSelect }) => {
  const { t } = useTranslation();

  const tabs = [
    { id: CampaignsTabsEnum.AllTheRest, name: t("pages.campaings.tabs.allTheRest") },
    { id: CampaignsTabsEnum.Archived, name: t("pages.campaings.tabs.archived") },
    { id: CampaignsTabsEnum.Drafts, name: t("pages.campaings.tabs.drafts") },
  ];

  return (
    <div className="archived-campaign-tabs">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`tab-button ${value === tab.id ? "active" : ""}`}
          onClick={() => onSelect(tab.id)}
        >
          {tab.name}
        </button>
      ))}
    </div>
  );
};

CampaignsInnerTabs.propTypes = {
  value: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export { CampaignsTabsEnum };
export default CampaignsInnerTabs;














// import React from "react";
// import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";

// const ArchivedTabsEnum = Object.freeze({
//   All_The_Rest: 0,
//   Archived: 1,
//   Draft: 2,
// });

// const ArchivedCampaignInnerTabs = ({ value, onSelect }) => {
//   const { t } = useTranslation();

//   const tabs = [
//     {
//       id: ArchivedTabsEnum.Archived,
//       name: t("pages.campaings.tabs.archived"),
//       icon: "stats-icon", // Customize icon classes if needed
//     },
//     {
//       id: ArchivedTabsEnum.Draft,
//       name: t("pages.campaings.tabs.draft"),
//       icon: "insights-icon", // Customize icon classes if needed
//     },
//     {
//       id: ArchivedTabsEnum.All_The_Rest,
//       name: t("pages.campaings.tabs.allTheRest"),
//       icon: "all-the-rest-icon", // Customize icon classes if needed
//     },
//   ];

//   return (
//     <div className="campaign-tabs">
//       {tabs.map((tab) => (
//         <button
//           key={tab.id}
//           className={`tab-button ${value === tab.id ? "active" : ""}`}
//           onClick={() => onSelect(tab.id)}
//         >
//           {tab.name}
//         </button>
//       ))}
//     </div>
//   );
// };

// ArchivedCampaignInnerTabs.propTypes = {
//   value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//   onSelect: PropTypes.func,
// };

// export { ArchivedTabsEnum };
// export default ArchivedCampaignInnerTabs;




