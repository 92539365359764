import BaseModel from "./base";
import { get } from "lodash";

class AdvertiserModel extends BaseModel {
  constructor(advertiser = {}, msg = {}) {
    super(msg);
    // Account Fields
    // this.companyName = get(advertiser, "companyName", "ttt");
    this.companyName = get(advertiser, "companyName", "");
    this.entity = get(advertiser, "entity", parseInt(2));
    // this.companyUrl = get(advertiser, "companyUrl", "asssas");
    this.companyUrl = get(advertiser, "companyUrl", "");
    // this.companyRegistrationNumber = get(advertiser, "companyRegistrationNumber", "sassas");
    this.companyRegistrationNumber = get(advertiser, "companyRegistrationNumber", "");
    // this.companyVatNumber = get(advertiser, "companyVatNumber", "sssssss");
    this.companyVatNumber = get(advertiser, "companyVatNumber", "");
    this.companyAddress = {
      lineOne: get(advertiser, "companyAddress.lineOne", ""),
      city: get(advertiser, "companyAddress.city", ""),
      postalCode: get(advertiser, "companyAddress.postalCode", ""),
      country: get(advertiser, "companyAddress.country", ""),
    };

    // User Fields
    // this.firstName = get(advertiser, "firstName", "tttt");
    this.firstName = get(advertiser, "firstName", "");
    // this.lastName = get(advertiser, "lastName", "tttt");
    this.lastName = get(advertiser, "lastName", "");
    // this.emailAddress = get(advertiser, "emailAddress", "tttt@t.com");
    this.emailAddress = get(advertiser, "emailAddress", "");
    // this.password = get(advertiser, "password", "123456A");
    this.password = get(advertiser, "password", "");
    this.role = get(advertiser, "role", parseInt("1"));
    // this.phoneNumber = get(advertiser, "phoneNumber", "1211113456");
    this.phoneNumber = get(advertiser, "phoneNumber", "");
    this.avatarUrl = get(advertiser, "avatarUrl", "");

    this.validationMsgs = msg;
    this.validationRules = {
      firstName: { presence: { allowEmpty: true, message: msg.firstName || "" } },
      lastName: { presence: { allowEmpty: true, message: msg.lastName || "" } },
      emailAddress: { presence: { allowEmpty: true, message: msg.emailAddress || "" } },
      role: { presence: { allowEmpty: true, message: msg.role || "" } },
      entity: { presence: { allowEmpty: true, message: msg.entity || "" } },
      phoneNumber: { presence: { allowEmpty: true, message: msg.phoneNumber || "" } },
      password: { presence: { allowEmpty: true, message: msg.password || "" } },
      companyName: { presence: { allowEmpty: true, message: msg.companyName || "" } },
      companyRegistrationNumber: { presence: { allowEmpty: true, message: msg.companyRegistrationNumber || "" } },
      companyVatNumber: { presence: { allowEmpty: true, message: msg.companyVatNumber || "" } },
      companyAddress: {
        presence: { allowEmpty: true, message: msg.companyAddress || "" },
      },
    };
  }

  get fields() {
    return {
      account: {
        companyName: this.companyName,
        entity: this.entity,
        companyUrl: this.companyUrl,
        companyRegistrationNumber: this.companyRegistrationNumber,
        companyVatNumber: this.companyVatNumber,
        companyAddress: this.companyAddress,
      },
      user: {
        firstName: this.firstName,
        lastName: this.lastName,
        emailAddress: this.emailAddress,
        password: this.password,
        phoneNumber: this.phoneNumber,
        avatarUrl: this.avatarUrl,
        role: this.role,
      },
    };
  }
}

export default AdvertiserModel;



















// // C:\Users\Admin\Desktop\WORK2\work\AdPort\Frontend\src\models\advertiser.js
// import BaseModel from "./base";
// import { get } from "lodash";

// class AdvertiserModel extends BaseModel {
//   constructor(advertiser = {}, msg = {}) {
//     super(msg);
//     // Account Fields
//     this.companyName = get(advertiser, "companyName", "ttt");
//     this.entity = get(advertiser, "entity", 3);
//     this.companyUrl = get(advertiser, "companyUrl", "asssas");
//     // this.description = get(advertiser, "description", "asssas");
//     this.companyRegistrationNumber = get(advertiser, "companyRegistrationNumber", "sassas");
//     this.companyVatNumber = get(advertiser, "companyVatNumber", "sssssss");
    
//     // User Fields
//     this.firstName = get(advertiser, "firstName", "tttt");
//     this.lastName = get(advertiser, "lastName", "tttt");
//     this.emailAddress = get(advertiser, "emailAddress", "tttt@t.com");
//     this.password = get(advertiser, "password", "123456A");
//     this.role = get(advertiser, "role", parseInt("1"));
//     this.phoneNumber = get(advertiser, "phoneNumber", "1211113456");
//     this.avatarUrl = get(advertiser, "avatarUrl", "");

//     this.validationMsgs = msg;
//     this.validationRules = {
//       firstName: { presence: { allowEmpty: true, message: msg.firstName || "" } },
//       lastName: { presence: { allowEmpty: true, message: msg.lastName || "" } },
//       emailAddress: { presence: { allowEmpty: true, message: msg.emailAddress || "" } },
//       role: { presence: { allowEmpty: true, message: msg.role || "" } },
//       phoneNumber: { presence: { allowEmpty: true, message: msg.phoneNumber || "" } },
//       password: { presence: { allowEmpty: true, message: msg.password || "" } },
//       companyName: { presence: { allowEmpty: true, message: msg.companyName || "" } },
//       companyRegistrationNumber: { presence: { allowEmpty: true, message: msg.companyRegistrationNumber || "" } },
//       companyVatNumber: { presence: { allowEmpty: true, message: msg.companyVatNumber || "" } },
//     };
//   }

//   get fields() {
//     return {
//       account: {
//         companyName: this.companyName,
//         entity: this.entity,
//         companyUrl: this.companyUrl,
//         // description: this.description,
//         companyRegistrationNumber: this.companyRegistrationNumber,
//         companyVatNumber: this.companyVatNumber,
//       },
//       user: {
//         firstName: this.firstName,
//         lastName: this.lastName,
//         emailAddress: this.emailAddress,
//         password: this.password,
//         phoneNumber: this.phoneNumber,
//         avatarUrl: this.avatarUrl,
//         role: this.role,
//       },
//     };
//   }
// }

// export default AdvertiserModel;















// import BaseModel from "./base";
// import { get } from "lodash";

// class AdvertiserModel extends BaseModel {
//   constructor(advertiser = {}, msg = {}) {
//     super(msg);
//     this.firstName = get(advertiser, "firstName", "");
//     this.lastName = get(advertiser, "lastName", "");
//     this.emailAddress = get(advertiser, "emailAddress", "");
//     this.password = get(advertiser, "password", "");
//     this.role = get(advertiser, "role", null);
//     this.phoneNumber = get(advertiser, "phoneNumber", "");
//     this.companyName = get(advertiser, "companyName", "");
//     this.currencyCode = get(advertiser, "currencyCode", "");
//     this.currencySymbol = get(advertiser, "currencySymbol", "");

//     this.validationMsgs = msg;
//     this.validationRules = {
//       firstName: {
//         presence: { allowEmpty: false, message: msg.firstName || "" },
//       },
//       lastName: {
//         presence: { allowEmpty: false, message: msg.lastName || "" },
//       },
//       emailAddress: {
//         presence: { allowEmpty: false, message: msg.emailAddress || "" },
//         // emailAddress: { message: msg.invalidEmail || "" },
//       },
//       role: {
//         presence: { allowEmpty: false, message: msg.role || "" },
//       },
//       phoneNumber: {
//         presence: { allowEmpty: false, message: msg.phoneNumber || "" },
//       },
//       // companyName: {
//       //   presence: { allowEmpty: false, message: msg.companyName || "" },
//       // },
//       password: {
//         presence: { allowEmpty: false, message: msg.password || "" },
//       },
//       // currencyCode: {
//       //   presence: { allowEmpty: false, message: msg.currencyCode || "USD"}
//       // },
//       // currencySymbol: {
//       //   presence: { allowEmpty: false, message: msg.currencyCode || "$"}
//       // }
//     };
//   }

//   get fields() {
//     return {
//       firstName: this.firstName,
//       lastName: this.lastName,
//       emailAddress: this.emailAddress,
//       password: this.password,
//       role: this.role,
//       phoneNumber: this.phoneNumber,
//       companyName: this.companyName,
//       currencyCode: this.currencyCode,
//       currencySymbol: this.currencySymbol,
//     };
//   }
// }

// export default AdvertiserModel;
