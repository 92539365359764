import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";
import ToggleButton from "@client.core.components/ToggleButton";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="age-tooltip">
        {`People at age ${label}: ${data.qty}`}
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.any,
  payload: PropTypes.any,
  label: PropTypes.any,
};

const AgeChart = ({ data }) => {
  // console.log("data", data);
  const { t } = useTranslation();
  const [isMale, setIsMale] = useState(true);
  const [isFemale, setIsFemale] = useState(true);

  if (!data || !data.ageGraph || data.ageGraph.length === 0) {
    return (
      <div className="data-analyzer-chart">
        <div className="chart-title">{t("pages.DataAnalyzer.ageChart.title")}</div>
        <p>{t("pages.DataAnalyzer.noData")}</p>
      </div>
    );
  }

  // Process data for the chart from `ageGraph`
  const dataToShow = data.ageGraph.reduce((acc, d) => {
    const { age, male, female } = d;

    // Include male/female filtering
    let qty = 0;
    if (isMale) qty += male || 0;
    if (isFemale) qty += female || 0;

    if (qty > 0) {
      acc.push({ age, qty });
    }
    return acc;
  }, []);

  return (
    <div className="data-analyzer-chart">
      <div className="data-analyzer-top-bar">
        <div className="chart-title">
          {t("pages.DataAnalyzer.ageChart.title")}
        </div>
        <div className="filter-buttons">
          <ToggleButton
            text={t("pages.DataAnalyzer.gender.female")}
            value={isFemale}
            onChange={setIsFemale}
          />
          <ToggleButton
            text={t("pages.DataAnalyzer.gender.male")}
            value={isMale}
            onChange={setIsMale}
          />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={dataToShow}
          margin={{ top: 20, right: 30, left: -20, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="age" name="Age" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="qty" fill="#2D99FF" name={t("pages.DataAnalyzer.quantity")} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

AgeChart.propTypes = {
  data: PropTypes.shape({
    ageGraph: PropTypes.arrayOf(
      PropTypes.shape({
        age: PropTypes.number.isRequired,
        male: PropTypes.number,
        female: PropTypes.number,
      })
    ),
    ageRangeFemaleHigh: PropTypes.number,
    ageRangeFemaleLow: PropTypes.number,
    ageRangeMaleHigh: PropTypes.number,
    ageRangeMaleLow: PropTypes.number,
    impressions: PropTypes.number,
    peopleAgeCount: PropTypes.arrayOf(
      PropTypes.shape({
        age: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
      })
    ),
    ivpGraph: PropTypes.arrayOf(
      PropTypes.shape({
        datetime: PropTypes.string.isRequired,
        impressions: PropTypes.number,
        peopleDetected: PropTypes.number,
        views: PropTypes.number,
      })
    ),
    peopleDetected: PropTypes.number,
    peopleFemale: PropTypes.number,
    peopleMale: PropTypes.number,
    playLocations: PropTypes.arrayOf(
      PropTypes.shape({
        latitude: PropTypes.number.isRequired,
        longitude: PropTypes.number.isRequired,
      })
    ),
    views: PropTypes.number,
  }),
};

export default AgeChart;











// import React, { useState } from "react";
// import PropTypes from "prop-types";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   ResponsiveContainer,
// } from "recharts";
// import { useTranslation } from "react-i18next";
// import ToggleButton from "@client.core.components/ToggleButton";

// const CustomTooltip = ({ active, payload, label }) => {
//   if (active && payload && payload.length) {
//     const data = payload[0].payload;
//     return (
//       <div className="age-tooltip">
//         {`People at age ${label}: ${data.qty}`}
//       </div>
//     );
//   }

//   return null;
// };

// CustomTooltip.propTypes = {
//   active: PropTypes.any,
//   payload: PropTypes.any,
//   label: PropTypes.any,
// };

// const AgeChart = ({ data }) => {
//   console.log("data", data);
//   const { t } = useTranslation();
//   const [isMale, setIsMale] = useState(true);
//   const [isFemale, setIsFemale] = useState(true);

//   if (!data || !Array.isArray(data) || data.length === 0) {
//     return (
//       <div className="data-analyzer-chart">
//         <div className="chart-title">{t("pages.DataAnalyzer.ageChart.title")}</div>
//         <p>{t("pages.DataAnalyzer.noData")}</p>
//       </div>
//     );
//   }

//   // Process data for the chart
//   const dataToShow = data.reduce((acc, d) => {
//     const { age, male, female } = d;

//     // Include male/female filtering
//     let qty = 0;
//     if (isMale) qty += male || 0;
//     if (isFemale) qty += female || 0;

//     if (qty > 0) {
//       acc.push({ age, qty });
//     }
//     return acc;
//   }, []);

//   return (
//     <div className="data-analyzer-chart">
//       <div className="data-analyzer-top-bar">
//         <div className="chart-title">
//           {t("pages.DataAnalyzer.ageChart.title")}
//         </div>
//         <div className="filter-buttons">
//           <ToggleButton
//             text={t("pages.DataAnalyzer.gender.female")}
//             value={isFemale}
//             onChange={setIsFemale}
//           />
//           <ToggleButton
//             text={t("pages.DataAnalyzer.gender.male")}
//             value={isMale}
//             onChange={setIsMale}
//           />
//         </div>
//       </div>
//       <ResponsiveContainer width="100%" height={400}>
//         <BarChart
//           data={dataToShow}
//           margin={{ top: 20, right: 30, left: -20, bottom: 20 }}
//         >
//           <CartesianGrid strokeDasharray="3 3" />
//           <XAxis dataKey="age" name="Age" />
//           <YAxis />
//           <Tooltip content={<CustomTooltip />} />
//           <Bar dataKey="qty" fill="#2D99FF" name={t("pages.DataAnalyzer.quantity")} />
//         </BarChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// AgeChart.propTypes = {
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       age: PropTypes.number.isRequired,
//       male: PropTypes.number,
//       female: PropTypes.number,
//     })
//   ),
// };

// export default AgeChart;





















// import React, { useState } from "react";
// import PropTypes from "prop-types";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   ResponsiveContainer,
// } from "recharts";
// import { useTranslation } from "react-i18next";
// import ToggleButton from "@client.core.components/ToggleButton";

// const CustomTooltip = ({ active, payload, label }) => {
//   if (active && payload && payload.length) {
//     const data = payload[0].payload;
//     return (
//       <div className="age-tooltip">
//         {`People at age ${label} viewed ${data.qty} times`}
//       </div>
//     );
//   }

//   return null;
// };

// CustomTooltip.propTypes = {
//   active: PropTypes.any,
//   payload: PropTypes.any,
//   label: PropTypes.any,
// };

// const AgeChart = ({ data }) => {
//   const { t } = useTranslation();
//   const [isMale, setIsMale] = useState(true);
//   const [isFemale, setIsFemale] = useState(true);

//   const dataToShow = data.reduce((acc, d) => {
//     d.viewers
//       .filter((v) => {
//         if (isMale && v.gender === "Male") {
//           return true;
//         }
//         if (isFemale && v.gender === "Female") {
//           return true;
//         }

//         return false;
//       })
//       .forEach((view) => {
//         const age = view.age;
//         if (!acc[age]) {
//           acc[age] = {
//             age,
//             qty: 0,
//           };
//         }
//         acc[age].qty += view.qty;
//       });

//     return acc;
//   }, {});

//   return (
//     <div className="data-analyzer-chart">
//       <div className="data-analyzer-top-bar">
//         <div className="chart-title">
//           {t("pages.DataAnalyzer.ageChart.title")}
//         </div>
//         <div className="filter-buttons">
//           <ToggleButton
//             text={"Female"}
//             value={isFemale}
//             onChange={setIsFemale}
//           />
//           <ToggleButton text={"Male"} value={isMale} onChange={setIsMale} />
//         </div>
//       </div>
//       <ResponsiveContainer width="100%" height={400}>
//         <BarChart
//           data={Object.values(dataToShow)}
//           margin={{ top: 20, right: 30, left: -20, bottom: 20 }}
//         >
//           <CartesianGrid strokeDasharray="3 3" />
//           <XAxis dataKey="age" name="Age" />
//           <YAxis />
//           <Tooltip content={<CustomTooltip />} />
//           <Bar dataKey="qty" fill="#2D99FF" name={"No of People"} />
//         </BarChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// AgeChart.propTypes = {
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       startAt: PropTypes.string.isRequired,
//       endAt: PropTypes.string.isRequired,
//       processed: PropTypes.string,
//       unprocessed: PropTypes.string,
//     })
//   ),
// };

// export default AgeChart;
