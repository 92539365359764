import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import classNames from "classnames";
import SvgIcon from "@client.core.components/SvgIcon";
import { VoidFunc } from "types";
import "./Button.scss";

interface ButtonProps {
  text?: string;
  icon?: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}

interface ButtonRef {
  setDisabled: VoidFunc<boolean>;
}

const Button = forwardRef<ButtonRef, ButtonProps>(
  ({ text, icon, onClick, className, disabled }, ref) => {
    const [isDisabled, setIsDisabled] = useState<boolean>(!!disabled);

    useImperativeHandle(ref, () => ({
      setDisabled: setIsDisabled,
    }));

    useEffect(() => {
      setIsDisabled(disabled || false);
    }, [disabled]);

    return (
      <div
        className={classNames("button", { disabled: isDisabled }, className)}
        onClick={isDisabled ? undefined : onClick}
      >
        {icon && <SvgIcon name={icon} />}
        {text && <span>{text}</span>}
      </div>
    );
  }
);

Button.displayName = "Button";

export default Button;
