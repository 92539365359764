import { IDashboardFilter } from "@client.features/main/type";
import BaseService from "@client.services/baseService";

export default class DashboardService extends BaseService {
  async getSystemLogs() {
    return super.post("", {
      query: `query {
                fetchSystemLogs {
                  count
                  results {
                    createdAt
                    deletedAt
                    description
                    icon
                    isActive
                    isDeleted
                    lookupId
                    title
                    unitId
                    unitType
                    updatedAt
                  }
                }
      }`,
      variables: {},
    });
  }

  async getDashboardBoxes() {
    return super.post("", {
      query: `query {
        dashboardBoxes {
          activeCampaigns
          dailyAdPlays
          dailyAdViews
          dailyBudgetSpent
        }
      }`,
    });
  }

  async getMap(filter: IDashboardFilter) {
    return super.post("", {
      query: `query($showZones: Boolean, $showHeatmap: Boolean, $deviceType: [Int], $deviceOnline: Boolean){
          map(showZones: $showZones, showHeatmap: $showHeatmap, deviceType: $deviceType, deviceOnline: $deviceOnline){
            heatmap {
              count
              results{
                name
                latitude
                longitude
                intensity
              }
            }
            devices {
              count
              results {
                Id
 createdAccountId
            assignedAccountId
            deviceReady
                latitude
                longitude
                createdAt
                deviceName
                deviceType
                hardwareVersion
                isActive
                isOnline
                lastMessageDate
                machineId
                softwareVersion
                timezone
                updatedAt
                vpnId
              }
            }
            zones {
              count
              results {
                Id
                category
                color
                createdAt
                isActive
                parentZoneId
                polygon
                ppvPrice
                primePrice
                updatedAt
                zoneName
              }
            }
          }
      }`,
      variables: {
        ...filter,
      },
    });
  }
}
