import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import SystemLogService from "@client.services/systemLogService";
import ServiceProvider from "@client.services/provider";
// Fetch system logs data
export const getAllSystemLogsAsync = createAsyncThunk(
  "systemLogs/getAll",
  async (params) => {
    const response = await ServiceProvider.SystemLogService.getAll(params);
    return response.data.systemLogs; 
  }
);

export const createSystemLogAsync = createAsyncThunk(
  "systemLogs/create",
  async (logData) => {
    const response = await ServiceProvider.SystemLogService.create(logData);
    return response.data.createSystemLog;
  }
);

const systemLogsSlice = createSlice({
  name: "systemLogs",
  initialState: {
    results: [],
    count: 0,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSystemLogsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSystemLogsAsync.fulfilled, (state, action) => {
        const data = action.payload;
        state.results = data.results;
        state.count = data.count;
        state.loading = false;
      })
      .addCase(getAllSystemLogsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })


     

       // Handle creating a system log
       .addCase(createSystemLogAsync.pending, (state) => {
        state.creatingLog = true; 
      })
      .addCase(createSystemLogAsync.fulfilled, (state, action) => {
        state.creatingLog = false; 
        state.results.push(action.payload.systemLog); 
      })
      .addCase(createSystemLogAsync.rejected, (state) => {
        state.creatingLog = false;
        state.error = "Failed to create log";  
      });


      
  },
});

// Selector to access system logs state
export const makeSystemLogs = (state) => state.systemLogs;

export default systemLogsSlice.reducer;











// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import ServiceProvider from "@client.services/provider";

// // Fetch system logs data
// export const getAllSystemLogsAsync = createAsyncThunk(
//   "systemLogs/getAll",
//   async (params) => {
//     const response = await ServiceProvider.SystemLogService.getAll(params);
//     return response.data.systemLogs; // Return the systemLogs array from the response
//   }
// );

// // Define the systemLogs slice
// const systemLogsSlice = createSlice({
//   name: "systemLogs",
//   initialState: {
//     results: [],
//     count: 0,
//     loading: false,
//   },
//   reducers: {},
//   extraReducers: (builder) => {
//     builder
//       .addCase(getAllSystemLogsAsync.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(getAllSystemLogsAsync.fulfilled, (state, action) => {
//         state.results = action.payload.results;
//         state.count = action.payload.count;
//         state.loading = false;
//       })
//       .addCase(getAllSystemLogsAsync.rejected, (state) => {
//         state.loading = false;
//       });
//   },
// });

// // Selector to access the systemLogs state
// export const makeSystemLogs = (state) => state.systemLogs;

// export default systemLogsSlice.reducer;
