import React from "react";
import "./Campaigns.scss";
import CampaignsList from "./CampaignsList";

const CampaignsPage = () => {
  return (
    <div className="campaigns">
      <CampaignsList  />
    </div>
  );
};

export default CampaignsPage;


