import moment from "moment";
import config from "@client.config";
export const getColumns = (t) => [
  {
    field: "createdAt",
    headerName: t("Created At"),
    cellRenderer: (params) => {
      return params.value
        // ? moment(params.value).format("YYYY-MM-DD HH:mm:ss")
        ? moment(params.value).format(config.dateTimeFormat)
        : "N/A";
    },
  },
  {
    field: "type",
    headerName: t("Action"),
    valueGetter: (params) => {
      switch (params.data.type) {
        case 0:
          return t("CREATE");
        case 1:
          return t("UPDATE");
        case 2:
          return t("DELETE");
        case 3:
          return t("LOGIN");
        default:
          return t("UNKNOWN");
      }
    },
  },
  {
    field: "userFullName",
    headerName: t("Full Name"),
    valueGetter: (params) => params.data.userFullName || "N/A", // Added fallback for null values
  },
  {
    field: "description",
    headerName: t("Description"),
  },
  {
    field: "ipAddress",
    headerName: t("IP Address"),
  },
];
