import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import StatisticView from "@client.components/StatisticView/StatisticView";
import moment from "moment";
import { dropdownStyles } from "./Overview";

const CampaignStatistic = ({ campaign, campaignId, dateFrom, dateTo }) => {
  // Memoize the parsed dates to avoid recalculating on every render
  const parsedDateFrom = useMemo(() => moment(dateFrom, "YYYY-MM-DD HH:mm:ss.SSSSSS").toDate(), [dateFrom]);
  const parsedDateTo = useMemo(() => moment(dateTo, "YYYY-MM-DD HH:mm:ss.SSSSSS").toDate(), [dateTo]);

  // Formatting function for displaying the date in a readable format
  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  // Helper function to calculate the start and end of a week for a given date
  const getWeekRange = (date) => {
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - date.getDay() + 1); // Monday
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Sunday
    return [startOfWeek, endOfWeek];
  };

  // State to store weeks based on `dateFrom` to `dateTo`
  const [weeks, setWeeks] = useState([]);

  useEffect(() => {
    // Generate weeks from `dateFrom` to `dateTo`
    const weeksData = [];
    let currentDate = new Date(parsedDateFrom);

    while (currentDate <= parsedDateTo) {
      const [startOfWeek, endOfWeek] = getWeekRange(currentDate);
      weeksData.push({
        start: startOfWeek,
        end: endOfWeek,
        label: `${formatDate(startOfWeek)} - ${formatDate(endOfWeek)}`,
        value: `${startOfWeek.toISOString()} - ${endOfWeek.toISOString()}`,
      });

      // Move to the next week
      currentDate.setDate(currentDate.getDate() + 7);
    }

    setWeeks(weeksData);
  }, [parsedDateFrom, parsedDateTo]); // Only run effect if `parsedDateFrom` or `parsedDateTo` change

  // Map weeks to options for Select
  const weekMap = weeks.map((week) => ({
    value: week.value,
    label: week.label,
  }));

  // Placeholder for the latest week range
  const latestWeek = weeks[0]?.label;

  return (
    campaign?.data?.campaignMonitoring && (
      <StatisticView
        title="pages.Campaign.campaignMonitoring"
        data={{
          green: {
            value: campaign.data.campaignMonitoring.totalPlays,
            title: "pages.Campaign.Overview.plays",
          },
          orange: {
            value: campaign.data.campaignMonitoring.totalViews,
            title: "pages.Campaign.Overview.views",
          },
          blue: {
            value: campaign.data.campaignMonitoring.totalSpent,
            title: "pages.Campaign.Overview.spent",
          },
        }}
      >
        <Select
          options={weekMap}
          styles={dropdownStyles}
          placeholder={latestWeek}
        />
      </StatisticView>
    )
  );
};

CampaignStatistic.propTypes = {
  campaignId: PropTypes.string,
  campaign: PropTypes.any,
  dateFrom: PropTypes.string, 
  dateTo: PropTypes.string,
};

export default CampaignStatistic;
























// import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
// import Select from "react-select";
// import StatisticView from "@client.components/StatisticView/StatisticView";
// import moment from "moment";
// import config from "@client.config";

// // eslint-disable-next-line no-unused-vars
// const CampaignStatistic = ({ campaign, campaignId, dateFrom, dateTo }) => {
//   console.log("From CampaignStatisticcccc campaign", campaign);

//   console.log("From CampaignStatisticcccc dateFrom", dateFrom);
//   console.log("From CampaignStatisticcccc dateTo", dateTo);
//   function formatDate(date) {
//     const options = {
//       weekday: "long",
//       month: "long",
//       day: "numeric",
//       year: "numeric",
//     };
//     return date.toLocaleDateString("en-US", options);
//   }

//   // Function to get the start and end of a week for a given date
//   function getWeekRange(date) {
//     const startOfWeek = new Date(date);
//     startOfWeek.setDate(date.getDate() - date.getDay() + 1); // Monday
//     const endOfWeek = new Date(startOfWeek);
//     endOfWeek.setDate(startOfWeek.getDate() + 6); // Sunday
//     return [startOfWeek, endOfWeek];
//   }

//   // State to store weeks
//   const [weeks, setWeeks] = useState([]);

//   // Populate the select box with the last 12 weeks
//   useEffect(() => {
//     const today = new Date();
//     const weeksData = [];

//     for (let i = 0; i < 12; i++) {
//       // const date = new Date();
//       const date = new Date();
//       // console.log("From CampaignStatisticccccsaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa date", moment(date).format(config.dateTimeFormat));
//       console.log("From CampaignStatisticccccsaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa date", date);
//       date.setDate(today.getDate() - i * 7);
//       const [startOfWeek, endOfWeek] = getWeekRange(date);
//       weeksData.push({
//         start: startOfWeek,
//         end: endOfWeek,
//         label: `${formatDate(startOfWeek)} - ${formatDate(endOfWeek)}`,
//         value: `${startOfWeek.toISOString()} - ${endOfWeek.toISOString()}`,
//       });
//     }

//     setWeeks(weeksData);
//   }, []);

//   const weekMap = weeks.map((week) => ({
//     value: week.value,
//     label: week.label,
//   }));
//   console.log("From CampaignStatisticcccc weekMap", weekMap);
//   const customStyles = {
//     container: (provided) => ({
//       ...provided,
//       width: "500px", // Adjust this value as needed
//     }),
//   };

//   const latestWeek = weeks[0]?.label;
//   // if (selectedCampaignStatistic.loading) {
//   //   return <Loader loading={true} />;
//   // }

//   return (
//     campaign.data.campaignMonitoring && 
//     (
//       <StatisticView
//         title="pages.Campaign.campaignMonitoring"
//         data={{
//           green: {
//             value: campaign.data.campaignMonitoring.totalPlays,
//             title: "pages.Campaign.Overview.plays",
//           },
//           orange: {
//             value: campaign.data.campaignMonitoring.totalViews,
//             title: "pages.Campaign.Overview.views",
//           },
//           blue: {
//             value: campaign.data.campaignMonitoring.totalSpent,
//             title: "pages.Campaign.Overview.spent",
//           },
//         }}
//       >
//         <Select
//           options={weekMap}
//           styles={customStyles}
//           placeholder={latestWeek}
//         />
//       </StatisticView>
//     )
//   );
// };

// CampaignStatistic.propTypes = {
//   campaignId: PropTypes.string,
//   campaign: PropTypes.any,
//   dateFrom: PropTypes.instanceOf(Date),
//   dateTo: PropTypes.instanceOf(Date),
// };

// export default CampaignStatistic;
