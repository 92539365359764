import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Icon from "@client.core.components/Icon";
import "./MapFilter.scss";

const MapFilter = ({ items }) => {
  const { t } = useTranslation();
  const [expand, setExpand] = useState(true);

  return (
    <div className="map-filter google-map-display-options">
      <div className="map-filter-header" onClick={() => setExpand(!expand)}>
        <span>{t("components.MapFilter.Menu")}</span>
        <Icon name={expand ? "collapse" : "chevron"} />
      </div>
      <div className={expand ? "options expanded" : "options"}>
        {items.map((item) => {
          return (
            <div
              className={item.enabled ? "option option-enabled" : "option"}
              onClick={item.onClick}
              key={item.title}
            >
              <div className="option-header">
                <Icon name={item.icon} />
                {t(item.title)}
              </div>
              {item.component}
            </div>
          );
        })}
      </div>
    </div>
  );
};
MapFilter.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.string,
      enabled: PropTypes.bool,
      onClick: PropTypes.func,
    })
  ),
};
export default MapFilter;
