import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "@client.core.components/Button";
import Pages from "@client.enums/pages";
import CampaignOverview from "@client.components/CampaignOverview";
import DataTablePagination from "@client.components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getAdvertiserCampaignsAsync, makeSelectedCampaigns } from "../reducer";
import useStableParams from "@client.hooks/useStableParams";
import CampaignStatuses from "@client.enums/campaignStatuses";

const ITEMS_PER_PAGE = 10;

const CampaignsTab = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedCampaigns = useSelector(makeSelectedCampaigns);
  const { id: advertiserId } = useStableParams();
  // console.log("AdvertiserIdddd:", advertiserId);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState(CampaignStatuses.All); // Manage selected status

  useEffect(() => {
    const offset = (currentPage - 1) * ITEMS_PER_PAGE;

    // Set status to null if "All" is selected
    const selectedStatus = status === CampaignStatuses.All ? null : status;

    dispatch(
      getAdvertiserCampaignsAsync({
        advertiserId, // Pass the advertiserId
        status: selectedStatus, // Pass null if "All" is selected
        offset,
        limit: ITEMS_PER_PAGE,
      })
    );
  }, [dispatch, advertiserId, currentPage, status]);

  return (
    <>
      <div className="tab-title">
        <span>{t("pages.Advertiser.CampaignsTab.title")}</span>
        <Button
          icon="plus"
          text={t("components.NavigationBar.createCampaign")}
          onClick={() => navigate(Pages.CreateCampaign)}
        />
      </div>

      <div className="campaign-list">
        {selectedCampaigns.campaigns && selectedCampaigns.campaigns.length > 0 ? (
          selectedCampaigns.campaigns.map((campaign) => (
            <CampaignOverview key={campaign.Id} campaign={campaign} />
          ))
        ) : (
          <div>{t("pages.Campaigns.noCampaignsFound")}</div>
        )}
      </div>

      <div className="pagination">
        <DataTablePagination
          total={selectedCampaigns.count || 0}
          itemsPerPage={ITEMS_PER_PAGE}
          onChange={setCurrentPage}
        />
      </div>
    </>
  );
};

export default CampaignsTab;











// import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// import Button from "@client.core.components/Button";
// import Pages from "@client.enums/pages";
// import CampaignList from "../CampaignList";
// import { useDispatch, useSelector } from "react-redux";
// import { getAdvertiserCampaignsAsync, makeSelectedCampaigns } from "../reducer";
// import useStableParams from "@client.hooks/useStableParams";
// import CampaignStatuses from "@client.enums/campaignStatuses";

// const CampaignsTab = () => {
//   const navigate = useNavigate();
//   const { t } = useTranslation();
//   const dispatch = useDispatch();
//   const selectedCampaigns = useSelector(makeSelectedCampaigns);

//   const { id } = useStableParams();

//   useEffect(() => {
//     dispatch(getAdvertiserCampaignsAsync(CampaignStatuses.All));
//   }, [dispatch, id]);

//   return (
//     <>
//       <div className="tab-title">
//         <span>{t("pages.Advertiser.CampaignsTab.title")}</span>
//         <Button
//           icon="plus"
//           text={t("components.NavigationBar.createCampaign")}
//           onClick={() => {
//             navigate(Pages.CreateCampaign);
//           }}
//         />
//       </div>
//       <CampaignList
//         advertiserId={id}
//         loading={selectedCampaigns.loading}
//         campaigns={selectedCampaigns.campaigns}
//       />
//     </>
//   );
// };

// export default CampaignsTab;
