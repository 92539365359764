import BaseModel from "./base";
import { get } from "lodash";

class BaseSystemLogModel extends BaseModel {
    constructor(entity = {}, msg = {}) {
        super(msg);

        this.entityId = get(entity, "entityId", "");  
        this.description = get(entity, "description", "");
        this.entity = get(entity, "entity", 0);
        this.type = get(entity, "type", 0);
        this.validationMsgs = msg;

        this.validationRules = {
            description: {
                presence: { allowEmpty: false, message: msg.description || "" },
            },
            entity: {
                presence: { allowEmpty: false, message: msg.entity || "" },
            },
            type: {
                presence: { allowEmpty: false, message: msg.type || "" },
            },
            entityId: {
                presence: { allowEmpty: true, message: msg.entityId || "" },
            }
        };
    }

    get fields() {
        return {
            description: this.description,
            entity: this.entity,
            type: this.type,
            entityId: this.entityId 
        };
    }
}

export default BaseSystemLogModel;













// import BaseModel from "./base";
// import { get } from "lodash";


//  class BaseSystemLogModel extends BaseModel {
//     constructor(entity = {}, msg = {}) {
//     super(msg);

//     this.entityId = get(entity, "entityId", "");
//     this.description = get(entity, "description", "");
//     this.entity = get(entity, "entity", 0);
//     this.type = get(entity, "type", 0);
//     this.validationMsgs = msg;
      
   

//     this.validationRules={
//         description: {
//             // presence: { allowEmpty: false, message: "Description is required" },
//             presence: { allowEmpty: false, message: msg.description || "" },
//         },
//         entity: {
//             presence: { allowEmpty: false, message: msg.entity || ""},
//         },
//         type: {
//             presence: { allowEmpty: false, message: msg.type || "" },
//         }
//     }
// }
//     get fields() {
//         return {
//             description: this.description,
//             entity: this.entity,
//             type: this.type,
//         };
//     }
// }

// export default BaseSystemLogModel;
