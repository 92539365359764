import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeSelectedAccount } from "../reducer";
import { useTranslation } from "react-i18next";
import ServiceProvider from "@client.services/provider";
import { get } from "lodash";
import DataTable from "@client.components/DataTable";
import Roles from "@client.enums/roles";

const UsersTab = () => {
  const selectedAccount = useSelector(makeSelectedAccount);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (selectedAccount.Id) {
      setLoading(true);
      ServiceProvider.User.getAll(
        {
          limit: 1000,
          offset: 0,
          startDate: "",
          endDate: "",
          roleId: "",
          search: "",
        },
        selectedAccount.Id
      )
        .then((resp) => {
          setUsers(get(resp, "data.users.results", []));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedAccount.Id]);

  const COLUMNS = [
    {
      field: "firstName",
      tooltipField: "firstName",
      headerName: t("pages.Users.columns.firstName"),
    },
    {
      field: "lastName",
      tooltipField: "lastName",
      headerName: t("pages.Users.columns.lastName"),
    },
    {
      field: "lastName",
      tooltipField: "lastName",
      headerName: t("pages.Users.columns.lastName"),
    },
    {
      field: "emailAddress",
      tooltipField: "emailAddress",
      headerName: t("pages.Users.columns.email"),
    },
    {
      field: "phoneNumber",
      tooltipField: "phoneNumber",
      headerName: t("pages.Users.columns.phone"),
    },
    {
      field: "role",
      valueGetter: (params) => {
        switch (params.data.role) {
          case Roles.Admin:
            return t("components.Advertisers.roles.admin");
          case Roles.Manager:
            return t("components.Advertisers.roles.manager");
          case Roles.Employee:
            return t("components.Advertisers.roles.employee");
          case Roles.Technician:
            return t("components.Advertisers.roles.technician");
          case Roles.User:
            return t("components.Advertisers.roles.user");
          default:
            return "N/A";
        }
      },
      headerName: t("pages.Users.columns.role"),
    },
  ];

  return (
    <div className="users-tab">
      <DataTable columns={COLUMNS} data={users} isLoading={loading} />
    </div>
  );
};

UsersTab.propTypes = {};

export default UsersTab;
