import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Card.scss";

const Card = ({ title, children, className }) => {
  return (
    <div className={classNames("details-card", className)}>
      <div className="title">{title}</div>
      {children}
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Card;
