import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "@client.services/provider";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";
import CampaignStatuses from "@client.enums/campaignStatuses";

const DEFAULT_SELECTED_ADVERTISER = { advertiser: null, loading: false };
const DEFAULT_SELECTED_CAMPAIGNS = { campaigns: [], loading: false, count: 0 };
const DEFAULT_SELECTED_ASSETS = { assets: [], loading: false };
const DEFAULT_SELECTED_DRAFT = { campaign: null, loading: false };

const initialState = {
  advertisers: [],
  error: null,
  loading: false,
  filter: { status: CampaignStatuses.Active, advertiserId: null },
  selected: DEFAULT_SELECTED_ADVERTISER,
  selectedCampaigns: DEFAULT_SELECTED_CAMPAIGNS,
  selectedDrafts: DEFAULT_SELECTED_CAMPAIGNS,
  selectedAssets: DEFAULT_SELECTED_ASSETS,
  selectedDraft: DEFAULT_SELECTED_DRAFT,
};



export const getAdvertiserCampaignsAsync = createAsyncThunk(
  "advertiser/getCampaigns",
  async ({
    status = null,
    advertiserId = null,
    startDate = "",
    endDate = "",
    limit = 1000,
    offset = 0,
    searchOn = "campaign_name",
    search = "",
  }) => {
    const response = await ServiceProvider.Campaign.getAllCampaigns({
      status,
      advertiserId,
      search,
      startDate,
      endDate,
      limit,
      offset,
      searchOn,
    });
    return response;
  }
);

export const getAllAdvertiserCampaignsAsync = createAsyncThunk(
  "advertiser/getAllCampaigns",
  async ({ advertiserId }) => {
    const response = await ServiceProvider.Campaign.getAllCampaigns({
      advertiserId,
      status: null,
      offset: 0,
      limit: 10000, // Fetch all campaigns
    });
    return response?.data?.campaigns?.results || [];
  }
);



// export const getAdvertiserCampaignsAsync = createAsyncThunk(
//   "advertiser/getCampaigns",
//   async ({
//     status = null,
//     advertiserId = null,
//     startDate = "",
//     endDate = "",
//     limit = 10,
//     offset = 0,
//     searchOn = "campaign_name",
//     search = "",
//   }) => {
//     const response = await ServiceProvider.Campaign.getAllCampaigns({
//       status,
//       advertiserId,
//       search,
//       startDate,
//       endDate,
//       limit,
//       offset,
//       searchOn,
//     });
//     return response;
//   }
// );

export const getAllAdvertisersAsync = createAsyncThunk(
  "advertiser/getAll",
  async () => {
    return await ServiceProvider.Advertiser.getAll();
  }
);

export const createAdvertiserAsync = createAsyncThunk(
  "advertiser/create",
  async (advertiser, thunkAPI) => {
    const response = await ServiceProvider.Advertiser.create(advertiser);
    if (!response.errors) {
      thunkAPI.dispatch(getAllAdvertisersAsync());
    }
    return response;
  }
);

export const updateAdvertiserCampaignAsync = createAsyncThunk(
  "advertiser/updateCampaign",
  async ({ campaign, filters }, thunkAPI) => {
    const response = await ServiceProvider.Campaign.updateCampaign(campaign);
    if (!response.errors) {
      thunkAPI.dispatch(getAdvertiserCampaignsAsync(filters));
    }
    return response;
  }
);

export const updateAdvertiserCampaignStatusAsync = createAsyncThunk(
  "advertiser/updateCampaignStatus",
  async ({ campaign, filters }, thunkAPI) => {
    const response = await ServiceProvider.Campaign.updateCampaignStatus(campaign);
    if (!response.errors) {
      thunkAPI.dispatch(getAdvertiserCampaignsAsync(filters));
    }
    return response;
  }
);

export const getAdvertiserDraftsAsync = createAsyncThunk(
  "advertiser/getDrafts",
  async () => {
    return await ServiceProvider.Campaign.getAllCampaigns({
      status: CampaignStatuses.Draft,
    });
  }
);

export const getCampaignDraftByIdAsync = createAsyncThunk(
  "advertiser/getDraftById",
  async (id) => {
    return await ServiceProvider.CampaignDraft.getById(id);
  }
);

export const getSelectedAssetsAsync = createAsyncThunk(
  "advertiser/getAllAssets",
  async (advertiserId) => {
    return await ServiceProvider.Advertiser.getAllAssets(advertiserId);
  }
);

export const uploadUserAssetAsync = createAsyncThunk(
  "advertiser/uploadAsset",
  async (asset) => {
    // console.log("Form uploadUserAssetAsync", asset);
    const response = await ServiceProvider.Advertiser.uploadAsset(asset);
    return response;
  }
);

export const getAdvertiserByIdAsync = createAsyncThunk(
  "advertiser/getById",
  async (advertiserId) => {
    // console.log("From getAdvertiserByIdAsync", advertiserId);
    const response = await ServiceProvider.Advertiser.getById(advertiserId);
    // console.log("From getAdvertiserByIdAsync", response);
    return response
  }
);

export const advertiserSlice = createSlice({
  name: "advertiser",
  initialState,
  reducers: {
    setCampaignFilter: (state, action) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    resetSelectedAdvertiser: (state) => {
      state.selected = { ...DEFAULT_SELECTED_ADVERTISER };
    },
    resetSelectedDraft: (state) => {
      state.selectedDraft = { ...DEFAULT_SELECTED_DRAFT };
    },
    clearAdvertiserError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdvertiserCampaignsAsync.pending, (state) => {
        state.selectedCampaigns.loading = true;
      })
      .addCase(getAdvertiserCampaignsAsync.fulfilled, (state, action) => {
        state.selectedCampaigns.loading = false;
        state.selectedCampaigns.campaigns = get(
          action,
          "payload.data.campaigns.results",
          []
        );
        state.selectedCampaigns.count = get(
          action,
          "payload.data.campaigns.count",
          0
        );
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAdvertiserCampaignsAsync.rejected, (state, action) => {
        state.selectedCampaigns = {
          ...DEFAULT_SELECTED_CAMPAIGNS,
        };
        state.error = getResponseError(action);
      })
      .addCase(getAllAdvertisersAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAdvertisersAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.advertisers = get(action, "payload.data.accounts.results", []);
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAllAdvertisersAsync.rejected, (state, action) => {
        state.loading = false;
        state.advertisers = [];
        state.error = getResponseError(action);
      })
      .addCase(createAdvertiserAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAdvertiserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = getGraphqlResponseError(action);
      })
      .addCase(createAdvertiserAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = getResponseError(action);
      })
      .addCase(updateAdvertiserCampaignStatusAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAdvertiserCampaignStatusAsync.fulfilled, (state, action) => {
        state.loading = false;
        const updatedCampaign = action.payload.data;
        const campaignIndex = state.selectedCampaigns.campaigns.findIndex(
          (c) => c.Id === updatedCampaign.Id
        );
        if (campaignIndex >= 0) {
          state.selectedCampaigns.campaigns[campaignIndex] = updatedCampaign;
        }
      })
      .addCase(updateAdvertiserCampaignStatusAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateAdvertiserCampaignAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAdvertiserCampaignAsync.fulfilled, (state, action) => {
        state.loading = false;
        const updatedCampaign = action.payload.data;
        const campaignIndex = state.selectedCampaigns.campaigns.findIndex(
          (c) => c.Id === updatedCampaign.Id
        );
        if (campaignIndex >= 0) {
          state.selectedCampaigns.campaigns[campaignIndex] = updatedCampaign;
        }
      })
      .addCase(updateAdvertiserCampaignAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAdvertiserDraftsAsync.pending, (state) => {
        state.selectedDrafts.loading = true;
      })
      .addCase(getAdvertiserDraftsAsync.fulfilled, (state, action) => {
        state.selectedDrafts.loading = false;
        state.selectedDrafts.campaigns = get(action, "payload.data.campaigns", {
          results: [],
          count: 0,
        }).results;
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAdvertiserDraftsAsync.rejected, (state, action) => {
        state.selectedDrafts = {
          ...DEFAULT_SELECTED_CAMPAIGNS,
        };
        state.error = getResponseError(action);
      })
      .addCase(getCampaignDraftByIdAsync.pending, (state) => {
        state.selectedDraft.loading = true;
      })
      .addCase(getCampaignDraftByIdAsync.fulfilled, (state, action) => {
        state.selectedDraft.loading = false;
        state.selectedDraft.campaign = get(
          action,
          "payload.data.fetchDraftCampaign",
          null
        );
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getCampaignDraftByIdAsync.rejected, (state, action) => {
        state.selectedDraft.loading = false;
        state.selectedDraft = {
          ...DEFAULT_SELECTED_DRAFT,
        };
        state.error = getResponseError(action);
      })
      .addCase(getSelectedAssetsAsync.pending, (state) => {
        state.selectedAssets.loading = true;
      })
      .addCase(getSelectedAssetsAsync.fulfilled, (state, action) => {
        state.selectedAssets.loading = false;
        const mediasData = get(action, "payload.data.medias", []);
        state.selectedAssets.assets = Array.isArray(mediasData) ? mediasData : [];
        state.error = getGraphqlResponseError(action);
      })
      
      .addCase(getSelectedAssetsAsync.rejected, (state, action) => {
        state.selectedAssets.loading = false;
        state.selectedAssets = DEFAULT_SELECTED_ASSETS;
        state.error = getResponseError(action);
      })
      .addCase(uploadUserAssetAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadUserAssetAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = getGraphqlResponseError(action);
      })
      .addCase(uploadUserAssetAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = getResponseError(action);
      })
      .addCase(getAdvertiserByIdAsync.pending, (state) => {
        state.selected.loading = true;
      })
      .addCase(getAdvertiserByIdAsync.fulfilled, (state, action) => {
        state.selected.loading = false;
        const advertiser = get(action, "payload.data.fetchUser", null);
        state.selected.advertiser = advertiser
          ? { ...advertiser, index: getAdvertiserIndex(state, advertiser) }
          : null;
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAdvertiserByIdAsync.rejected, (state, action) => {
        state.selected.loading = false;
        state.selected.advertiser = { ...DEFAULT_SELECTED_ADVERTISER };
        state.error = getResponseError(action);
      });
  },
});

export const {
  resetSelectedAdvertiser,
  resetSelectedDraft,
  clearAdvertiserError,
  setCampaignFilter,
} = advertiserSlice.actions;

export const makeAdvertisers = (state) => state.advertiser.advertisers;
export const makeAdvertisersLoading = (state) => state.advertiser.loading;
export const makeSelectedAdvertiser = (state) => state.advertiser.selected;
export const makeSelectedCampaigns = (state) => state.advertiser.selectedCampaigns;
export const makeSelectedDrafts = (state) => state.advertiser.selectedDrafts;
export const makeSelectedDraft = (state) => state.advertiser.selectedDraft;
export const makeSelectedAssets = (state) => state.advertiser.selectedAssets;
export const makeAdvertiserError = (state) => state.advertiser.error;
export const makeCampaignFilter = (state) => state.advertiser.filter;

export default advertiserSlice.reducer;