import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";
import ServiceProvider from "@client.services/provider";
import { get } from "lodash";
import CampaignStatuses from "@client.enums/campaignStatuses";

// Async action creator
export const getAdvertiserCampaignsAsync = createAsyncThunk(
  "advertiser/getCampaigns",
  async () => {
    return await ServiceProvider.Campaign.getAllCampaigns({
      status: CampaignStatuses.Active,
    });
  }
);

// Initial state
const DEFAULT_SELECTED_CAMPAIGNS = { campaigns: [], loading: false };
const initialState = {
  error: null,
  loading: false,
  selectedCampaigns: DEFAULT_SELECTED_CAMPAIGNS,
};

// Reducer
const reducer = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    setLoadingSelectedCampaigns: (state, action) => {
      state.selectedCampaigns.loading = action.payload;
    },
    toggleIsActive(state) {
      state.isActive = !state.isActive;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdvertiserCampaignsAsync.pending, (state) => {
        state.selectedCampaigns.loading = true;
      })
      .addCase(getAdvertiserCampaignsAsync.fulfilled, (state, action) => {
        state.selectedCampaigns.loading = false;
        state.selectedCampaigns.campaigns = get(
          action,
          "payload.data.campaigns",
          {
            results: [],
            count: 0,
          }
        ).results;
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAdvertiserCampaignsAsync.rejected, (state, action) => {
        state.selectedCampaigns.loading = false;
        state.selectedCampaigns = {
          ...DEFAULT_SELECTED_CAMPAIGNS,
        };
        state.error = getResponseError(action);
      });
  },
});

export const { toggleIsActive } = reducer.actions;
export const makeSelectedCampaigns = (state) =>
  state.advertiser.selectedCampaigns;

export default reducer;
