import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import "./DateTimeRangePicker.scss";

const DateTimeRangePicker = ({
  onChange,
  showTimeSelect = true,
  dateRange,
  captionStart = "Start Date/Time",
  captionEnd = "End Date/Time",
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (dateRange) {
      if (dateRange.start) {
        setStartDate(dateRange.start);
      }

      if (dateRange.end) {
        setEndDate(dateRange.end);
      }
    }
  }, [dateRange]);

  return (
    <div className="date-time-range-picker">
      <div className="input-container">
        <span className="label">{captionStart}</span>
        <DatePicker
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            onChange(date, endDate);
          }}
          showTimeSelect={showTimeSelect}
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat={showTimeSelect ? "MMMM d, yyyy h:mm aa" : "MMMM d, yyyy"}
          timeCaption="time"
          selectsStart
          startDate={startDate}
          endDate={endDate}
          portalId="root-portal"
        />
      </div>
      <div className="input-container">
        <span className="label">{captionEnd}</span>
        <DatePicker
          selected={endDate}
          onChange={(date) => {
            setEndDate(date);
            onChange(startDate, date);
          }}
          showTimeSelect={showTimeSelect}
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat={showTimeSelect ? "MMMM d, yyyy h:mm aa" : "MMMM d, yyyy"}
          timeCaption="time"
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          portalId="root-portal"
        />
      </div>
    </div>
  );
};

DateTimeRangePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  showTimeSelect: PropTypes.bool,
  captionStart: PropTypes.string,
  captionEnd: PropTypes.string,
  dateRange: PropTypes.shape({
    start: PropTypes.date,
    end: PropTypes.date,
  }),
};

export default DateTimeRangePicker;
