import React from "react";
import classNames from "classnames";
import Button from "@client.core.components/Button";
import "./ToggleButton.scss";
import { VoidFunc } from "types";

interface ToggleButtonProps {
  value: boolean;
  onChange: VoidFunc<boolean>;
  className?: string;
  text: string;
}

const ToggleButton = ({
  value,
  onChange,
  className = "",
  text = "",
}: ToggleButtonProps) => {
  return (
    <Button
      text={text}
      className={classNames("toggle-button", className, { toggled: value })}
      onClick={() => onChange(!value)}
    />
  );
};

export default ToggleButton;
