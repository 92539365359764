import React, { useEffect } from "react";
import PropTypes from "prop-types";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import Input from "@client.core.components/Input";
import "./PlacesAutocomplete.scss";

const PlacesAutocomplete = ({ setLocation, onChange, location, label }) => {
  const {
    setValue,
    suggestions: { data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    setValue(address, false);
    onChange(address);
    clearSuggestions();

    if (setLocation) {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setLocation({ lat, lng }, address);
    }
  };

  useEffect(() => {
    const handleOutsideClick = () => {
      clearSuggestions();
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  });
  return (
    <div className="places-autocomplete">
      <Input
        value={location}
        label={label}
        onChange={(v) => {
          setValue(v);
          onChange(v);
        }}
      />
      {data.length > 0 && (
        <ul className="suggestions-list">
          {data.map((suggestion) => (
            <li
              key={suggestion.place_id}
              onClick={() => handleSelect(suggestion.description)}
              className="suggestion-item"
            >
              {suggestion.description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

PlacesAutocomplete.propTypes = {
  setLocation: PropTypes.func,
  location: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default PlacesAutocomplete;
