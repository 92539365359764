import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "@client.core.components/Button";
import Input from "@client.core.components/Input";
import { validateModel } from "@client.utils/form";
import WelcomeCard from "@client.components/WelcomeCard/WelcomeCard";
import UnauthorizedPageCard from "@client.components/UnauthorizedPageCard/UnauthorizedPageCard";
import PasswordValidator from "@client.components/PasswordValidator/PasswordValidator";
import RegisterUserModel from "@client.models/registerUser";
import "./RegisterUser.scss";
import Dropdown from "@client.core.components/Dropdown";
import CustomerType from "@client.enums/customerType";

const RegisterUser = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setModel(
      new RegisterUserModel(
        {},
        {
          password: t("pages.InviteUser.validation.password"),
          email: t("pages.Login.validation.emailAddress"),
          invalidEmail: t("pages.Login.validation.invalidEmail"),
          firstName: t("pages.InviteUser.validation.firstName"),
          lastName: t("pages.InviteUser.validation.lastName"),
          phone: t("pages.InviteUser.validation.phone"),
          customerTypeId: t("pages.RegisterUser.validation.customerTypeId"),
        }
      )
    );
  }, [t]);

  const changeModel = useCallback(
    (v, field) => {
      setModel(
        new RegisterUserModel({ ...model, [field]: v }, model.validationMsgs)
      );
    },
    [model]
  );

  const register = useCallback(() => {
    const isValid = validateModel(model);
    if (isValid) {
      alert("Valid = " + JSON.stringify(model));
    } else {
      alert("Invalid = " + JSON.stringify(model));
    }
  }, [dispatch, model]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      register();
    }
  };

  const customerTypes = [
    {
      id: CustomerType.Advertiser,
      label: t("pages.RegisterUser.customerType.advertiser"),
    },
    {
      id: CustomerType.Partner,
      label: t("pages.RegisterUser.customerType.partner"),
    },
  ];
  return (
    <div className="user-creation">
      {!isLoading && (
        <WelcomeCard>
          <div className="welcome">
            <span>{t("pages.InviteUser.welcome.title")}</span>
            <div className="welcome-description">
              {t("pages.InviteUser.welcome.description")}
            </div>
          </div>
        </WelcomeCard>
      )}
      <UnauthorizedPageCard isLoading={isLoading}>
        <div className="title">{t("pages.RegisterUser.header")}</div>
        <div className="description">{t("pages.InviteUser.description")}</div>
        <div className="title subtitle">
          {t("pages.InviteUser.getStarted")},&nbsp;
          <span className="signin">{t("pages.InviteUser.signin")}</span>
          &nbsp;
          {t("pages.InviteUser.createAccount")}
        </div>
        <div className="row">
          <Input
            id="firstName"
            placeholder={t("pages.InviteUser.firstName")}
            value={model.firstName}
            onChange={(v) => changeModel(v, "firstName")}
            label={t("pages.InviteUser.lastName")}
            onKeyDown={handleKeyPress}
          />
          <Input
            id="lastName"
            placeholder={t("pages.InviteUser.lastName")}
            value={model.lastName}
            onChange={(v) => changeModel(v, "lastName")}
            label={t("pages.InviteUser.lastName")}
            onKeyDown={handleKeyPress}
          />
        </div>
        <div className="row">
          <Input
            id="email"
            placeholder={t("pages.InviteUser.email")}
            value={model.email}
            onChange={(v) => changeModel(v, "email")}
            label={t("pages.InviteUser.email")}
            onKeyDown={handleKeyPress}
          />
          <Input
            id="phone"
            placeholder={t("pages.InviteUser.phone")}
            value={model.phone}
            onChange={(v) => changeModel(v, "phone")}
            label={t("pages.InviteUser.phone")}
            onKeyDown={handleKeyPress}
          />
        </div>
        <Dropdown
          items={customerTypes}
          defaultValue={model.customerTypeId}
          label={t("pages.RegisterUser.customerType")}
          placeholder={t("pages.RegisterUser.customerType")}
          valueKey="id"
          labelKey="label"
          onPrepareValue={(v) => parseInt(v)}
          onSelect={(val) => {
            changeModel(val, "customerTypeId");
          }}
        />
        <div className="row">
          <Input
            id="company"
            placeholder={t("pages.RegisterUser.company")}
            value={model.company}
            onChange={(v) => changeModel(v, "company")}
            label={t("pages.RegisterUser.company")}
            onKeyDown={handleKeyPress}
          />
          <Input
            id="website"
            placeholder={t("pages.RegisterUser.website")}
            value={model.website}
            onChange={(v) => changeModel(v, "website")}
            label={t("pages.RegisterUser.website")}
            onKeyDown={handleKeyPress}
          />
        </div>
        <PasswordValidator
          id="pswrd"
          value={model.password}
          placeholder={t("pages.InviteUser.password")}
          onChange={(v) => changeModel(v, "password")}
          onKeyDown={handleKeyPress}
        />
        <div className="btn">
          <Button
            text={t("pages.InviteUser.signinForFree")}
            onClick={register}
          />
        </div>
      </UnauthorizedPageCard>
    </div>
  );
};

export default RegisterUser;
