import React, { useEffect } from "react";
import TopBoardOverview from "@client.components/TopBoardOverview";
import { useDispatch, useSelector } from "react-redux";
import DevicesOverview from "./DevicesOverview";
import {
  fetchMapAsync,
  fetchPlatformDashboardAsync,
  makeDashboardFilter,
  makePlatformDashboard,
} from "@client.features/main/dashboard";
import "./Dashboard.scss";
import { AppDispatch } from "@client.store";

const DashboardPage = () => {
  const platformDashboard = useSelector(makePlatformDashboard);
  const dashboardFilter = useSelector(makeDashboardFilter);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchPlatformDashboardAsync());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchMapAsync());
  }, [
    dispatch,
    dashboardFilter.enableLocations,
    dashboardFilter.enableHeatmap,
    dashboardFilter.id,
  ]);

  return (
    <div className="page dashboard">
      <TopBoardOverview
        className="top-board-dashboard-overview"
        activeCampaigns={platformDashboard.activeCampaigns}
        adPlays={platformDashboard.dailyAdPlays}
        adViews={platformDashboard.dailyAdViews}
        budgetSpent={platformDashboard.dailyBudgetSpent}
      />
      <DevicesOverview
        deviceMap={platformDashboard.deviceMap}
        deviceHeatmap={platformDashboard.deviceHeatmap}
        deviceLocation={platformDashboard.deviceLocations}
      />
    </div>
  );
};

export default DashboardPage;
