import React from "react";
import PropTypes from "prop-types";
import "./PausedMessage.scss";
import { faPauseCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const PausedMessage = ({ campaign }) => {
  const { t } = useTranslation();
  return (
    <div className="pending-message-content">
      <FontAwesomeIcon size={"5x"} icon={faPauseCircle} />
      <div>{t("pages.Campaign.PausedMessage", { name: campaign?.campaignName || "Campaign" })}</div>
      <div className="pending-details">
        {t("pages.Campaign.PausedDetails", {
          name: campaign?.campaignName || t("pages.Campaign.DefaultName"),
        })}
      </div>
    </div>
  );
};

PausedMessage.propTypes = {
  campaign: PropTypes.any,
};

export default PausedMessage;