

// heatmaps retrieved from the server.. changed to retrieve from the parent component
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
// import { getCampaignHeatmapAsync, makeCampaignHeatmap } from "./reducer";
import Loader from "@client.core.components/Loader";
import GoogleMap from "@client.components/GoogleMap_campaign";

// eslint-disable-next-line no-unused-vars
const HeatMap = ({ campaignId, selectedHeatMap ,dateFrom, dateTo }) => {
  // console.log("HeatMap -> selectedHeatMap", selectedHeatMap);
  // const dispatch = useDispatch();
  // const selectedCampaignHeatmap = useSelector(makeCampaignHeatmap);
  // console.log("HeatMap -> selectedCampaignHeatmap", selectedCampaignHeatmap);
  // console.log("HeatMap -> DateFrom", dateFrom);
  // useEffect(() => {
  //   const id = campaignId;
  //   const zoneId = ""; 
  //   dispatch(getCampaignHeatmapAsync({ id, zoneId }));
  // }, [dispatch]);


  // if (selectedCampaignHeatmap.loading) {
  //   return <Loader loading={true} />;
  // }
  
  

  return (
    <GoogleMap
      width={"100%"}
      height={"380px"}
      // heatmapData={selectedCampaignHeatmap.heatmap}
      heatmapData={selectedHeatMap}
    />
  );
};

HeatMap.propTypes = {
  selectedHeatMap: PropTypes.string,
  campaignId: PropTypes.string,
  dateFrom: PropTypes.instanceOf(Date),
  dateTo: PropTypes.instanceOf(Date),
};

export default React.memo(HeatMap);





























// // heatmaps retrieved from the server.. changed to retrieve from the parent component
// import React, { useEffect } from "react";
// import PropTypes from "prop-types";
// import { useDispatch, useSelector } from "react-redux";
// import { getCampaignHeatmapAsync, makeCampaignHeatmap } from "./reducer";
// import Loader from "@client.core.components/Loader";
// import GoogleMap from "@client.components/GoogleMap";

// // eslint-disable-next-line no-unused-vars
// const HeatMap = ({ campaignId, selectedDevice ,dateFrom, dateTo }) => {
//   const dispatch = useDispatch();
//   const selectedCampaignHeatmap = useSelector(makeCampaignHeatmap);
//   console.log("HeatMap -> selectedCampaignHeatmap", selectedCampaignHeatmap);
//   // console.log("HeatMap -> DateFrom", dateFrom);
//   useEffect(() => {
//     const id = campaignId;
//     const zoneId = ""; 
//     dispatch(getCampaignHeatmapAsync({ id, zoneId }));
//   }, [dispatch]);


//   if (selectedCampaignHeatmap.loading) {
//     return <Loader loading={true} />;
//   }
  
  

//   return (
//     <GoogleMap
//       width={"100%"}
//       height={"380px"}
//       // heatmapData={deviceHeatmap}
//       heatmapData={selectedCampaignHeatmap.heatmap}
//     />
//   );
// };

// HeatMap.propTypes = {
//   campaignId: PropTypes.string,
//   selectedDevice: PropTypes.string,
//   dateFrom: PropTypes.instanceOf(Date),
//   dateTo: PropTypes.instanceOf(Date),
// };

// export default React.memo(HeatMap);





























// old code


// import React, { useEffect } from "react";
// import PropTypes from "prop-types";
// import { useDispatch, useSelector } from "react-redux";
// import { getCampaignHeatmapAsync, makeCampaignHeatmap } from "./reducer";
// import Loader from "@client.core.components/Loader";
// import GoogleMap from "@client.components/GoogleMap";

// // eslint-disable-next-line no-unused-vars
// const HeatMap = ({ campaignId, dateFrom, dateTo }) => {
//   const dispatch = useDispatch();
//   const selectedCampaignHeatmap = useSelector(makeCampaignHeatmap);

//   // useEffect(() => {
//   //   dispatch(getCampaignHeatmapAsync(campaignId));
//   // }, [dispatch, campaignId]);

//   if (selectedCampaignHeatmap.loading) {
//     return <Loader loading={true} />;
//   }
//   const deviceHeatmap = [
//     {
//       latitude: 32.0935137,
//       longitude: 34.8259006,
//     },
//     {
//       latitude: 32.0271732,
//       longitude: 34.781738,
//     },
//     {
//       latitude: 32.0934501,
//       longitude: 34.8259327,
//     },
//     {
//       latitude: 32.0270913,
//       longitude: 34.781738,
//     },
//     {
//       latitude: 32.0270368,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.093341,
//       longitude: 34.8259435,
//     },
//     {
//       latitude: 32.0269094,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.0932319,
//       longitude: 34.8259971,
//     },
//     {
//       latitude: 32.0268185,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.0931319,
//       longitude: 34.8260186,
//     },
//     {
//       latitude: 32.0267184,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.0930683,
//       longitude: 34.8260186,
//     },
//     {
//       latitude: 32.0266456,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.0929865,
//       longitude: 34.8260186,
//     },
//     {
//       latitude: 32.0265456,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.0929047,
//       longitude: 34.8260508,
//     },
//     {
//       latitude: 32.0264001,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.092832,
//       longitude: 34.8260615,
//     },
//     {
//       latitude: 32.0262909,
//       longitude: 34.781738,
//     },
//     {
//       latitude: 32.0927593,
//       longitude: 34.8260722,
//     },
//     {
//       latitude: 32.0262454,
//       longitude: 34.781738,
//     },
//     {
//       latitude: 32.0927138,
//       longitude: 34.8260937,
//     },
//     {
//       latitude: 32.0261545,
//       longitude: 34.781738,
//     },
//     {
//       latitude: 32.0926502,
//       longitude: 34.8261259,
//     },
//     {
//       latitude: 32.0260362,
//       longitude: 34.781738,
//     },
//     {
//       latitude: 32.0925684,
//       longitude: 34.8261473,
//     },
//     {
//       latitude: 32.0259635,
//       longitude: 34.7817058,
//     },
//     {
//       latitude: 32.0924775,
//       longitude: 34.8261473,
//     },
//     {
//       latitude: 32.0258452,
//       longitude: 34.7817058,
//     },
//     {
//       latitude: 32.0924411,
//       longitude: 34.826201,
//     },
//     {
//       latitude: 32.0257724,
//       longitude: 34.7817058,
//     },
//     {
//       latitude: 32.0924775,
//       longitude: 34.8263834,
//     },
//     {
//       latitude: 32.0257361,
//       longitude: 34.7817058,
//     },
//     {
//       latitude: 32.0924957,
//       longitude: 34.8264799,
//     },
//     {
//       latitude: 32.0256451,
//       longitude: 34.7817058,
//     },
//     {
//       latitude: 32.0924957,
//       longitude: 34.8265765,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7817058,
//     },
//     {
//       latitude: 32.0925048,
//       longitude: 34.8267267,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7815449,
//     },
//     {
//       latitude: 32.0925411,
//       longitude: 34.8268447,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7814698,
//     },
//     {
//       latitude: 32.0925684,
//       longitude: 34.8269198,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7814054,
//     },
//     {
//       latitude: 32.0925775,
//       longitude: 34.8270378,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7812874,
//     },
//     {
//       latitude: 32.0925775,
//       longitude: 34.8271022,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7811586,
//     },
//     {
//       latitude: 32.0925775,
//       longitude: 34.8271987,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7810299,
//     },
//     {
//       latitude: 32.0925866,
//       longitude: 34.8273275,
//     },
//     {
//       latitude: 32.0255905,
//       longitude: 34.7809119,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7807939,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7806651,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7805578,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7804505,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7803754,
//     },
//     {
//       latitude: 32.0255996,
//       longitude: 34.7802682,
//     },
//     {
//       latitude: 32.0255996,
//       longitude: 34.7801179,
//     },
//     {
//       latitude: 32.0255996,
//       longitude: 34.7800321,
//     },
//     {
//       latitude: 32.0255996,
//       longitude: 34.7800107,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7799248,
//     },
//     {
//       latitude: 32.0255541,
//       longitude: 34.7799248,
//     },
//     {
//       latitude: 32.0254814,
//       longitude: 34.7799677,
//     },
//     {
//       latitude: 32.0254541,
//       longitude: 34.7800214,
//     },
//     {
//       latitude: 32.0254268,
//       longitude: 34.7800214,
//     },
//     {
//       latitude: 32.0253995,
//       longitude: 34.7800321,
//     },
//     {
//       latitude: 32.0253449,
//       longitude: 34.780075,
//     },
//     {
//       latitude: 32.0252812,
//       longitude: 34.780075,
//     },
//     {
//       latitude: 32.0252176,
//       longitude: 34.7801072,
//     },
//     {
//       latitude: 32.0251539,
//       longitude: 34.7801179,
//     },
//     {
//       latitude: 32.0251084,
//       longitude: 34.7801179,
//     },
//     {
//       latitude: 32.0250811,
//       longitude: 34.7801179,
//     },
//     {
//       latitude: 32.0250357,
//       longitude: 34.7801179,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.7801179,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.7803111,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.7804398,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.7804935,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.78059,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.7806866,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.7807939,
//     },
//     {
//       latitude: 32.0248173,
//       longitude: 34.7807939,
//     },
//     {
//       latitude: 32.0247537,
//       longitude: 34.7807939,
//     },
//     {
//       latitude: 32.0247264,
//       longitude: 34.7807939,
//     },
//     {
//       latitude: 32.0247264,
//       longitude: 34.7807939,
//     },
//     {
//       latitude: 32.0246718,
//       longitude: 34.7807831,
//     },
//     {
//       latitude: 32.0246263,
//       longitude: 34.7807778,
//     },
//     {
//       latitude: 32.0245263,
//       longitude: 34.7807724,
//     },
//     {
//       latitude: 32.0271732,
//       longitude: 34.781738,
//     },
//     {
//       latitude: 32.0270913,
//       longitude: 34.781738,
//     },
//     {
//       latitude: 32.0270368,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.0269094,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.0268185,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.0267184,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.0266456,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.0265456,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.0264001,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.0262909,
//       longitude: 34.781738,
//     },
//     {
//       latitude: 32.0262454,
//       longitude: 34.781738,
//     },
//     {
//       latitude: 32.0261545,
//       longitude: 34.781738,
//     },
//     {
//       latitude: 32.0260362,
//       longitude: 34.781738,
//     },
//     {
//       latitude: 32.0259635,
//       longitude: 34.7817058,
//     },
//     {
//       latitude: 32.0258452,
//       longitude: 34.7817058,
//     },
//     {
//       latitude: 32.0257724,
//       longitude: 34.7817058,
//     },
//     {
//       latitude: 32.0257361,
//       longitude: 34.7817058,
//     },
//     {
//       latitude: 32.0256451,
//       longitude: 34.7817058,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7817058,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7815449,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7814698,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7814054,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7812874,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7811586,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7810299,
//     },
//     {
//       latitude: 32.0255905,
//       longitude: 34.7809119,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7807939,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7806651,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7805578,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7804505,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7803754,
//     },
//     {
//       latitude: 32.0255996,
//       longitude: 34.7802682,
//     },
//     {
//       latitude: 32.0255996,
//       longitude: 34.7801179,
//     },
//     {
//       latitude: 32.0255996,
//       longitude: 34.7800321,
//     },
//     {
//       latitude: 32.0255996,
//       longitude: 34.7800107,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7799248,
//     },
//     {
//       latitude: 32.0255541,
//       longitude: 34.7799248,
//     },
//     {
//       latitude: 32.0254814,
//       longitude: 34.7799677,
//     },
//     {
//       latitude: 32.0254541,
//       longitude: 34.7800214,
//     },
//     {
//       latitude: 32.0254268,
//       longitude: 34.7800214,
//     },
//     {
//       latitude: 32.0253995,
//       longitude: 34.7800321,
//     },
//     {
//       latitude: 32.0253449,
//       longitude: 34.780075,
//     },
//     {
//       latitude: 32.0252812,
//       longitude: 34.780075,
//     },
//     {
//       latitude: 32.0252176,
//       longitude: 34.7801072,
//     },
//     {
//       latitude: 32.0251539,
//       longitude: 34.7801179,
//     },
//     {
//       latitude: 32.0251084,
//       longitude: 34.7801179,
//     },
//     {
//       latitude: 32.0250811,
//       longitude: 34.7801179,
//     },
//     {
//       latitude: 32.0250357,
//       longitude: 34.7801179,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.7801179,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.7803111,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.7804398,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.7804935,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.78059,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.7806866,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.7807939,
//     },
//     {
//       latitude: 32.0248173,
//       longitude: 34.7807939,
//     },
//     {
//       latitude: 32.0247537,
//       longitude: 34.7807939,
//     },
//     {
//       latitude: 32.0247264,
//       longitude: 34.7807939,
//     },
//     {
//       latitude: 32.0247264,
//       longitude: 34.7807939,
//     },
//     {
//       latitude: 32.0246718,
//       longitude: 34.7807831,
//     },
//     {
//       latitude: 32.0246263,
//       longitude: 34.7807778,
//     },
//     {
//       latitude: 32.0245263,
//       longitude: 34.7807724,
//     },
//     {
//       latitude: 32.0271732,
//       longitude: 34.781738,
//     },
//     {
//       latitude: 32.0270913,
//       longitude: 34.781738,
//     },
//     {
//       latitude: 32.0270368,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.0269094,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.0268185,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.0267184,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.0266456,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.0265456,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.0264001,
//       longitude: 34.7817273,
//     },
//     {
//       latitude: 32.0262909,
//       longitude: 34.781738,
//     },
//     {
//       latitude: 32.0262454,
//       longitude: 34.781738,
//     },
//     {
//       latitude: 32.0261545,
//       longitude: 34.781738,
//     },
//     {
//       latitude: 32.0260362,
//       longitude: 34.781738,
//     },
//     {
//       latitude: 32.0259635,
//       longitude: 34.7817058,
//     },
//     {
//       latitude: 32.0258452,
//       longitude: 34.7817058,
//     },
//     {
//       latitude: 32.0257724,
//       longitude: 34.7817058,
//     },
//     {
//       latitude: 32.0257361,
//       longitude: 34.7817058,
//     },
//     {
//       latitude: 32.0256451,
//       longitude: 34.7817058,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7817058,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7815449,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7814698,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7814054,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7812874,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7811586,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7810299,
//     },
//     {
//       latitude: 32.0255905,
//       longitude: 34.7809119,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7807939,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7806651,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7805578,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7804505,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7803754,
//     },
//     {
//       latitude: 32.0255996,
//       longitude: 34.7802682,
//     },
//     {
//       latitude: 32.0255996,
//       longitude: 34.7801179,
//     },
//     {
//       latitude: 32.0255996,
//       longitude: 34.7800321,
//     },
//     {
//       latitude: 32.0255996,
//       longitude: 34.7800107,
//     },
//     {
//       latitude: 32.0255814,
//       longitude: 34.7799248,
//     },
//     {
//       latitude: 32.0255541,
//       longitude: 34.7799248,
//     },
//     {
//       latitude: 32.0254814,
//       longitude: 34.7799677,
//     },
//     {
//       latitude: 32.0254541,
//       longitude: 34.7800214,
//     },
//     {
//       latitude: 32.0254268,
//       longitude: 34.7800214,
//     },
//     {
//       latitude: 32.0253995,
//       longitude: 34.7800321,
//     },
//     {
//       latitude: 32.0253449,
//       longitude: 34.780075,
//     },
//     {
//       latitude: 32.0252812,
//       longitude: 34.780075,
//     },
//     {
//       latitude: 32.0252176,
//       longitude: 34.7801072,
//     },
//     {
//       latitude: 32.0251539,
//       longitude: 34.7801179,
//     },
//     {
//       latitude: 32.0251084,
//       longitude: 34.7801179,
//     },
//     {
//       latitude: 32.0250811,
//       longitude: 34.7801179,
//     },
//     {
//       latitude: 32.0250357,
//       longitude: 34.7801179,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.7801179,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.7803111,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.7804398,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.7804935,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.78059,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.7806866,
//     },
//     {
//       latitude: 32.0249447,
//       longitude: 34.7807939,
//     },
//     {
//       latitude: 32.0248173,
//       longitude: 34.7807939,
//     },
//     {
//       latitude: 32.0247537,
//       longitude: 34.7807939,
//     },
//     {
//       latitude: 32.0247264,
//       longitude: 34.7807939,
//     },
//     {
//       latitude: 32.0247264,
//       longitude: 34.7807939,
//     },
//     {
//       latitude: 32.0246718,
//       longitude: 34.7807831,
//     },
//     {
//       latitude: 32.0246263,
//       longitude: 34.7807778,
//     },
//     {
//       latitude: 32.0245263,
//       longitude: 34.7807724,
//     },
//   ];
//   return (
//     <GoogleMap
//       width={"100%"}
//       height={"380px"}
//       heatmapData={deviceHeatmap}
//       // heatmapData={selectedCampaignHeatmap.heatmap}
//     />
//   );
// };

// HeatMap.propTypes = {
//   campaignId: PropTypes.string,
//   dateFrom: PropTypes.instanceOf(Date),
//   dateTo: PropTypes.instanceOf(Date),
// };

// export default React.memo(HeatMap);
