import React, { useCallback, forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Input from "@client.core.components/Input";
import { useTranslation } from "react-i18next";
import { validateModel } from "@client.utils/form";
import ZoneModel from "@client.models/zone";
import ColorPicker from "@client.core.components/ColorPicker";
import { PlacesAutocomplete } from "@client.components/GoogleMap";
import ServiceProvider from "@client.services/provider";

const ZoneManagement = forwardRef(({ zoneModel }, ref) => {
  const { t } = useTranslation();

  const [model, setModel] = useState(
    new ZoneModel(
      {},
      {
        zoneName: t(
          "pages.Locations.components.ZoneManagement.validation.zoneName"
        ),
        primePrice: t(
          "pages.Locations.components.ZoneManagement.validation.primeRate"
        ),
        ppvPrice: t(
          "pages.Locations.components.ZoneManagement.validation.ppvRate"
        ),
        color: t("pages.Locations.components.ZoneManagement.validation.color"),
      }
    )
  );

  useEffect(() => {
    if (!zoneModel._reactName)
      setModel(new ZoneModel({ ...zoneModel }, zoneModel.validationMsgs));
  }, [t, zoneModel]);

  React.useImperativeHandle(ref, () => ({
    managementItem: model,
    validate: () => {
      const isValid = validateModel(model, model.validationRules);
      return isValid;
    },
  }));

  const changeModel = useCallback(
    (v, field) => {
      setModel(new ZoneModel({ ...model, [field]: v }, model.validationMsgs));
    },
    [model]
  );

  return (
    <div className="create-device" ref={ref}>
      <PlacesAutocomplete
        location={model.zoneName}
        label={t("pages.Locations.components.ZoneManagement.name")}
        onChange={(v) => {
          changeModel(v, "zoneName");
        }}
        // setLocation={(_coordinates, zone) => {
        //   ServiceProvider.Zone.getPolygon(zone).then((data) => {
        //     changeModel(data, "polygon");
        //   });
        // }}
        setLocation={(_coordinates, zone) => {
          //Reminder to myself of how i fixed for future reference - Tamir
          // Preserve the current state of model
          changeModel(zone, "zoneName"); // Update the name immediately on selection
          ServiceProvider.Zone.getPolygon(zone).then((data) => {
            // console.log("Updating zoneName:", zone);
            // console.log("Updating polygon:", data);
            setModel((prevModel) => {
              // Only update the polygon field, keeping the rest of the model intact
              return new ZoneModel(
                {
                  ...prevModel,
                  polygon: data, // Update the polygon
                },
                prevModel.validationMsgs
              );
            });
            // console.log("Updating zoneName:", zone);
            // console.log("Updating polygon:", data);
          }).catch((error) => {
            alert("Location not found");
          }
          );
        }}
        
      />
      <Input
        value={model.ppvPrice}
        type="number"
        onChange={(v) => changeModel(v, "ppvPrice")}
        label={t("pages.Locations.components.ZoneManagement.ppvRate")}
      />
      <Input
        value={model.primePrice}
        type="number"
        onChange={(v) => changeModel(v, "primePrice")}
        label={t("pages.Locations.components.ZoneManagement.primeRate")}
      />
      <div className="color-container">
        <span>{t("pages.Locations.components.ZoneManagement.color")}</span>
        <ColorPicker
          hexColor={model.color}
          onChange={(v) => changeModel(v, "color")}
        />
      </div>
    </div>
  );
});

ZoneManagement.displayName = "ZoneManagement";

ZoneManagement.defaultProps = {
  zoneModel: {},
};

ZoneManagement.propTypes = {
  zoneModel: PropTypes.object,
};

export default ZoneManagement;
