import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { OverviewBlock } from "./OverviewBlock";
import { makeProfile } from "@client.pages/Login/reducer";
import "./TopBoardOverview.scss";
import classNames from "classnames";

export const TopBoardOverview = ({
  budgetSpent,
  adPlays,
  adViews,
  activeCampaigns,
  className,
}) => {
  const { t } = useTranslation();
  const profile = useSelector(makeProfile);

  return (
    <div className={classNames("top-board-overview", className)}>
      <OverviewBlock
        value={budgetSpent}
        icon="budgetSpent"
        text={t("components.TopBoardOverview.budgetSpent")}
        className="budget-spent"
        currency={profile.currency}
      />
      <OverviewBlock
        value={adPlays}
        icon="adPlays"
        text={t("components.TopBoardOverview.adPlays")}
        className="ad-plays"
      />
      <OverviewBlock
        value={adViews}
        icon="adViews"
        text={t("components.TopBoardOverview.adViews")}
        className="ad-views"
      />
      <OverviewBlock
        value={activeCampaigns}
        icon="activeCampaigns"
        text={t("components.TopBoardOverview.activeCampaigns")}
        className="active-campaigns"
      />
    </div>
  );
};

TopBoardOverview.defaultProps = {
  budgetSpent: 0,
  adPlays: 0,
  adViews: 0,
  activeCampaigns: 0,
  loading: false,
};

TopBoardOverview.propTypes = {
  budgetSpent: PropTypes.number,
  adPlays: PropTypes.number,
  adViews: PropTypes.number,
  activeCampaigns: PropTypes.number,
  loading: PropTypes.bool,
  className: PropTypes.string,
};
