import React from "react";
import PropTypes from "prop-types";
import "./RenderXYZ.scss";

const RenderXYZ = ({ paramX="X", paramY="Y", paramZ="Z",x, y, z }) => {
  return (
    <div className="xyz">
      <span className="key">{paramX}:</span>
      <span className="val">{x || "N/A"}</span>
      <span className="key">{paramY}:</span>
      <span className="val">{y || "N/A"}</span>
      <span className="key">{paramZ}:</span>
      <span className="val">{z || "N/A"}</span>
    </div>
  );
};

RenderXYZ.propTypes = {
  x: PropTypes.string,
  y: PropTypes.string,
  z: PropTypes.string,
  paramX: PropTypes.string,
  paramY: PropTypes.string,
  paramZ: PropTypes.string,
};

export default RenderXYZ;
