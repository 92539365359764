import React, { useState } from "react";
import PropTypes from "prop-types";
import Loader from "@client.core.components/Loader";
import moment from "moment";
import NewDataTablePagination from "@client.components/NewDataTablePagination";
import { useTranslation } from "react-i18next";
import Roles from "@client.enums/roles";
import config from "@client.config";
import "./NewDataTable.scss";

const NewDataTable = ({ data, isLoading, onEditClick,onEditAdvertiserClick, pagination }) => {
  const { t } = useTranslation();

  const [selectedUser, setSelectedUser] = useState(null);

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  const handleEditClick = () => {
    if (selectedUser) {
      onEditClick(selectedUser.Id);
    }
  };
  const handleEditAdvertiserClick = () => {
    if (selectedUser) {
      onEditAdvertiserClick(selectedUser.Id);
    }
  };

  // Function to map role number to role name
  const getRoleName = (role) => {
    switch (role) {
    case Roles.Admin:
      return "Admin";
    case Roles.Manager:
      return "Manager";
    case Roles.Employee:
      return "Employee";
    case Roles.Technician:
      return "Technician";
    case Roles.User:
      return "User";
    default:
      return "N/A";
    }
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <Loader loading={true} />
      </div>
    );
  }

  const renderInfo = (field, value) => {
    return (
      <div className="card">
        <h2 className="card-title">{t(field)}</h2>
        <p className="card-description">{value}</p>
      </div>
    );
  };

  return (
    <div className="new-data-table">
      <div className="content">
        {/* Left side - Users List */}
        <div className="user-list">
          {data && data.length > 0 ? (
            data.map((user) => (
              <div
                key={user.Id}
                className={`user-list-item ${
                  selectedUser === user ? "selected" : ""
                }`}
                onClick={() => handleUserClick(user)}
              >
                {user.avatarUrl ? (
                  <img
                    src={user.avatarUrl}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                    alt="Avatar"
                    className="avatar"
                  />
                ) : (
                  <img
                    src="https://avatar.iran.liara.run/public/28"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                    alt="Default Avatar"
                  />
                )}
                <span className="user-name">{`${user.firstName} ${user.lastName}`}</span>
              </div>
            ))
          ) : (
            <p>No users available</p>
          )}
        </div>

        {/* Right side - User Details */}
        <div className="user-details">
          {selectedUser ? (
            <>
              <h1>{t("pages.Users.columns.userInformation")}</h1>

              <div className="user-info-grid">
                {renderInfo(
                  "pages.Users.columns.firstName",
                  selectedUser.firstName
                )}
                {renderInfo(
                  "pages.Users.columns.lastName",
                  selectedUser.lastName
                )}
                {renderInfo(
                  "pages.Users.columns.email",
                  selectedUser.emailAddress
                )}
                {renderInfo(
                  "pages.Users.columns.role",
                  getRoleName(selectedUser.role) // Use the getRoleName function
                )}
                {renderInfo(
                  "pages.Users.columns.createdAt",
                  moment(selectedUser.createdAt).format(config.dateTimeFormat)
                )}
                {renderInfo(
                  "pages.Users.columns.lastLogin",
                  selectedUser.lastLoginTime ? moment(selectedUser.lastLoginTime).format(
                    config.dateTimeFormat
                  ) : "N/A"
                )}
                {renderInfo(
                  "pages.Users.columns.phoneNumber",
                  selectedUser.phoneNumber || "N/A"
                )}
                {renderInfo(
                  "pages.Users.columns.has2fa",
                  selectedUser.has2fa ? t("Yes") : t("No")
                )}
                {renderInfo(
                  "pages.Users.columns.isActive",
                  selectedUser.isActive ? t("Active") : t("Inactive")
                )}
              </div>

              <div className="user-details-action">
                <button className="button" onClick={handleEditClick}>
                  {t("pages.Users.columns.editUser")}
                </button>
                
                {/* <button className="button" onClick={handleEditAdvertiserClick}>
                  Edit Advertiser
                </button> */}
              </div>
            </>
          ) : (
            <p>{t("pages.Users.columns.selectUserToViewDetails")}</p>
          )}
        </div>
      </div>

      <div className="pagination-wrapper">
        {pagination && (
          <NewDataTablePagination
            total={pagination.total}
            itemsPerPage={pagination.itemsPerPage}
            currentPage={pagination.currentPage}
            onChange={pagination.onChange}
          />
        )}
      </div>
    </div>
  );
};

NewDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onEditAdvertiserClick: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    itemsPerPage: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
  }),
};

export default NewDataTable;
