import BaseService from "@client.services/baseService";

export default class VpnService extends BaseService {
  constructor() {
    super("vpn", true);
  }

  async getAll() {
    return super.post("", {
      query: `query{
	vpnServers{
		count
		results{
			Id
			serverName
			location
			ipAddress
			sshPort
      status
			username
			password
			status
		}
	}
}`,
    });
  }



  async getAll2(allFields = false) {
    return super.post("", {
      query: allFields
        ? `query {
        vpnServers {
          count
          results {
            Id
            ipAddress
            location
            serverName
            sshPort
            status
            username
            isActive
            createdAt
          }
        }
      }`
        : `query {
        vpnServers {
          count
          results {
            Id
            serverName
          }
        }
    }`,
    });
  }

  async create(model) {
    return super.post("", {
      query: `mutation CreateNewVpnServer($fields: VPNServerInputType) {
        createVpnServer(fields: $fields) {
          message
        }
      }`,
      variables: {
        fields: model.fields,
      },
    });
  }
}
