import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import SvgIcon from "@client.core.components/SvgIcon";
import MediaFile from "@client.components/MediaFile";
import "./UploaderPlaceholder.scss";

const UploaderPlaceholder = ({ onClick, className, description, url }) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={classNames("uploader-placeholder", className)}
        onClick={onClick}
      >
        {url ? (
          <MediaFile src={url} showThumbnail />
        ) : (
          <div className="drop-area-inner">
            {<SvgIcon name="photo" />}
            {t("pages.CreateCampaign.uploadPhoto")}
          </div>
        )}
      </div>
      {description && (
        <div className="footer">
          <div className="title">{t("components.Uploader.uploadPhoto")}</div>
          <div className="description">{description}</div>
        </div>
      )}
    </>
  );
};

UploaderPlaceholder.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
};

export default React.memo(UploaderPlaceholder);
