import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@client.core.components/SvgIcon";
import classNames from "classnames";
import Icon from "@client.core.components/Icon";

export const OverviewBlock = ({
  value,
  text,
  icon,
  className,
  description,
  currency,
}) => {
  return (
    <div className={classNames("overview-block", className)}>
      <Icon name="info" className="info" title={description || text} />
      <div className="overview-icon">
        <SvgIcon name={icon} />
      </div>
      <div className="overview-info">
        <div className="overview-value">
          {currency} {value}
        </div>
        <div className="overview-text">{text}</div>
      </div>
    </div>
  );
};

OverviewBlock.defaultProps = {};

OverviewBlock.propTypes = {
  value: PropTypes.number,
  icon: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  currency: PropTypes.string,
};
