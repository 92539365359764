import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import UploaderPlaceholder from "./UploaderPlaceholder";
import config from "@client.config";
import "./Uploader.scss";
import useToast from "@client.hooks/useToast";
import classNames from "classnames";
import SvgIcon from "@client.core.components/SvgIcon";

const Uploader = ({ onChange, placeholderUrl, isButton, className }) => {
  const maxSize = config.maxFileSizeInMb * 1024 * 1024;
  const { t } = useTranslation();
  const { showToastWarning } = useToast();

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
      "video/mp4": [],
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles && rejectedFiles.length > 0) {
        const rejectedFile = rejectedFiles[0];
        if (rejectedFile.file.size > maxSize) {
          showToastWarning("components.Uploader.fileSizeExceeds", {
            value: config.maxFileSizeInMb,
          });
        }
      } else {
        setFiles(acceptedFiles);
      }
    },
    maxSize: maxSize,
  });

  useEffect(() => {
    if (files.length > 0) onChange(files[0]);
  }, [onChange, files]);

  const content = (
    <UploaderPlaceholder
      url={placeholderUrl}
      className="uploader-rectangle"
      description={t("components.Uploader.maxSize", {
        value: config.maxFileSizeInMb,
      })}
    />
  );

  if (isButton) {
    return (
      <button
        {...getRootProps({
          className: classNames("dropzone button", className),
        })}
      >
        <input {...getInputProps()} />
        <SvgIcon name="plus" />
        <span>{t("pages.MediaLibrary.Assets.Upload")}</span>
      </button>
    );
  } else {
    return (
      <section className="uploader">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          {content}
        </div>
      </section>
    );
  }
};

Uploader.defaultProps = {
  placeholderUrl: undefined,
};

Uploader.propTypes = {
  onChange: PropTypes.func,
  placeholderUrl: PropTypes.string,
  className: PropTypes.string,
  isButton: PropTypes.bool,
};

export default Uploader;
