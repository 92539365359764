import React from 'react';
import PropTypes from "prop-types";
import { GoogleMap, OverlayView } from '@react-google-maps/api';
import MapMarker from "./MapMarker";

const Map = ({ locations }) => {
  // console.log("locations", locations);
  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };

  const center = {
    lat: 40.106746,
    lng: -74.2212774,
  };

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={10}
    >
      {locations.map((location, index) => (
        <OverlayView
          key={index}
          position={{
            lat: location.latitude,
            lng: location.longitude,
          }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <div style={{ transform: 'translate(-50%, -100%)' }}>
            <div>
              <MapMarker />
            </div>
          </div>
        </OverlayView>
      ))}
    </GoogleMap>
  );
};

Map.propTypes = {
  locations: PropTypes.any,
}

export default Map;