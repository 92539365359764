import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Title from "@client.components/Title";
import NoDataPlaceholder from "@client.core.components/NoDataPlaceholder";
import HierarchyNode from "@client.components/Hierarchy/HierarchyNode";
import DataTable from "@client.components/DataTable";
import { getColumns } from "./consts";
import permissionsData from "./permissionsData.json";
import "./PermissionsPage.scss";

const PermissionsPage = () => {
  const [selectedRole, setSelectedRole] = useState(null);

  const handleRoleSelect = (item) => {
    if (item.permissions) {
      setSelectedRole(item);
    }
  };

  const { t } = useTranslation();

  const togglePermission = (name, permissionType) => {
    if (!selectedRole) return;

    const updatedPermissions = selectedRole.permissions.map((perm) => {
      if (perm.name === name) {
        return {
          ...perm,
          [permissionType]: !perm[permissionType],
        };
      }
      return perm;
    });

    setSelectedRole({
      ...selectedRole,
      permissions: updatedPermissions,
    });
  };

  return (
    <div className="page">
      <Title text="pages.Permissions.title" />
      <div className="permissions">
        <div className="permissions-sidebar">
          {permissionsData.entities.map((role) => {
            return (
              <HierarchyNode
                key={role.id}
                node={role}
                onSelect={handleRoleSelect}
                selectedNode={selectedRole}
                isRoot={true}
              />
            );
          })}
        </div>

        <div className="permissions-content">
          {selectedRole ? (
            <DataTable
              columns={getColumns(t, togglePermission)}
              data={selectedRole.permissions}
            />
          ) : (
            <NoDataPlaceholder description="pages.Permissions.noDataDescription" />
          )}
        </div>
      </div>
    </div>
  );
};

export default PermissionsPage;
