import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "@client.components/DataTable";
import { getColumns } from "./consts";
import Title from "@client.components/Title";
import ServiceProvider from "@client.services/provider";
import Button from "@client.core.components/Button";
import { MODAL_EVENT } from "@client.components/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  createVpnAsync,
  getAllVpnServersAsync,
  makeVpnFilter,
  makeVpnServers,
  setFilter,
} from "./reducer";
import "./VpnManagement.scss";
import { getGraphqlResponseError } from "@client.utils/error";
import VpnManagement from "./components/VpnManagement";
import Input from "@client.core.components/Input";

// const ITEMS_PER_PAGE = 15;

const VpnManagementPage = () => {
  const { t } = useTranslation();
  const data = useSelector(makeVpnServers);
  const filter = useSelector(makeVpnFilter);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllVpnServersAsync());
  }, [dispatch]);

  const createTxt = t("pages.VpnManagement.createServer");
  const handleCreateClick = () => {
    ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
      show: true,
      withBodyRef: true,
      title: createTxt,
      component: <VpnManagement />,
      buttonSettings: {
        text: t("pages.VpnManagement.create"),
        onClick: async (component) => {
          const isValid = component.validate();

          console.log(component)

          return isValid
            ? dispatch(createVpnAsync(component.managementItem)).then(
                (resp) => {
                  const error = getGraphqlResponseError(resp);
                  if (!error) {
                    ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
                      show: false,
                    });
                  }
                }
              )
            : Promise.resolve();
        },
      },
    });
  };

  const handleFilterChange = (request) => {
    dispatch(
      setFilter({
        ...filter,
        ...request,
      })
    );
  };

  return (
    <div className="vpn-management page">
      <Title text="components.NavigationBar.vpn">
        <Button
          icon="plus"
          text={createTxt}
          onClick={handleCreateClick}
          className="button-create"
        />
      </Title>
      <div className="search">
        <div className="fields">
          <Input
            label={t("pages.VpnManagement.columns.name")}
            onChange={(v) =>
              handleFilterChange({
                name: v,
              })
            }
            value={filter.name}
          />
          <Input
            label={t("pages.VpnManagement.columns.ipAddress")}
            onChange={(v) =>
              handleFilterChange({
                ipAddress: v,
              })
            }
            value={filter.ipAddress}
          />
          <Input
            label={t("pages.VpnManagement.columns.location")}
            onChange={(v) =>
              handleFilterChange({
                location: v,
              })
            }
            value={filter.location}
          />
        </div>
        <Button
          text={t("pages.VpnManagement.search")}
          className="search-submit"
          onClick={() => {
            dispatch(getAllVpnServersAsync());
          }}
        />
      </div>
      <DataTable
        columns={getColumns(t)}
        data={data.results}
        isLoading={data.loading}
        // pagination={{
        //   total: data.count,
        //   ITEMS_PER_PAGE: ITEMS_PER_PAGE,
        //   onChange: setCurrentPage,
        // }}
      />
    </div>
  );
};

export default VpnManagementPage;
