import React, { useCallback, forwardRef } from "react";
import Input from "@client.core.components/Input";
import { validateModel } from "@client.utils/form";
import BaseModel from "@client.models/base";
import { get } from "lodash";
import Button from "@client.core.components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  makeDashboardFilter,
  setDashboardFilter,
} from "@client.features/main/dashboard";

class SearchModel extends BaseModel {
  constructor(search = {}, msg = {}) {
    super(msg);
    this.id = get(search, "id", "");

    this.validationMsgs = msg;
    this.validationRules = {
      id: {
        presence: { allowEmpty: false, message: "Id is required" },
      },
    };
  }

  get fields() {
    return {
      id: this.id,
    };
  }
}

const SearchContent = forwardRef((_, ref) => {
  const [model, setModel] = React.useState(new SearchModel({}));
  const dashboardFilter = useSelector(makeDashboardFilter);
  const dispatch = useDispatch();

  const validate = () => {
    const isValid = validateModel(model, model.validationRules);
    return isValid;
  };

  React.useImperativeHandle(ref, () => ({
    managementItem: model,
    validate,
  }));

  const changeModel = useCallback(
    (v, field) => {
      setModel(new SearchModel({ ...model, [field]: v }, model.validationMsgs));
    },
    [model]
  );

  return (
    <div className="search-by-id-modal" ref={ref}>
      <Input
        value={model.id}
        onChange={(v) => changeModel(v, "id")}
        label="ID"
      />
      <Button
        text="Search"
        onClick={() => {
          dispatch(
            setDashboardFilter({
              ...dashboardFilter,
              id: model.id,
            })
          );
        }}
      />
    </div>
  );
});

SearchContent.displayName = "SearchContent";

export default SearchContent;
