import React from "react";
import SvgIcon from "@client.core.components/SvgIcon";
import { useTranslation } from "react-i18next";
import "./NoDataPlaceholder.scss";

const NoDataPlaceholder = ({ description }: { description?: string }) => {
  const { t } = useTranslation();

  return (
    <div className="no-data-placeholder">
      <SvgIcon name="no-data" />
      <div className="text">
        {t("components.NoDataPlaceholder.noDataToShow")}
      </div>
      {description && <div className="description">{t(description)}</div>}
    </div>
  );
};

export default NoDataPlaceholder;
