import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "@client.components/DataTable";
import { getColumns } from "./consts";
import { useTranslation } from "react-i18next";
import Input from "@client.core.components/Input";
import Title from "@client.components/Title";
import DateTimeRangePicker from "@client.components/DateTimeRangePicker/DateTimeRangePicker";
import { getAllUserLogsAsync, makeUserLogs } from "./reducer";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
import "./UserLogs.scss";

const ITEMS_PER_PAGE = 10;

const UserLogsPage = () => {
  const { t } = useTranslation();
  const userLogs = useSelector(makeUserLogs);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const gridRef = useRef(null);
  const [filter, setFilter] = useState({
    query: "",
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    const offset = (currentPage - 1) * ITEMS_PER_PAGE;
    dispatch(
      getAllUserLogsAsync({
        offset,
        limit: ITEMS_PER_PAGE,
        search: filter.query,
        searchOn: "user_full_name,description", // Adjust search fields
        startDate: filter.startDate,
        endDate: filter.endDate,
      })
    );
  }, [filter, currentPage, dispatch]);

  const onRowClick = useCallback(
    (id) => {
      navigate(`${Pages.ProfileGeneral}/${id}`);
    },
    [navigate]
  );

  return (
    <div className="user-logs page">
      <Title text="components.NavigationBar.UserLogs" />
      <div className="search">
        <Input
          label="Search"
          value={filter.query}
          onChange={(e) => {
            setFilter({
              ...filter,
              query: e,
            });
          }}
        />
        <DateTimeRangePicker
          dateFrom={filter.startDate}
          dateTo={filter.endDate}
          showTimeSelect={false}
          captionStart="Start Date"
          captionEnd="End Date"
          onChange={(startDate, endDate) => {
            setFilter({
              ...filter,
              startDate,
              endDate,
            });
          }}
        />
      </div>
      <div className="content">
        <DataTable
          ref={gridRef}
          columns={getColumns(t, onRowClick)}
          data={userLogs.results}
          isLoading={userLogs.loading}
          pagination={{
            total: userLogs.count,
            itemsPerPage: ITEMS_PER_PAGE,
            onChange: setCurrentPage,
          }}
        />
      </div>
    </div>
  );
};

export default UserLogsPage;
