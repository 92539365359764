import React from "react";
import PropTypes from "prop-types";
import Dropdown from "@client.core.components/Dropdown";
import deviceTypes from "@client.enums/deviceTypes";
import { useTranslation } from "react-i18next";

const DeviceTypeDropdown = ({ value, onSelect, label }) => {
  const { t } = useTranslation();
  // const profile = useSelector(makeProfile);// This is the profile of the logged in user.. the role of the logged in user is used to determine the roles that can be assigned to a user
  const actions = [
    {
      value: deviceTypes.Unknown,
      label: t("pages.Devices.columns.unknown"),
    },
    {
      value: deviceTypes.Static,
      label: t("pages.Devices.columns.static"),
    },
    {
      value: deviceTypes.Dynamic,
      label: t("pages.Devices.columns.dynamic"),
    },
  ];

  return (
    <Dropdown
      items={actions}
      defaultValue={value}
      label={label}
      valueKey="value"
      labelKey="label"
      onPrepareValue={(v) => parseInt(v)}
      onSelect={onSelect}
    />
  );
};

DeviceTypeDropdown.defaultProps = {
  value: null,
  label: null,
};

DeviceTypeDropdown.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default DeviceTypeDropdown;
