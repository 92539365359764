import { get } from "lodash";
import BaseService from "@client.services/baseService";

export default class UserService extends BaseService {
  async login(emailAddress, password) {
    const result = await super.post("", {
      query: `mutation($emailAddress: String, $password: String){
        login(emailAddress: $emailAddress, password: $password){
          message
          token
          profile {
            firstName
            lastName
            Id
            emailAddress
            avatarUrl
            phoneNumber
          }
          account {
            entity
          }
        }
      }`,
      variables: {
        emailAddress,
        password,
      },
    });

    return result;
  }

  async profile() {
    const response = await super.post("", {
      query: `query {
        accountInformation {
          username
          phoneNumber
          name
          lastName
          firstName
          entity
          emailAddress
          avatarUrl
          accountName
          accountId
          Id
        }
      }`,
    });
    const result = get(response, "data.accountInformation", null);
    if (!result) {
      // it will be processed in reducer.
      return response;
    } else {
      const { entity, accountName, ...profile } = result;
      return {
        data: {
          profile,
          account: {
            entity: parseInt(entity),
            accountName,
          },
        },
      };
    }
  }

  async getAll(
    { limit, offset, startDate, endDate, search, searchOn },
    accountId
  ) {
    return super.post("", {
      query: `query($accountId: String, $pagination: PaginationInputType){
        users(accountId: $accountId, pagination: $pagination){
          count
          results{
            Id
            avatarUrl
            firstName
            lastName
            emailAddress
            role
            createdAt
            lastLoginTime
            isActive
            phoneNumber
          }
        }
      }`,
      variables: {
        accountId,
        pagination: {
          limit,
          offset,
          search,
          searchOn,
          startDate,
          endDate,
        },
      },
    });
  }


  async getById(userId) {
    return super.post("", {
      query: `query($userId: String){
	user(userId: $userId){
    Id
    accountId
    avatarUrl
    createdAt
    emailAddress
    firstName
    isActive
    jobTitle
    lastLoginTime
    lastName
    password
    phoneNumber
    role
    tfaLevelOne
    tfaLevelTwo
    updatedAt
	}
}`,
      variables: {
        userId,
      },
    });
  }

  async forgotPassword(emailAddress) {
    return super.post(`forgotPassword`, {
      emailAddress,
    });
  }

  async logout() {
    return super.post(`logout`);
  }

  async delete(userId) {
    return super.post("", {
      query: `mutation($id: String){
        deleteUser(id: $id)
      }`,
      variables: {
        id: userId,
      },
    });
  }

  async updateUser(model,fields) {
    console.log("model,fields", model,fields);
    return super.post("", {
      query: `mutation UpdateUser($id: String!, $fields: UserRequiredInputType!) {
        updateUser(id: $id, fields: $fields) {
          message
          user {
            Id
            accountId
            avatarUrl
            createdAt
            emailAddress
            firstName
            isActive
            jobTitle
            lastLoginTime
            lastName
            password
            phoneNumber
            role
            tfaLevelOne
            tfaLevelTwo
            updatedAt
          }
        }
      }`,
      variables: {
        id: model, // Use model.Id directly as required by your query
        fields: {
          avatarUrl: fields.avatarUrl || "",
          emailAddress: fields.emailAddress || "",
          firstName: fields.firstName || "",
          jobTitle: fields.jobTitle || "",
          lastName: fields.lastName || "",
          password: fields.password || "",
          phoneNumber: fields.phoneNumber || "",
          role: fields.role || null, // Default to null for role if undefined
        },
      },
    });
  }
  
  async create(user) {
    return super.post("", {
      query: `mutation($fields: UserRequiredInputType){
        createUser(fields: $fields){
          message
          user{
            Id
            emailAddress
            password
            phoneNumber
            firstName
            lastName
            lastLoginTime
            avatarUrl
            role
            accountId
          }
        }
      }`,
      variables: {
        fields: {
          avatarUrl: user.avatarUrl || "",
          emailAddress: user.emailAddress,
          firstName: user.firstName,
          lastName: user.lastName,
          password: user.password,
          phoneNumber: user.phoneNumber,
          role: user.role,
        },
      },
    });
  }

  async create2(user) {
    return super.post("", {
      query: `mutation CreateUser($fields: UserInputType!) {
        createUser(fields: $fields) {
          message
        }
      }`,
      variables: {
        fields: user.fields,
      },
    });
  }

  async save(user) {
    return super.post("", {
      query: `mutation UpdateUser($id: String, $user: UserInputType){
        updateUser(id: $id, user: $user){
          message
        }
      }`,
      variables: {
        id: user.lookupId,
        user: user.fields,
      },
    });
  }

  async changePassword(userId, password) {
    return super.post("", {
      query: `mutation UpdateUser($id: String, $fields: UserInputType){
        updateUser(id: $id, fields: $fields){
          message
        }
      }`,
      variables: {
        id: userId,
        fields: {
          password: password,
        },
      },
    });
  }
}
