/* eslint-disable no-unused-vars */
import React, { useCallback, forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Input from "@client.core.components/Input";
import { useTranslation } from "react-i18next";
import { validateModel } from "@client.utils/form";
import ServiceProvider from "@client.services/provider";
import "./VpnManagement.scss";
import VpnServerModel from "@client.models/vpnServer";
import Password from "@client.components/Password";
import VpnStatusDropdown from "./VpnStatusDropdown";

const VpnManagement = forwardRef(({ device }, ref) => {
  const { t } = useTranslation();

  const [model, setModel] = useState(new VpnServerModel());
  const [vpnList, setVpnList] = useState([]);
  const [accountList, setAccountList] = useState([]);

  useEffect(() => {
    Promise.all([
      ServiceProvider.Advertiser.getAll(),
      ServiceProvider.Vpn.getAll(),
    ]).then((result) => {
      setAccountList(result[0].data.accounts.results);
      setVpnList(result[1].data.fetchVpnServers);
    });
  }, []);

  useEffect(() => {
    setModel(
      new VpnServerModel(
        { ...device },
        {
          serverName: t("pages.VpnManagement.validation.serverName"),
          location: t("pages.VpnManagement.validation.location"),
          ipAddress: t("pages.VpnManagement.validation.ipAddress"),
          sshPort: t("pages.VpnManagement.validation.sshPort"),
          status: t("pages.VpnManagement.validation.status"),
          userName: t("pages.VpnManagement.validation.userName"),
          password: t("pages.VpnManagement.validation.password"),
        }
      )
    );
  }, [t, device]);

  React.useImperativeHandle(ref, () => ({
    managementItem: model,
    validate: () => {
      const isValid = validateModel(model, model.validationRules);
      return isValid;
    },
  }));

  const changeModel = useCallback(
    (v, field) => {
      setModel(
        new VpnServerModel({ ...model, [field]: v }, model.validationMsgs)
      );
    },
    [model]
  );

  return (
    <div className="vpn-management" ref={ref}>
      <Input
        value={model.serverName}
        onChange={(v) => changeModel(v, "serverName")}
        label={t("pages.VpnManagement.columns.name")}
      />
      <Input
        value={model.location}
        onChange={(v) => changeModel(v, "location")}
        label={t("pages.VpnManagement.columns.location")}
      />
      <Input
        value={model.ipAddress}
        onChange={(v) => changeModel(v, "ipAddress")}
        label={t("pages.VpnManagement.columns.ipAddress")}
      />
      <Input
        value={model.sshPort}
        type="number"
        onChange={(v) => changeModel(+v, "sshPort")}
        label={t("pages.VpnManagement.columns.sshPort")}
      />
      <VpnStatusDropdown
        value={model.status}
        onSelect={(v) => changeModel(v, "status")}
        label={t("pages.VpnManagement.columns.status")}
      />
      <Input
        value={model.userName}
        onChange={(v) => changeModel(v, "userName")}
        label={t("pages.VpnManagement.columns.userName")}
      />
      <Password
        value={model.password}
        onChange={(v) => changeModel(v, "password")}
        label={t("pages.VpnManagement.columns.password")}
      />
    </div>
  );
});

VpnManagement.displayName = "VpnManagement";

VpnManagement.defaultProps = {
  device: {},
};

VpnManagement.propTypes = {
  device: PropTypes.object,
};

export default VpnManagement;
