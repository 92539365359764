import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeSelectedAccount } from "../reducer";
import { useTranslation } from "react-i18next";
import DataTable from "@client.components/DataTable";
import moment from "moment";
import config from "@client.config";
import Card from "./Card";
import Button from "@client.core.components/Button";
import Input from "@client.core.components/Input";
import "./Wallet.scss";

const WalletTab = () => {
  const selectedAccount = useSelector(makeSelectedAccount);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([
    { id: 1, provider: "Google", cardNumber: "**** **** **** 1234" },
    { id: 2, provider: "Apple", cardNumber: "**** **** **** 5678" },
  ]);
  const [newCard, setNewCard] = useState({
    provider: "Google",
    cardNumber: "",
  });

  const [transactions, setTransactions] = useState([]);

  const addCard = () => {
    if (newCard.cardNumber) {
      setCards([...cards, { id: cards.length + 1, ...newCard }]);
      setNewCard({ provider: "Google", cardNumber: "" });
    }
  };

  const handleProviderChange = (provider) => {
    setNewCard({ ...newCard, provider });
  };

  useEffect(() => {
    if (selectedAccount.Id) {
      setLoading(true);
      setTimeout(() => {
        setTransactions([
          {
            amount: 123,
            createdAt: "2024-08-22T01:50:45.248Z",
          },
          {
            amount: 444,
            createdAt: "2024-09-12T06:43:45.248Z",
          },
          {
            amount: 532,
            createdAt: "2024-09-12T06:50:45.248Z",
          },
          {
            amount: 1000,
            createdAt: "2024-10-22T22:50:45.248Z",
          },
        ]);
        setLoading(false);
      }, 1000);
    }
  }, [selectedAccount.Id]);

  const COLUMNS = [
    {
      field: "amount",
      tooltipField: "amount",
      headerName: "Amount",
      cellRenderer: (params) => <div>${params.value}</div>,
    },
    {
      field: "createdAt",
      tooltipField: "createdAt",
      headerName: "Date",
      tooltipValueGetter: (params) =>
        moment(params.value).format(config.dateTimeSecondsFormat),
      cellRenderer: (params) =>
        moment(params.value).format(config.dateTimeSecondsFormat),
    },
  ];

  return (
    <div className="wallet-tab">
      <div className="customer-cards">
        <Card
          title={t("pages.Accounts.newPaymentMethod")}
          className="add-card-form"
        >
          <div className="provider-toggle">
            <button
              className={`toggle-btn ${
                newCard.provider === "Google" ? "active" : ""
              }`}
              onClick={() => handleProviderChange("Google")}
            >
              Google
            </button>
            <button
              className={`toggle-btn ${
                newCard.provider === "Apple" ? "active" : ""
              }`}
              onClick={() => handleProviderChange("Apple")}
            >
              Apple
            </button>
            <button
              className={`toggle-btn ${
                newCard.provider === "PayPal" ? "active" : ""
              }`}
              onClick={() => handleProviderChange("PayPal")}
            >
              PayPal
            </button>
          </div>
          <Input
            value={newCard.cardNumber}
            onChange={(e) => setNewCard({ ...newCard, cardNumber: e })}
            label={t("pages.Accounts.cardNumber")}
          />
          <Button
            icon="plus"
            text={t("pages.Accounts.addPaymentMethod")}
            onClick={addCard}
          />
        </Card>
        <div className="card-list">
          {cards.map((card) => (
            <div key={card.id} className="card">
              <span className="provider">{card.provider}</span>
              <span className="card-number">{card.cardNumber}</span>
            </div>
          ))}
        </div>
      </div>
      <DataTable columns={COLUMNS} data={transactions} isLoading={loading} />
    </div>
  );
};

WalletTab.propTypes = {};

export default WalletTab;
