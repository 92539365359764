import { IPlan } from "@client.features/billing/types";
import BaseService from "@client.services/baseService";
import { AllItemsResponse, ManagementResponse } from "types";

export default class PlanService extends BaseService {
  async getAll(): Promise<AllItemsResponse<IPlan>> {
    return super.post("", {
      query: `query{
        plans{
          count
          results{
            Id
            planName
            pricePerMonth
            features {
              name
            }
            isActive
          }
        }
      }`,
    });
  }

  async create(version: IPlan): Promise<ManagementResponse> {
    return super.post("", {
      query: `mutation($fields: PlanInputType){
        createPlan(fields: $fields){
          message
          plan{
            Id
            planName
            pricePerMonth
            isActive
            features {
              name
            }
          }
        }
      }`,
      variables: {
        fields: version,
      },
    });
  }

  async update(plan: IPlan): Promise<ManagementResponse> {
    const { Id: id, ...fields } = plan;
    return super.post("", {
      query: `mutation($id: String, $fields: PlanInputType){
        updatePlan(id: $id, fields: $fields){
          message
          plan{
            Id
            planName
            pricePerMonth
            features
          }
        }
      }`,
      variables: {
        id,
        fields,
      },
    });
  }

  async delete(id: string): Promise<ManagementResponse> {
    return super.post("", {
      query: `mutation($id: String){
        deletePlan(id: $id) {
          message
        }
      }`,
      variables: {
        id,
      },
    });
  }

  getAllFeatures(): string[] {
    return [
      "Geo Location Targeting",
      "Number of Impressions",
      "Dwelling Time",
      "Viewing Angles",
      "Foot Traffic Density",
      "Viewers Age Range",
      "Gender Demographics",
      "Time of Day Analysis",
      "Return Viewers",
      "Visitor Journeys",
      "Popular Areas",
      "Engagement Rate",
      "Controlling Campaign hours",
      "Gaze Tracking",
      "Economic Status",
      "Pedestrian Density",
      "Real-Time Traffic",
    ].sort();
  }
}
