import BaseService from "@client.services/baseService";

export default class AccountService extends BaseService {
  async getAll({
    limit,
    offset,
    startDate,
    endDate,
    search,
    searchOn,
  }: {
    limit: number;
    offset: number;
    startDate?: string;
    endDate?: string;
    search?: string;
    searchOn?: string;
  }) {
    return super.post("", {
      query: `query (
        $limit: Int
        $offset: Int
        $endDate: String
        $startDate: String
        $search: String
      ) {
        accounts(
            pagination: {endDate: $endDate, limit: $limit, offset: $offset, search: $search, startDate: $startDate}
        ) {
          count
          results
          {
            Id
            companyName
            entity
            companyRegistrationNumber
            companyVatNumber
            companyUrl
            companyLogoMediaId
          }
        }
      }`,
      variables: {
        pagination: {
          limit,
          offset,
          search,
          searchOn,
          startDate,
          endDate,
        },
      },
    });
  }
}