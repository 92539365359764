import ZoneType from "@client.enums/zoneType";
import BaseModel from "./base";
import { get } from "lodash";


function validatePolygon(polygon) {
  if (!Array.isArray(polygon)) return [[polygon]];
  return polygon.map((entry) =>
    Array.isArray(entry) ? entry : Array.isArray(entry.data) ? entry.data : []
  );
}


class ZoneModel extends BaseModel {
  constructor(zone = {}, msg = {}) {
    super(msg);

    this.Id = get(zone, "Id", "");
    this.zoneName = get(zone, "zoneName", "");
    this.category = get(zone, "category", ZoneType.Country);
    this.ppvPrice = parseFloat(get(zone, "ppvPrice", 0));
    this.primePrice = parseFloat(get(zone, "primePrice", 0));
    this.parentZoneId = get(zone, "parentZoneId", "");
    this.polygon = get(zone, "polygon", []);
    // this.polygon = validatePolygon(get(zone, "polygon", []));
    this.color = get(zone, "color", "");

    this.validationRules = {
      zoneName: {
        presence: { allowEmpty: false, message: msg.zoneName },
      },
      primePrice: {
        presence: { allowEmpty: false, message: msg.primePrice },
        numericality: { greaterThanOrEqualTo: 0, message: msg.primePrice },
      },
      ppvPrice: {
        presence: { allowEmpty: false, message: msg.ppvPrice },
        numericality: { greaterThanOrEqualTo: 0, message: msg.ppvPrice },
      },
      color: {
        presence: { allowEmpty: false, message: msg.color },
      },
    };
  }

  get fields() {
    const data = {
      color: this.color,
      zoneName: this.zoneName,
      category: this.category,
      ppvPrice: this.ppvPrice,
      primePrice: this.primePrice,
      polygon: JSON.stringify(this.polygon),
    };

    if (this.Id) {
      data.Id = this.Id;
    }
    if (this.parentZoneId) {
      data.parentZoneId = this.parentZoneId;
    }
    return data;
  }
}

export default ZoneModel;
