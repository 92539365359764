import React from "react";
import PropTypes from "prop-types";
import Dropdown from "@client.core.components/Dropdown";
import VPNStatuses from "@client.enums/vpnStatuses";
import { useTranslation } from "react-i18next";

const VpnStatusDropdown = ({ value, onSelect, label }) => {
  const { t } = useTranslation();
  // const profile = useSelector(makeProfile);// This is the profile of the logged in user.. the role of the logged in user is used to determine the roles that can be assigned to a user
  const actions = [
    {
      value: VPNStatuses.All,
      label: t("pages.Devices.status.all"),
    },
    {
      value: VPNStatuses.Online,
      label: t("pages.Devices.status.online"),
    },
    {
      value: VPNStatuses.Offline,
      label: t("pages.Devices.status.offline"),
    },
  ];

  return (
    <Dropdown
      items={actions}
      defaultValue={value}
      label={label}
      valueKey="value"
      labelKey="label"
      onPrepareValue={(v) => parseInt(v)}
      onSelect={onSelect}
    />
  );
};

VpnStatusDropdown.defaultProps = {
  value: null,
  label: null,
};

VpnStatusDropdown.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default VpnStatusDropdown;
