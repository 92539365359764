import React, { useState } from "react";
import {
  MdOutlineExpandLess,
  MdOutlineExpandMore,
  MdOutlineEdit,
  MdAdd,
  MdDeleteOutline,
} from "react-icons/md";
import ZoneType from "@client.enums/zoneType";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ZoneEntity from "./types";
import classNames from "classnames";
import { makeProfile } from "@client.pages/Login/reducer";

const ZoneItem = ({
  zone,
  onZoneManagement,
  onDelete,
  isEditable,
  selectedZone,
  onZoneSelect,
}) => {
  const profile = useSelector(makeProfile);

  const [expanded, setExpanded] = useState(false);
  const getType = () => {
    switch (zone.category) {
      case ZoneType.Country:
        return "Country";
      case ZoneType.Minicipality:
        return "Municipality";
      case ZoneType.City:
        return "City";
      case ZoneType.Custom:
        return "Custom";
    }
  };

  const renderExpandCollapse = () => {
    if (
      !isEditable &&
      [ZoneType.City, ZoneType.Custom].includes(zone.category)
    ) {
      return null;
    }

    return expanded ? (
      <MdOutlineExpandLess />
    ) : (
      <MdOutlineExpandMore className="expand" />
    );
  };
  return (
    <li>
      <div
        onClick={() => {
          const expandedVal = !expanded;
          setExpanded(expandedVal);
          onZoneSelect(expandedVal ? zone : null);
        }}
        style={{ cursor: "pointer" }}
        className={classNames("tree-leaf", {
          selected: selectedZone && zone.Id === selectedZone.Id,
        })}
      >
        <div className="tree-leaf-name">
          {renderExpandCollapse()}
          {zone.zoneName}
          <span className="zone-type">({getType()})</span>
        </div>
      </div>
      {expanded && (
        <div>
          {isEditable && (
            <div className="tree-leaf-data">
              <div className="action-icons">
                {![ZoneType.City, ZoneType.Custom].includes(zone.category) && (
                  <MdAdd
                    onClick={(e) => {
                      e.stopPropagation();
                      onZoneManagement({
                        parentZoneId: zone.Id,
                        category: zone.category + 1,
                      });
                    }}
                    title="Add"
                  />
                )}
                <MdOutlineEdit
                  onClick={(e) => {
                    e.stopPropagation();
                    onZoneManagement(zone);
                  }}
                  title="Edit"
                />
                <MdDeleteOutline
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(zone.Id);
                  }}
                  title="Delete"
                />
              </div>
              <div>PPV Rate: {`${profile.currency} ${zone.ppvPrice}`}</div>
              <div>Prime Rate: {`${profile.currency} ${zone.primePrice}`}</div>
            </div>
          )}
          {zone.items && (
            <ul>
              {zone.items.map((item) => (
                <ZoneItem
                  key={item.Id}
                  zone={item}
                  onZoneManagement={onZoneManagement}
                  onDelete={onDelete}
                  isEditable={isEditable}
                  selectedZone={selectedZone}
                  onZoneSelect={onZoneSelect}
                />
              ))}
            </ul>
          )}
        </div>
      )}
    </li>
  );
};

ZoneItem.propTypes = {
  zone: ZoneEntity,
  onZoneManagement: PropTypes.func,
  onDelete: PropTypes.func,
  isEditable: PropTypes.bool,
  selectedZone: PropTypes.object,
  onZoneSelect: PropTypes.func,
};

export default ZoneItem;
