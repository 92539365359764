import config from "@client.config";
import BaseService from "@client.services/baseService";
import ServiceProvider from "./provider";
import LocalStorageKeys from "@client.enums/localStorageKeys";

export default class AdvertiserService extends BaseService {
  constructor() {
    super("advertiser", true);

    // TODO remove later on.
    this.advertisers = [
      {
        id: "1",
        firstName: "Mc Donald's",
        lastName: "",
        activeCampaigns: 1,
        adPlays: 10,
        adViews: 20,
        budgetSpent: 300,
      },
      {
        id: "2",
        firstName: "Cafe Cafe",
        lastName: "",
        activeCampaigns: 2,
        adPlays: 33,
        adViews: 64,
        budgetSpent: 153,
      },
      {
        id: "3",
        firstName: "Aroma",
        lastName: "",
        activeCampaigns: 2,
        adPlays: 22,
        adViews: 333,
        budgetSpent: 324,
      },
    ];
  }

  async getOverview() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          this.advertisers.reduce(
            (accumulator, currentValue) => {
              accumulator.activeCampaigns += currentValue.activeCampaigns;
              accumulator.adPlays += currentValue.adPlays;
              accumulator.adViews += currentValue.adViews;
              accumulator.budgetSpent += currentValue.budgetSpent;

              return accumulator;
            },
            {
              activeCampaigns: 0,
              adPlays: 0,
              adViews: 0,
              budgetSpent: 0,
            }
          )
        );
      }, 500);
    });
  }

  async getAll({
    limit = 10,
    offset = 0,
    startDate,
    endDate,
    search,
    searchOn,
  } = {}) {
    return super.post("", {
      query: `query($pagination: PaginationInputType) {
        accounts(pagination: $pagination) {
          count
          results
          {
            Id
            companyName
            entity
            companyRegistrationNumber
            companyVatNumber
            companyUrl
            companyLogoMediaId
          }
        }
      }`,
      variables: {
        pagination: {
          limit,
          offset,
          search,
          searchOn,
          startDate,
          endDate,
        },
      },
    });
  }






  async create(advertiser) {
    return super.post("", {
      query: `mutation($account: AccountInputType, $user: UserInputType){
        createAccount(account: $account, user: $user){
          message
          user{
            Id
            emailAddress
            phoneNumber
            firstName
            lastName
            lastLoginTime
            avatarUrl
            role
            accountId
          }
        }
      }`,
      variables: {
        account: advertiser.fields.account,
        user: advertiser.fields.user,
      },
    });
  }









  // async create(advertiser) {
  //   return super.post("", {
  //     query: `mutation($account: AccountInputType, $user: UserInputType){
  //       createAccount(account: $account, user: $user){
  //         message
  //         user{
  //           Id
  //           emailAddress
  //           phoneNumber
  //           firstName
  //           lastName
  //           lastLoginTime
  //           avatarUrl
  //           role
  //           accountId
  //         }
  //       }
  //     }`,
  //     variables: {
  //       // fields: advertiser.fields,
  //       account: advertiser.fields.account,
  //       user: advertiser.fields.user,
  //     },
  //   });
  // }

  // async create(advertiser) {
  //   return super.post("", {
  //     query: `mutation CreateUser($fields: UserInputType!) {
  //       createUser(fields: $fields) {
  //         message
  //       }
  //     }`,
  //     variables: {
  //       fields: advertiser.fields,
  //     },
  //   });
  // }

  async getById(accountId) {
    return super.post("", {
      query: `query ($accountId: String) {
        account(accountId: $accountId) {
          Id
          accountName
          companyNumber
          createdAt
          description
          vatNumber
          updatedAt
          parentAccountId
          logoUrl
          isActive
          entity
        }
      }`,
      variables: {
        accountId,
      },
    });
  }
  
  async uploadAsset(asset) {
    const formData = new FormData();
    formData.append("file", asset.file);
    // console.log("assettttttttttttttttttttttttttttttttttttttttt", asset);
    // Add `kgn_account_id` directly to formData as per new approach
    // if (asset.kgn_account_id) {
      formData.append("kgn_account_id", asset.kgn_account_id);
      // formData.append("kgn_account_id", "6707ad0cfb35e5d9ce5f5e29");
    // }

    const token = ServiceProvider.LocalStorage.getItem(LocalStorageKeys.Token);
    const authHeader = token ? { Authorization: `KGN ${token}` } : {};

    return super.upload(config.uploadUrl, formData, true, authHeader);
  }



  async getAllAssets(accountId) {
    return super.post("", {
      query: `query($accountId: String) {
        medias(accountId: $accountId) {
          count
          results {
            Id
            createdAt
            accountId
            fileExtension
            externalId
            fileSize
            isActive
            updatedAt
            userId
            mimeType
          }
        }
      }`,
      variables: {
        accountId, // Correctly passing accountId instead of userId
      },
    });
  }
  
  
  // async getAllAssets(userId) {
  //   return super.post("", {
  //     query: `query($userId: String) {
  //       fetchUserFiles(id: $userId) {
  //         lookupId
  //         locationPath
  //       }
  //     }`,
  //     variables: {
  //       userId: userId,
  //     },
  //   });
  // }
}
