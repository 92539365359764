import React, { useEffect, useState } from "react";
import HierarchyNode from "@client.components/Hierarchy/HierarchyNode";
import AccountTabs, { AccountTabsEnum } from "./AccountTabs";
import { useTranslation } from "react-i18next";
import NoDataPlaceholder from "@client.core.components/NoDataPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAccountsAsync,
  makeAccountHierarchy,
  makeSelectedAccount,
  setSelectedAccount,
} from "./reducer";
import { createAdvertiserAsync } from "../../pages/Advertiser/reducer";
import Loader from "@client.core.components/Loader";
import DetailsTab from "./tabs/Details";
import "./Accounts.scss";
import DevicesTab from "./tabs/Devices";
import UsersTab from "./tabs/Users";
import WalletTab from "./tabs/Wallet";
import CreateAdvertiser from "./CreateAdvertiser";
import Button from "@client.core.components/Button";
import ServiceProvider from "@client.services/provider";
import { MODAL_EVENT } from "@client.components/Modal";
import { getGraphqlResponseError } from "@client.utils/error";
import AdvertisersList from "../../components/AdvertisersList/AdvertisersList";
import CircleIcon from "@client.components/CircleIcon";

const AccountsPage = () => {
  const [selectedTab, setSelectedTab] = useState(AccountTabsEnum.Details);

  const accounts = useSelector(makeAccountHierarchy);
  // console.log("From Accounts.js: ", accounts);
  const selectedAccount = useSelector(makeSelectedAccount);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleAccountSelect = (acc) => {
    dispatch(setSelectedAccount(acc));
  };

  useEffect(() => {
    dispatch(getAllAccountsAsync());
  }, [dispatch]);

  const renderContent = () => {
    if (!selectedAccount) {
      return <NoDataPlaceholder description={t("pages.Accounts.noData")} />;
    }
    switch (selectedTab) {
    case AccountTabsEnum.Details:
      // debugger;
      return <DetailsTab />;
    case AccountTabsEnum.Wallet:
      return <WalletTab />;
    case AccountTabsEnum.Devices:
      return <DevicesTab />;
    case AccountTabsEnum.Users:
      return <UsersTab />;
    }
  };
  
  if (accounts.loading) {
    return (
      <div className="accounts page-loading">
        <Loader loading={true} />
      </div>
    );
  }

  const createTxt = t("components.NavigationBar.createAdvertiser");

  return (
    <div className="accounts page">
      
      <div className="page-title">
        <span className="title">{t("pages.AccountsPage.title")}</span>
        {  console.log("From Accounts.jssssssss: ", selectedAccount)}
        {selectedAccount && <AccountTabs onSelect={setSelectedTab} />}
      </div>
      
      <div className="accounts-content">
        <div className="accounts-hierarchy">
          <Button
            icon="plus"
            className="create-advertiser"
            text={createTxt}
            onClick={() => {
              ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
                show: true,
                withBodyRef: true,
                title: createTxt,
                component: <CreateAdvertiser />,
                buttonSettings: {
                  text: t("components.Advertisers.create"),
                  onClick: async (component) => {
                  // console.log("componentttttttttttt", component);
                    const isValid = component.validate();

                    return isValid
                      ? dispatch(
                        createAdvertiserAsync(component.managementItem)
                      ).then((resp) => {
                        const error = getGraphqlResponseError(resp);
                        if (!error) {
                          ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
                            show: false,
                          });
                        }
                      })
                      : Promise.resolve();
                  },
                },
              });
            }}
          />
          <div className="accounts-list">
            {accounts.hierarchy.map((account, index) => {
              return (
                <div
                  key={account.Id}
                  className={`account-item ${selectedAccount?.Id === account.Id ? 'account-item--selected' : ''}`}
                  onClick={() => handleAccountSelect(account)}
                >
                  <CircleIcon name={account.companyName} index={index} />
                  {account.companyName}
                </div>
              );
            })}
          </div >
        </div>
        <div className="tab-content">{renderContent()}</div>
      </div>
    </div>
  );
};

export default AccountsPage;
