import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./CampaignProgressBar.scss";


const CampaignProgressBar = ({ startDate, endDate }) => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (startDate && endDate) {
      const now = new Date();
      const start = new Date(startDate);
      const end = new Date(endDate);

      // Calculate total duration and time elapsed
      const totalDuration = end - start;
      const elapsed = now - start;

      // Calculate percentage
      const percentage = Math.min(100, Math.max(0, (elapsed / totalDuration) * 100));
      setProgress(percentage);
    }
  }, [startDate, endDate]);

  return (
    <div className="campaign-progress-container">
      <div className="progress-label">{t("pages.Campaign.Overview.component.progressbar")}</div>
      <div className="progress-percentage">{Math.floor(progress)}%</div>
      <div className="progress-bar">
        <div className="progress-fill" style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );
};

CampaignProgressBar.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default CampaignProgressBar;
