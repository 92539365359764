import React from "react";
import PropTypes from "prop-types";

const Kpi = ({ title, value }) => {
  return (
    <div className="kpi">
      <div className="kpi-title">{title}</div>
      <div className="kpi-value">{value}</div>
    </div>
  );
};

Kpi.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
};

export default Kpi;
