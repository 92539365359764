import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import Loader from "@client.core.components/Loader";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.min.css";
import variables from "./DataTable.scss";
import DataTablePagination from "@client.components/Pagination/Pagination";
import "./DataTable.scss";

export const DataTable = forwardRef(
  ({ columns, data, isLoading, onSelectionChanged, pagination }, ref) => {
    const headerHeight = parseInt(variables.cellHeight.replace("px", ""));

    return (
      <div className="data-table ag-theme-alpine-light">
        {isLoading && (
          <div className="data-table-loader">
            <Loader loading={true} />
          </div>
        )}
        <AgGridReact
          ref={ref}
          rowData={data}
          columnDefs={columns.map((col) => ({
            ...col,
          }))}
          gridOptions={{
            onCellClicked: onSelectionChanged,
            headerHeight: headerHeight,
            rowSelection: "single",
            getRowHeight: () => {
              return headerHeight;
            },
          }}
          defaultColDef={{
            sortable: true,
            flex: 1,
            autoSizeColumns: true,
            headerClass: "data-table-header",
            cellClass: "data-table-cell",
          }}
        />
        {pagination && <DataTablePagination {...pagination} />}
      </div>
    );
  }
);

DataTable.displayName = "DataTable";

DataTable.propTypes = {
  pagination: PropTypes.shape({
    itemsPerPage: PropTypes.number,
    onChange: PropTypes.func,
    total: PropTypes.number,
  }),
  onSelectionChanged: PropTypes.func,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      sort: PropTypes.string,
    })
  ).isRequired,
};
