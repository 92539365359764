import { TOAST_EVENT } from "@client.core.components/Toast";
import ServiceProvider from "@client.services/provider";
import { getGraphqlResponseError } from "@client.utils/error";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const useToast = () => {
  const { t } = useTranslation();

  const showToastAfterRequest = useCallback(
    (resp, message) => {
      return new Promise((resolve) => {
        const error = getGraphqlResponseError(resp);
        if (!error) {
          ServiceProvider.EventEmitter.emit(TOAST_EVENT, [
            {
              type: "success",
              msg: t(message),
            },
          ]);

          resolve();
        }
      });
    },
    [t]
  );

  const showToastWarning = useCallback(
    (message, messageDict) => {
      ServiceProvider.EventEmitter.emit(TOAST_EVENT, [
        {
          type: "warning",
          msg: t(message, messageDict),
        },
      ]);
    },
    [t]
  );

  return { showToastAfterRequest, showToastWarning };
};

export default useToast;
