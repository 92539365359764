const enum Pages {
  DataAnalyzerOld = "/dataAnalyzerOld",
  DataAnalyzerBlob = "/dataAnalyzerBlob",
  DataAnalyzerDemo = "/dataAnalyzerDemo",
  DataAnalyzer = "/dataAnalyzer",
  VpnManagement = "vpn/management",
  CampaignApprovals = "campaign/approvals",
  RegisterUser = "/user/register",
  InviteUser = "/user/invite",
  Login = "/login",
  Logout = "/logout",
  ProfileGeneral = "/profile",
  ProfileGeneralEditUser = "/editUser",
  ProfileBilling = "/profile/billing",
  ProfileNotifications = "/profile/notifications",
  ProfileChangePassword = "/profile/changePassword",
  ProfilePayment = "/profile/payment",
  Dashboard = "/dashboard",
  CreateCampaign = "/createCampaign",
  Devices = "/devices",
  Users = "/users",
  Campaigns = "/campaigns",
  Zones = "/zones",
  ForgotPassword = "/forgotPassword",
  FlickrDemo = "/flickrDemo",
  Advertiser = "/advertiser",
  Campaign = "/campaign",
  MediaLibrary = "/mediaLibrary",
  ActiveCampaigns = "/activeCampaigns",
  PausedCampaigns = "/pausedCampaigns",
  DraftCampaigns = "/draftCampaigns",
  Reports = "/reports",
  VpnServer = "/vpnServer",
  Accounts = "/accounts",
  PermissionsPage = "/PermissionsPage",
  UserLogs = "/userLogs",
  SystemLogs = "/systemLogs",
  SoftwareVersions = "/analytics-tools/software-versions",
  Plans = "/billing/plans",
}

export default Pages;
