import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const AdvertiserTabsEnum = Object.freeze({
  Statistics: 0,
  Insights: 1,
});

const CampaignInnerTabs = ({ value, onSelect }) => {
  const { t } = useTranslation();

  const tabs = [
    {
      id: AdvertiserTabsEnum.Statistics,
      name: t("pages.campaings.tabs.overview"),
      icon: "stats-icon",
    },
    {
      id: AdvertiserTabsEnum.Insights,
      name: t("pages.campaings.tabs.insights"),
      icon: "insights-icon",
    },
  ];

  return (
    <div className="campaign-tabs">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`tab-button ${value === tab.id ? "active" : ""}`}
          onClick={() => onSelect(tab.id)}
        >
          {tab.name}
        </button>
      ))}
    </div>
  );
};

CampaignInnerTabs.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func,
};

export { AdvertiserTabsEnum };
export default CampaignInnerTabs;








// working code


// import React from "react";
// import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";
// import Tabs from "@client.components/Tabs";

// const AdvertiserTabsEnum = Object.freeze({
//   Statistics: 0,
//   Insights: 1,
// });

// const CampaignInnerTabs = ({ value, onSelect }) => {
//   const { t } = useTranslation();

//   const tabs = [
//     {
//       id: AdvertiserTabsEnum.Statistics,
//       name: t("pages.campaings.tabs.statistics"),
//       icon: "stats-icon", // Customize icon classes if needed
//     },
//     {
//       id: AdvertiserTabsEnum.Insights,
//       name: t("pages.campaings.tabs.insights"),
//       icon: "insights-icon", // Customize icon classes if needed
//     },
//   ];

//   return <Tabs tabs={tabs} value={value} onSelect={onSelect} />;
// };

// CampaignInnerTabs.propTypes = {
//   value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//   onSelect: PropTypes.func,
// };

// export { AdvertiserTabsEnum };
// export default CampaignInnerTabs;









// import React from "react";
// import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";
// import Tabs from "@client.components/Tabs";

// const AdvertiserTabsEnum = Object.freeze({
//   Statistics: 0,
//   Insights: 1,
// });

// const CampaignInnerTabs = ({ value, onSelect }) => {
//   const { t } = useTranslation();

//   const tabs = [
//     {
//       id: AdvertiserTabsEnum.Statistics,
//       name: t("pages.campaings.tabs.statistics"),
//       icon: "speaker",
//     },
//     {
//       id: AdvertiserTabsEnum.Insights,
//       name: t("pages.campaings.tabs.insights"),
//       icon: "draft",
//     },
//   ];

//   return <Tabs tabs={tabs} value={value} onSelect={onSelect} />;
// };

// CampaignInnerTabs.propTypes = {
//   value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//   onSelect: PropTypes.func,
// };

// export { AdvertiserTabsEnum };
// export default CampaignInnerTabs;
