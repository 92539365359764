import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { makeProfile } from "@client.pages/Login/reducer";
import moment from "moment";
import config from "@client.config";
import classNames from "classnames";
import CampaignStatuses from "@client.enums/campaignStatuses";
import CampaignActions from "./CampaignActions";
import MediaFile from "@client.components/MediaFile";
import "./CampaignOverview.scss";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";

// export const CampaignOverview = ({ campaign, currentFilters }) => {
  export const CampaignOverview = ({ campaign, currentFilters, onStatusChange }) => {
  // console.log("From CampaignOverview.js: ", campaign);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const profile = useSelector(makeProfile);

  const currentDate = new Date();
  const startDate = new Date(campaign.startDate);
  const endDate = new Date(campaign.endDate);

  const views = campaign.totalViews || 0;
  const spent = campaign.totalSpent || 0;
  const pricePerView = views === 0 || spent === 0 ? 0 : spent / views;

  const isStartDateFuture = startDate > currentDate;
  const isEndDatePast = endDate < currentDate;
  
  const determineStatus = () => {
    // if (campaign.isDraft) {
    //   return { term: "pages.Campaign.status.draft", class: "draft" };
    // }
    if (campaign.isDraft) {
      return { term: "pages.Campaign.status.draft", class: "draft" };
    }

    if (isEndDatePast) {
      return { term: "pages.Campaign.status.ended", class: "ended" };
    }
  
    switch (campaign.status) {
      case CampaignStatuses.Paused:
        return { term: "pages.Campaign.status.paused", class: "paused" };
      case CampaignStatuses.Archive:
        return { term: "pages.Campaign.status.archived", class: "archived" };
      case CampaignStatuses.Queued:
        return { term: "pages.Campaign.status.queued", class: "queued" };
      case CampaignStatuses.PendingApproval:
        return { term: "pages.Campaign.status.pending", class: "pending" };
      case CampaignStatuses.Ended:
        return { term: "pages.Campaign.status.ended", class: "ended" };
      default:
        if (isStartDateFuture) return { term: "pages.Campaign.status.queued", class: "queued" };
        return { term: "pages.Campaign.status.live", class: "live" };
    }
  };

  console.log(campaign)

  const status = determineStatus();
  
  return (
    <div className="campaign-overview">
      <div className="preview"
        onClick={() => {
          navigate(
            campaign.isDraft 
            ? `${Pages.CreateCampaign}/${campaign.Id}`
            : `${Pages.Campaign}/${campaign.Id}`
          );
        }}
      >
        <MediaFile
          src={campaign.mediaId || ""}
          showThumbnail
          isNotFound={!campaign.mediaId}
        />
      </div>
      <div className="status">
        <span className={classNames("badge", status.class)}>
          {t(status.term)}
        </span>
        <span className="time">
          <span>
          {isEndDatePast
            ? t("components.CampaignOverview.ended")
            : t("components.CampaignOverview.ending")}
          </span>
          <span>{moment(campaign.endDate).format(config.dateTimeFormat)}</span>
        </span>
      </div>
      <div className="name">
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
      <span>{campaign.campaignName}</span>
      <CampaignActions
        campaignId={campaign.Id}
        campaign={campaign}
        currentFilters={currentFilters}
        onStatusChange={onStatusChange} // Pass callback
      />
      </div>
      </div>
      <div className="statistic">
        <div className="section-payment">
          <div className="price">{views.toLocaleString()}</div>
          <div className="description">
            {t("components.CampaignOverview.plays")}
          </div>
        </div>
        <div className="section-payment">
          <div className="price">
            {profile.currency} {pricePerView.toLocaleString()}
          </div>
          <div className="description">
            {t("components.CampaignOverview.perPlay")}
          </div>
        </div>
        <div className="section-payment no-border">
          <div className="price">
            {profile.currency} {spent.toLocaleString()}
          </div>
          <div className="description">
            {t("components.CampaignOverview.spent")}
          </div>
        </div>
      </div>
    </div>
  );
};

CampaignOverview.propTypes = {
  
  campaign: PropTypes.shape({
    Id: PropTypes.string.isRequired,
    campaignName: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    mediaId: PropTypes.string,
    totalViews: PropTypes.number,
    totalSpent: PropTypes.number,
    currentFilters: PropTypes.array,
    isDraft: PropTypes.boolean,
  }).isRequired,
  currentFilters: PropTypes.array,
  onStatusChange: PropTypes.func,
};
