import React from "react";
import PropTypes from "prop-types";
import Loader from "@client.core.components/Loader";
import SvgIcon from "@client.core.components/SvgIcon";
import "./UnauthorizedPageCard.scss";

const UnauthorizedPageCard = ({ children, isLoading }) => {
  return (
    <div className="unauthorized-page-card">
      <Loader loading={isLoading} />
      {!isLoading && (
        <div className="unauthorized-page-container">
          <SvgIcon name="logo" className="logo" />
          <div className="unauthorized-page-form">{children}</div>
          <div className="footer" />
        </div>
      )}
    </div>
  );
};

UnauthorizedPageCard.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};

export default UnauthorizedPageCard;
