import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import Kpi from "./Kpi";

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#AF19FF",
  "#FF6392",
  "#FF3D1F",
  "#9FE6B9",
  "#FFBF00",
  "#6A0572",
];

const Statistic = ({ data }) => {
  console.log("data ---->", data);
  // console.log("data ---->", data);
  const { t } = useTranslation();

  if (!data) {
    return <div>{t("pages.DataAnalyzer.noDataAvailable")}</div>;
  }

  // Extracting data
  const {
    ageGraph = [],
    totalImpressions = 0,
    totalViews = 0,
    peopleDetected = data.totalPeopleDetected    ,
  } = data;

  const peopleMale = ageGraph.reduce((sum, { male }) => sum + male, 0);
  const peopleFemale = ageGraph.reduce((sum, { female }) => sum + female, 0);
  const totalViewers = peopleMale + peopleFemale || 1; // Prevent division by zero

  // Gender Pie Chart Data
  const genderPieChartData = [
    {
      name: t("pages.DataAnalyzer.gender.male"),
      qty: peopleMale,
      value: parseFloat(((peopleMale / totalViewers) * 100).toFixed(2)),
    },
    {
      name: t("pages.DataAnalyzer.gender.female"),
      qty: peopleFemale,
      value: parseFloat(((peopleFemale / totalViewers) * 100).toFixed(2)),
    },
  ];

  // Age Range Pie Chart Data
  const ageRangePieData = ageGraph.map(({ age, male, female }) => {
    const qty = (male || 0) + (female || 0);
    return {
      name: `Age ${age}`,
      qty,
      value: parseFloat(((qty / peopleDetected) * 100).toFixed(2)) || 0,
    };
  });

  // Tooltip for Pie Charts
  const pieChartTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, qty, value } = payload[0].payload;
      return (
        <div className="pie-chart-tooltip">
          <p><b>{name}</b></p>
          <p>{t("pages.DataAnalyzer.quantity")}: {qty}</p>
          <p>{t("pages.DataAnalyzer.percentage")}: {value}%</p>
        </div>
      );
    }
    return null;
  };

  // Helper to get age range
  const getAgeRange = (isMale) => {
    const ages = ageGraph.reduce((acc, { age, male, female }) => {
      if (isMale && male > 0) acc.push(age);
      if (!isMale && female > 0) acc.push(age);
      return acc;
    }, []);
    if (ages.length === 0) return null; // Return `null` to avoid prop type error
    return `${Math.min(...ages)} - ${Math.max(...ages)}`;
  };

  return (
    <div className="data-analyzer-statistic">
      {/* KPIs */}
      <div className="kpis">
        <Kpi title={t("pages.DataAnalyzer.peopleArea")} value={peopleDetected} />
        <Kpi title={t("pages.DataAnalyzer.viewers")} value={totalViews} />
        <Kpi title={t("pages.DataAnalyzer.interact")} value={totalImpressions} />
        <Kpi
          title={t("pages.DataAnalyzer.gender.male.ageRange")}
          value={getAgeRange(true) || 0}
        />
        <Kpi
          title={t("pages.DataAnalyzer.gender.female.ageRange")}
          value={getAgeRange(false) || 0}
        />
      </div>

      {/* Charts */}
      <div className="data-analyzer-chart">
        <div className="chart-title">{t("pages.DataAnalyzer.demographics")}</div>
        <div className="demographics-charts">
          {/* Gender Pie Chart */}
          <ResponsiveContainer width="50%" height={400}>
            <PieChart>
              <Pie
                data={genderPieChartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={150}
                fill="#8884d8"
                label={(entry) => entry.name}
              >
                {genderPieChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip content={pieChartTooltip} />
            </PieChart>
          </ResponsiveContainer>

          {/* Age Range Pie Chart */}
          <ResponsiveContainer width="50%" height={400}>
            <PieChart>
              <Pie
                data={ageRangePieData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={150}
                fill="#8884d8"
                label={(entry) => entry.name}
              >
                {ageRangePieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip content={pieChartTooltip} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

Statistic.propTypes = {
  data: PropTypes.shape({
    ageGraph: PropTypes.arrayOf(
      PropTypes.shape({
        age: PropTypes.number.isRequired,
        male: PropTypes.number,
        female: PropTypes.number,
      })
    ),
    totalImpressions: PropTypes.number,
    totalViews: PropTypes.number,
    peopleDetected: PropTypes.number,
    totalPeopleDetected: PropTypes.number,
  }),
};

export default Statistic;
















// import React from "react";
// import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";
// import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
// import Kpi from "./Kpi";

// const COLORS = [
//   "#0088FE",
//   "#00C49F",
//   "#FFBB28",
//   "#FF8042",
//   "#AF19FF",
//   "#FF6392",
//   "#FF3D1F",
//   "#9FE6B9",
//   "#FFBF00",
//   "#6A0572",
// ];

// const Statistic = ({ data }) => {
//   console.log("data ---->", data);
//   const { t } = useTranslation();

//   if (!data) {
//     return <div>{t("pages.DataAnalyzer.noDataAvailable")}</div>;
//   }

//   // Extracting data
//   const {
//     ageGraph = [],
//     peopleDetected = 0,
//     peopleFemale = 0,
//     peopleMale = 0,
//     impressions = 0,
//     views = 0,
//   } = data;

//   const totalViewers = peopleFemale + peopleMale;
//   const totalDetected = peopleDetected > 0 ? peopleDetected : 1; // Prevent zero division

//   // Gender Pie Chart Data
//   const genderPieChartData = [
//     {
//       name: t("pages.DataAnalyzer.gender.male"),
//       qty: peopleMale,
//       value: parseFloat(((peopleMale / totalViewers) * 100).toFixed(2)) || 0,
//     },
//     {
//       name: t("pages.DataAnalyzer.gender.female"),
//       qty: peopleFemale,
//       value: parseFloat(((peopleFemale / totalViewers) * 100).toFixed(2)) || 0,
//     },
//   ];

//   // Age Range Pie Chart Data
//   const ageRangePieData = ageGraph.map(({ age, male, female }) => {
//     const qty = (male || 0) + (female || 0);
//     return {
//       name: `Age ${age}`,
//       qty,
//       value: parseFloat(((qty / totalDetected) * 100).toFixed(2)) || 0,
//     };
//   });

//   // Tooltip for Pie Charts
//   const pieChartTooltip = ({ active, payload }) => {
//     if (active && payload && payload.length) {
//       const { name, qty, value } = payload[0].payload;
//       return (
//         <div className="pie-chart-tooltip">
//           <p><b>{name}</b></p>
//           <p>{t("pages.DataAnalyzer.quantity")}: {qty}</p>
//           <p>{t("pages.DataAnalyzer.percentage")}: {value}%</p>
//         </div>
//       );
//     }
//     return null;
//   };

//   // Helper to get age range
//   const getAgeRange = (isMale) => {
//     const ages = ageGraph.reduce((acc, { age, male, female }) => {
//       if (isMale && male > 0) acc.push(age);
//       if (!isMale && female > 0) acc.push(age);
//       return acc;
//     }, []);
//     if (ages.length === 0) return "N/A";
//     return `${Math.min(...ages)} - ${Math.max(...ages)}`;
//   };

//   return (
//     <div className="data-analyzer-statistic">
//       {/* KPIs */}
//       <div className="kpis">
//         <Kpi title={t("pages.DataAnalyzer.peopleArea")} value={peopleDetected} />
//         <Kpi title={t("pages.DataAnalyzer.viewers")} value={views} />
//         <Kpi title={t("pages.DataAnalyzer.interact")} value={impressions} />
//         <Kpi
//           title={t("pages.DataAnalyzer.gender.male.ageRange")}
//           value={getAgeRange(true)}
//         />
//         <Kpi
//           title={t("pages.DataAnalyzer.gender.female.ageRange")}
//           value={getAgeRange(false)}
//         />
//       </div>

//       {/* Charts */}
//       <div className="data-analyzer-chart">
//         <div className="chart-title">{t("pages.DataAnalyzer.demographics")}</div>
//         <div className="demographics-charts">
//           {/* Gender Pie Chart */}
//           <ResponsiveContainer width="50%" height={400}>
//             <PieChart>
//               <Pie
//                 data={genderPieChartData}
//                 dataKey="value"
//                 nameKey="name"
//                 cx="50%"
//                 cy="50%"
//                 outerRadius={150}
//                 fill="#8884d8"
//                 label={(entry) => entry.name}
//               >
//                 {genderPieChartData.map((entry, index) => (
//                   <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                 ))}
//               </Pie>
//               <Tooltip content={pieChartTooltip} />
//             </PieChart>
//           </ResponsiveContainer>

//           {/* Age Range Pie Chart */}
//           <ResponsiveContainer width="50%" height={400}>
//             <PieChart>
//               <Pie
//                 data={ageRangePieData}
//                 dataKey="value"
//                 nameKey="name"
//                 cx="50%"
//                 cy="50%"
//                 outerRadius={150}
//                 fill="#8884d8"
//                 label={(entry) => entry.name}
//               >
//                 {ageRangePieData.map((entry, index) => (
//                   <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                 ))}
//               </Pie>
//               <Tooltip content={pieChartTooltip} />
//             </PieChart>
//           </ResponsiveContainer>
//         </div>
//       </div>
//     </div>
//   );
// };

// Statistic.propTypes = {
//   data: PropTypes.shape({
//     ageGraph: PropTypes.arrayOf(
//       PropTypes.shape({
//         age: PropTypes.number.isRequired,
//         male: PropTypes.number,
//         female: PropTypes.number,
//       })
//     ),
//     peopleDetected: PropTypes.number,
//     peopleFemale: PropTypes.number,
//     peopleMale: PropTypes.number,
//     views: PropTypes.number,
//     impressions: PropTypes.number,
//   }),
// };

// export default Statistic;











//One Graph


// import React from "react";
// import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";
// import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
// import Kpi from "./Kpi";

// const COLORS = [
//   "#0088FE",
//   "#00C49F",
//   "#FFBB28",
//   "#FF8042",
//   "#AF19FF",
//   "#FF6392",
//   "#FF3D1F",
//   "#9FE6B9",
//   "#FFBF00",
//   "#6A0572",
// ];

// const Statistic = ({ data }) => {
//   const { t } = useTranslation();

//   if (!data) {
//     return <div>{t("pages.DataAnalyzer.noDataAvailable")}</div>;
//   }

//   // Extract data
//   const {
//     ageGraph = [],
//     peopleDetected = 0,
//     peopleFemale = 0,
//     peopleMale = 0,
//     views = 0,
//     impressions = 0,
//   } = data;

//   // Validate totals to avoid division by zero
//   const totalPeople = peopleMale + peopleFemale;
//   const totalDetected = peopleDetected > 0 ? peopleDetected : 1; // Prevent zero division

//   // Gender Pie Chart Data
//   const genderPieChartData = [
//     {
//       name: t("pages.DataAnalyzer.gender.male"),
//       qty: peopleMale,
//       value: parseFloat(((peopleMale / totalPeople) * 100).toFixed(2)),
//     },
//     {
//       name: t("pages.DataAnalyzer.gender.female"),
//       qty: peopleFemale,
//       value: parseFloat(((peopleFemale / totalPeople) * 100).toFixed(2)),
//     },
//   ];

//   // Age Range Pie Chart Data
//   const ageRangePieData = ageGraph.map(({ age, male, female }) => {
//     const qty = (male || 0) + (female || 0);
//     return {
//       name: `Age ${age}`,
//       qty,
//       value: parseFloat(((qty / totalDetected) * 100).toFixed(2)),
//     };
//   });

//   // Tooltip for Pie Charts
//   const pieChartTooltip = ({ active, payload }) => {
//     if (active && payload && payload.length) {
//       const { name, qty, value } = payload[0].payload;
//       return (
//         <div className="pie-chart-tooltip">
//           <p><b>{name}</b></p>
//           <p>{t("pages.DataAnalyzer.quantity")}: {qty}</p>
//           <p>{t("pages.DataAnalyzer.percentage")}: {value}%</p>
//         </div>
//       );
//     }
//     return null;
//   };

//   // Helper to get age range
//   const getAgeRange = (isMale) => {
//     const ages = ageGraph.reduce((acc, { age, male, female }) => {
//       if (isMale && male > 0) acc.push(age);
//       if (!isMale && female > 0) acc.push(age);
//       return acc;
//     }, []);
//     if (ages.length === 0) return "N/A";
//     return `${Math.min(...ages)} - ${Math.max(...ages)}`;
//   };

//   return (
//     <div className="data-analyzer-statistic">
//       {/* KPIs */}
//       <div className="kpis">
//         <Kpi title={t("pages.DataAnalyzer.peopleArea")} value={peopleDetected} />
//         <Kpi title={t("pages.DataAnalyzer.viewers")} value={views} />
//         <Kpi title={t("pages.DataAnalyzer.interact")} value={impressions} />
//         <Kpi
//           title={t("pages.DataAnalyzer.gender.male.ageRange")}
//           value={getAgeRange(true)}
//         />
//         <Kpi
//           title={t("pages.DataAnalyzer.gender.female.ageRange")}
//           value={getAgeRange(false)}
//         />
//       </div>

//       {/* Charts */}
//       <div className="data-analyzer-chart">
//         <div className="chart-title">{t("pages.DataAnalyzer.demographics")}</div>
//         <div className="demographics-charts">
//           {/* Gender Pie Chart */}
//           <ResponsiveContainer width="50%" height={400}>
//             <PieChart>
//               <Pie
//                 data={genderPieChartData}
//                 dataKey="value"
//                 nameKey="name"
//                 cx="50%"
//                 cy="50%"
//                 outerRadius={150}
//                 fill="#8884d8"
//                 label={(entry) => entry.name}
//               >
//                 {genderPieChartData.map((entry, index) => (
//                   <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                 ))}
//               </Pie>
//               <Tooltip content={pieChartTooltip} />
//             </PieChart>
//           </ResponsiveContainer>

//           {/* Age Range Pie Chart */}
//           <ResponsiveContainer width="50%" height={400}>
//             <PieChart>
//               <Pie
//                 data={ageRangePieData}
//                 dataKey="value"
//                 nameKey="name"
//                 cx="50%"
//                 cy="50%"
//                 outerRadius={150}
//                 fill="#8884d8"
//                 label={(entry) => entry.name}
//               >
//                 {ageRangePieData.map((entry, index) => (
//                   <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                 ))}
//               </Pie>
//               <Tooltip content={pieChartTooltip} />
//             </PieChart>
//           </ResponsiveContainer>
//         </div>
//       </div>
//     </div>
//   );
// };

// Statistic.propTypes = {
//   data: PropTypes.shape({
//     ageGraph: PropTypes.arrayOf(
//       PropTypes.shape({
//         age: PropTypes.number.isRequired,
//         male: PropTypes.number,
//         female: PropTypes.number,
//       })
//     ),
//     peopleDetected: PropTypes.number,
//     peopleFemale: PropTypes.number,
//     peopleMale: PropTypes.number,
//     views: PropTypes.number,
//     impressions: PropTypes.number,
//   }),
// };

// export default Statistic;













// import React from "react";
// import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";
// import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
// import Kpi from "./Kpi";

// const COLORS = [
//   "#0088FE",
//   "#00C49F",
//   "#FFBB28",
//   "#FF8042",
//   "#AF19FF",
//   "#FF6392",
//   "#FF3D1F",
//   "#9FE6B9",
//   "#FFBF00",
//   "#6A0572",
// ];

// const Statistic = ({ data }) => {
//   console.log("data ---->", data);
//   const { t } = useTranslation();

//   if (!data) {
//     return <div>{t("pages.DataAnalyzer.noDataAvailable")}</div>;
//   }

//   // Extract data with proper validation
//   const {
//     ageGraph = [],
//     peopleDetected = 0,
//     peopleFemale = 0,
//     peopleMale = 0,
//     views = 0,
//     impressions = 0,
//   } = data;

//   // Calculate gender breakdown pie chart data
//   const totalViewers = peopleFemale + peopleMale;
//   const genderPieChartData = [
//     {
//       name: t("pages.DataAnalyzer.gender.male"),
//       qty: peopleMale,
//       value: parseFloat(((peopleMale / totalViewers) * 100).toFixed(2)) || 0,
//     },
//     {
//       name: t("pages.DataAnalyzer.gender.female"),
//       qty: peopleFemale,
//       value: parseFloat(((peopleFemale / totalViewers) * 100).toFixed(2)) || 0,
//     },
//   ];

//   // Calculate age breakdown pie chart data
//   const ageRangePieData = ageGraph.map(({ age, male, female }) => {
//     console.log("age ---->", age);
//     const qty = (male || 0) + (female || 0);
//     return {
//       name: age,
//       qty,
//       value: parseFloat(((qty / peopleDetected) * 100).toFixed(2)) || 0,
//     };
//   });

//   // Tooltip for both pie charts
//   const pieChartTooltip = ({ active, payload }) => {
//     if (active && payload && payload.length) {
//       return (
//         <div className="pie-chart-tooltip">
//           <p>
//             <b>{payload[0].name}</b>
//           </p>
//           <p>
//             {t("pages.DataAnalyzer.quantity")}: {payload[0].payload.qty}
//           </p>
//           <p>
//             {t("pages.DataAnalyzer.percentage")}: {payload[0].value}%
//           </p>
//         </div>
//       );
//     }
//     return null;
//   };

//   // Helper function to get age ranges
//   const getAgeRange = (isMale) => {
//     const ages = ageGraph.reduce((acc, { age, male, female }) => {
//       if (isMale && male > 0) acc.push(age);
//       if (!isMale && female > 0) acc.push(age);
//       return acc;
//     }, []);
//     if (ages.length === 0) return "N/A";
//     return `${Math.min(...ages)} - ${Math.max(...ages)}`;
//   };

//   return (
//     <div className="data-analyzer-statistic">
//       <div className="kpis">
//         <Kpi title={t("pages.DataAnalyzer.peopleArea")} value={peopleDetected} />
//         <Kpi title={t("pages.DataAnalyzer.viewers")} value={views} />
//         <Kpi title={t("pages.DataAnalyzer.interact")} value={impressions} />
//         <Kpi
//           title={t("pages.DataAnalyzer.gender.male.ageRange")}
//           value={getAgeRange(true)}
//         />
//         <Kpi
//           title={t("pages.DataAnalyzer.gender.female.ageRange")}
//           value={getAgeRange(false)}
//         />
//       </div>

//       <div className="data-analyzer-chart">
//         <div className="chart-title">{t("pages.DataAnalyzer.demographics")}</div>
//         <div className="demographics-charts">
//           <ResponsiveContainer width="50%" height={400}>
//             <PieChart>
//               <Pie
//                 data={genderPieChartData}
//                 dataKey="value"
//                 nameKey="name"
//                 cx="50%"
//                 cy="50%"
//                 outerRadius={150}
//                 fill="#8884d8"
//                 label={(item) => item.name}
//               >
//                 {genderPieChartData.map((entry, index) => (
//                   <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                 ))}
//               </Pie>
//               <Tooltip content={pieChartTooltip} />
//             </PieChart>
//           </ResponsiveContainer>
//           <ResponsiveContainer width="50%" height={400}>
//             <PieChart>
//               <Pie
//                 data={ageRangePieData}
//                 dataKey="value"
//                 nameKey="name"
//                 cx="50%"
//                 cy="50%"
//                 outerRadius={150}
//                 fill="#8884d8"
//                 label={(item) => item.name}
//               >
//                 {ageRangePieData.map((entry, index) => (
//                   <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                 ))}
//               </Pie>
//               <Tooltip content={pieChartTooltip} />
//             </PieChart>
//           </ResponsiveContainer>
//         </div>
//       </div>
//     </div>
//   );
// };

// Statistic.propTypes = {
//   data: PropTypes.shape({
//     ageGraph: PropTypes.arrayOf(
//       PropTypes.shape({
//         age: PropTypes.number.isRequired,
//         male: PropTypes.number,
//         female: PropTypes.number,
//       })
//     ),
//     peopleDetected: PropTypes.number,
//     peopleFemale: PropTypes.number,
//     peopleMale: PropTypes.number,
//     views: PropTypes.number,
//     impressions: PropTypes.number,
//   }),
// };

// export default Statistic;










// import React from "react";
// import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";
// import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
// import Kpi from "./Kpi";

// const COLORS = [
//   "#0088FE",
//   "#00C49F",
//   "#FFBB28",
//   "#FF8042",
//   "#AF19FF",
//   "#FF6392",
//   "#FF3D1F",
//   "#9FE6B9",
//   "#FFBF00",
//   "#6A0572",
// ];

// const Statistic = ({ data }) => {
//   const { t } = useTranslation();

//   if (!data) {
//     return <div>{t("pages.DataAnalyzer.noDataAvailable")}</div>;
//   }

//   // Extract data with proper validation
//   const {
//     ageGraph = [],
//     peopleDetected = 0,
//     peopleFemale = 0,
//     peopleMale = 0,
//     views = 0,
//     impressions = 0,
//   } = data;

//   // Calculate gender breakdown pie chart data
//   const totalViewers = peopleFemale + peopleMale;
//   const genderPieChartData = [
//     {
//       name: t("pages.DataAnalyzer.gender.male"),
//       qty: peopleMale,
//       value: parseFloat(((peopleMale / totalViewers) * 100).toFixed(2)) || 0,
//     },
//     {
//       name: t("pages.DataAnalyzer.gender.female"),
//       qty: peopleFemale,
//       value: parseFloat(((peopleFemale / totalViewers) * 100).toFixed(2)) || 0,
//     },
//   ];

//   // Calculate age breakdown pie chart data
//   const ageRangePieData = ageGraph.map(({ age, male, female }) => {
//     const qty = (male || 0) + (female || 0);
//     return {
//       name: age,
//       qty,
//       value: parseFloat(((qty / peopleDetected) * 100).toFixed(2)) || 0,
//     };
//   });

//   // Tooltip for both pie charts
//   const pieChartTooltip = ({ active, payload }) => {
//     if (active && payload && payload.length) {
//       return (
//         <div className="pie-chart-tooltip">
//           <p>
//             <b>{payload[0].name}</b>
//           </p>
//           <p>
//             {t("pages.DataAnalyzer.quantity")}: {payload[0].payload.qty}
//           </p>
//           <p>
//             {t("pages.DataAnalyzer.percentage")}: {payload[0].value}%
//           </p>
//         </div>
//       );
//     }
//     return null;
//   };

//   // Helper function to get age ranges
//   const getAgeRange = (isMale) => {
//     const ages = ageGraph.reduce((acc, { age, male, female }) => {
//       if (isMale && male > 0) acc.push(age);
//       if (!isMale && female > 0) acc.push(age);
//       return acc;
//     }, []);
//     if (ages.length === 0) return "N/A";
//     return `${Math.min(...ages)} - ${Math.max(...ages)}`;
//   };

//   return (
//     <div className="data-analyzer-statistic">
//       <div className="kpis">
//         <Kpi title={t("pages.DataAnalyzer.peopleArea")} value={peopleDetected} />
//         <Kpi title={t("pages.DataAnalyzer.viewers")} value={views} />
//         <Kpi title={t("pages.DataAnalyzer.interact")} value={impressions} />
//         <Kpi
//           title={t("pages.DataAnalyzer.gender.male.ageRange")}
//           value={getAgeRange(true)}
//         />
//         <Kpi
//           title={t("pages.DataAnalyzer.gender.female.ageRange")}
//           value={getAgeRange(false)}
//         />
//       </div>

//       <div className="data-analyzer-chart">
//         <div className="chart-title">{t("pages.DataAnalyzer.demographics")}</div>
//         <div className="demographics-charts">
//           <ResponsiveContainer width="50%" height={400}>
//             <PieChart>
//               <Pie
//                 data={genderPieChartData}
//                 dataKey="value"
//                 nameKey="name"
//                 cx="50%"
//                 cy="50%"
//                 outerRadius={150}
//                 fill="#8884d8"
//                 label={(item) => item.name}
//               >
//                 {genderPieChartData.map((entry, index) => (
//                   <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                 ))}
//               </Pie>
//               <Tooltip content={pieChartTooltip} />
//             </PieChart>
//           </ResponsiveContainer>
//           <ResponsiveContainer width="50%" height={400}>
//             <PieChart>
//               <Pie
//                 data={ageRangePieData}
//                 dataKey="value"
//                 nameKey="name"
//                 cx="50%"
//                 cy="50%"
//                 outerRadius={150}
//                 fill="#8884d8"
//                 label={(item) => item.name}
//               >
//                 {ageRangePieData.map((entry, index) => (
//                   <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                 ))}
//               </Pie>
//               <Tooltip content={pieChartTooltip} />
//             </PieChart>
//           </ResponsiveContainer>
//         </div>
//       </div>
//     </div>
//   );
// };

// Statistic.propTypes = {
//   data: PropTypes.shape({
//     ageGraph: PropTypes.arrayOf(
//       PropTypes.shape({
//         age: PropTypes.number.isRequired,
//         male: PropTypes.number,
//         female: PropTypes.number,
//       })
//     ),
//     peopleDetected: PropTypes.number,
//     peopleFemale: PropTypes.number,
//     peopleMale: PropTypes.number,
//     views: PropTypes.number,
//     impressions: PropTypes.number,
//   }),
// };

// export default Statistic;









// import React from "react";
// import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";
// import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
// import Kpi from "./Kpi";

// const COLORS = [
//   "#0088FE",
//   "#00C49F",
//   "#FFBB28",
//   "#FF8042",
//   "#AF19FF",
//   "#FF6392",
//   "#FF3D1F",
//   "#9FE6B9",
//   "#FFBF00",
//   "#6A0572",
// ];

// const Statistic = ({ data = [] }) => {
//   const { t } = useTranslation();

//   if (!Array.isArray(data) || data.length === 0) {
//     return <div>{t("pages.DataAnalyzer.noDataAvailable")}</div>;
//   }

//   let minAge = Infinity;
//   let maxAge = -Infinity;
//   let totalImpressionsQty = 0;
//   let totalViewersQty = 0;
//   let totalPeopleInArea = 0;

//   const genderBreakdown = {
//     maleQty: 0,
//     femaleQty: 0,
//     maleAges: [],
//     femaleAges: [],
//     getSplit: function (isMale) {
//       const qty = isMale ? this.maleQty : this.femaleQty;
//       const result = ((qty / (this.maleQty + this.femaleQty)) * 100).toFixed(2);
//       return isNaN(result) ? "N/A" : `${result}%`;
//     },
//     getAgeRange: function (isMale) {
//       const arr = isMale ? this.maleAges : this.femaleAges;
//       if (arr.length === 0) {
//         return "N/A";
//       }
//       arr.sort((a, b) => a - b);
//       return `${arr[0]} - ${arr[arr.length - 1]}`;
//     },
//   };

//   const ageBreakDown = data.reduce((acc, d) => {
//     if (!d.viewers) return acc;
//     d.viewers.forEach((view) => {
//       const age = view.age || 0;
//       if (age < minAge) minAge = age;
//       if (age > maxAge) maxAge = age;

//       if (!acc[age]) {
//         acc[age] = { age, qty: 0 };
//       }
//       if (view.gender === "Male") {
//         genderBreakdown.maleQty += view.qty || 0;
//         genderBreakdown.maleAges.push(age);
//       } else if (view.gender === "Female") {
//         genderBreakdown.femaleQty += view.qty || 0;
//         genderBreakdown.femaleAges.push(age);
//       }

//       totalImpressionsQty += view.impressions || 0;
//       totalViewersQty += view.qty || 0;
//       totalPeopleInArea += view.peopleInArea || 0;
//       acc[age].qty += view.qty || 0;
//     });
//     return acc;
//   }, {});

//   const ageRangePieData = Object.values(ageBreakDown).map((item) => ({
//     name: item.age,
//     qty: item.qty,
//     value: parseFloat(((item.qty / totalViewersQty) * 100).toFixed(2)),
//   }));

//   const agePieChartTooltip = ({ active, payload }) => {
//     if (active && payload && payload.length) {
//       return (
//         <div className="pie-chart-tooltip">
//           <p>
//             {t("pages.DataAnalyzer.age")}: {payload[0].name}
//           </p>
//           <p>
//             {t("pages.DataAnalyzer.quantity")}: {payload[0].payload.qty}
//           </p>
//           <p>
//             {t("pages.DataAnalyzer.percentage")}: {payload[0].value}%
//           </p>
//         </div>
//       );
//     }
//     return null;
//   };

//   const genderPieChartData = [
//     {
//       name: t("pages.DataAnalyzer.gender.male"),
//       qty: genderBreakdown.maleQty,
//       value: parseFloat(((genderBreakdown.maleQty / totalViewersQty) * 100).toFixed(2)),
//     },
//     {
//       name: t("pages.DataAnalyzer.gender.female"),
//       qty: genderBreakdown.femaleQty,
//       value: parseFloat(((genderBreakdown.femaleQty / totalViewersQty) * 100).toFixed(2)),
//     },
//   ];

//   return (
//     <div className="data-analyzer-statistic">
//       <div className="kpis">
//         <Kpi title={t("pages.DataAnalyzer.peopleArea")} value={totalPeopleInArea} />
//         <Kpi title={t("pages.DataAnalyzer.viewers")} value={totalViewersQty} />
//         <Kpi title={t("pages.DataAnalyzer.interact")} value={totalImpressionsQty} />
//         <Kpi
//           title={t("pages.DataAnalyzer.gender.male.ageRange")}
//           value={genderBreakdown.getAgeRange(true)}
//         />
//         <Kpi
//           title={t("pages.DataAnalyzer.gender.female.ageRange")}
//           value={genderBreakdown.getAgeRange(false)}
//         />
//       </div>

//       <div className="data-analyzer-chart">
//         <div className="chart-title">{t("pages.DataAnalyzer.demographics")}</div>
//         <div className="demographics-charts">
//           <ResponsiveContainer width="50%" height={400}>
//             <PieChart>
//               <Pie
//                 data={genderPieChartData}
//                 dataKey="value"
//                 nameKey="name"
//                 cx="50%"
//                 cy="50%"
//                 outerRadius={150}
//                 fill="#8884d8"
//                 label={(item) => item.name}
//               >
//                 {genderPieChartData.map((entry, index) => (
//                   <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                 ))}
//               </Pie>
//               <Tooltip content={agePieChartTooltip} />
//             </PieChart>
//           </ResponsiveContainer>
//           <ResponsiveContainer width="50%" height={400}>
//             <PieChart>
//               <Pie
//                 data={ageRangePieData}
//                 dataKey="value"
//                 nameKey="name"
//                 cx="50%"
//                 cy="50%"
//                 outerRadius={150}
//                 fill="#8884d8"
//                 label={(item) => item.name}
//               >
//                 {ageRangePieData.map((entry, index) => (
//                   <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                 ))}
//               </Pie>
//               <Tooltip content={agePieChartTooltip} />
//             </PieChart>
//           </ResponsiveContainer>
//         </div>
//       </div>
//     </div>
//   );
// };

// Statistic.propTypes = {
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       viewers: PropTypes.arrayOf(
//         PropTypes.shape({
//           age: PropTypes.number,
//           qty: PropTypes.number,
//           gender: PropTypes.string,
//           impressions: PropTypes.number,
//           peopleInArea: PropTypes.number,
//         })
//       ),
//     })
//   ),
// };

// export default Statistic;


























// import React from "react";
// import PropTypes from "prop-types";

// import { useTranslation } from "react-i18next";
// import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
// import Kpi from "./Kpi";

// const COLORS = [
//   "#0088FE",
//   "#00C49F",
//   "#FFBB28",
//   "#FF8042",
//   "#AF19FF",
//   "#FF6392",
//   "#FF3D1F",
//   "#9FE6B9",
//   "#FFBF00",
//   "#6A0572",
// ];

// const Statistic = ({ data }) => {
//   const { t } = useTranslation();

//   let minAge = 0;
//   let maxAge = 0;
//   let totalImpressionsQty = 0;
//   let totalViewersQty = 0;
//   let totalPeopleInArea = 0;
//   let genderBreakdown = {
//     maleQty: 0,
//     femaleQty: 0,
//     maleAges: [],
//     femaleAges: [],
//     getSplit: function (isMale) {
//       const qty = isMale ? this.maleQty : this.femaleQty;
//       const result = ((qty / (this.maleQty + this.femaleQty)) * 100).toFixed(2);
//       return isNaN(result) ? "N/A" : `${result}%`;
//     },
//     getAgeRange: function (isMale) {
//       const arr = isMale ? this.maleAges : this.femaleAges;
//       if (arr === 0) {
//         return "N/A";
//       }
//       if (arr === 1) {
//         return arr[0];
//       }
//       arr.sort();
//       return `${arr[0]} - ${arr[arr.length - 1]}`;
//     },
//   };

//   const ageBreakDown = data.reduce((acc, d) => {
//     d.viewers.forEach((view) => {
//       const age = view.age;
//       if (minAge === 0 || minAge > age) {
//         minAge = age;
//       }
//       if (maxAge === 0 || maxAge < age) {
//         maxAge = age;
//       }
//       if (!acc[age]) {
//         acc[age] = {
//           age,
//           qty: 0,
//         };
//       }
//       if (view.gender === "Male") {
//         genderBreakdown.maleQty += view.qty;
//         genderBreakdown.maleAges.push(view.age);
//       } else {
//         genderBreakdown.femaleQty += view.qty;
//         genderBreakdown.femaleAges.push(view.age);
//       }
//       totalImpressionsQty += view.impressions;
//       totalViewersQty += view.qty;
//       totalPeopleInArea += view.peopleInArea;
//       acc[age].qty += view.qty;
//     });

//     return acc;
//   }, {});

//   const ageRangePieData = Object.values(ageBreakDown).map((item) => ({
//     name: item.age,
//     qty: item.qty,
//     value: parseFloat(((item.qty / totalViewersQty) * 100).toFixed(2)),
//   }));

//   const agePieChartTooltip = ({ active, payload }) => {
//     if (active && payload && payload.length) {
//       return (
//         <div className="pie-chart-tooltip">
//           <p>
//             {t("pages.DataAnalyzer.age")}: {payload[0].name}
//           </p>
//           <p>
//             {t("pages.DataAnalyzer.quantity")}: {payload[0].payload.qty}
//           </p>
//           <p>
//             {t("pages.DataAnalyzer.percentage")}: {payload[0].value}%
//           </p>
//         </div>
//       );
//     }

//     return null;
//   };

//   const genderPieChartTooltip = ({ active, payload }) => {
//     if (active && payload && payload.length) {
//       return (
//         <div className="pie-chart-tooltip">
//           <p>
//             <b>{payload[0].name}</b>
//           </p>
//           <p>
//             {t("pages.DataAnalyzer.quantity")}: {payload[0].payload.qty}
//           </p>
//           <p>
//             {t("pages.DataAnalyzer.percentage")}: {payload[0].value}%
//           </p>
//         </div>
//       );
//     }

//     return null;
//   };

//   const total = genderBreakdown.maleQty + genderBreakdown.femaleQty;
//   const genderPieChartData = [
//     {
//       name: t("pages.DataAnalyzer.gender.male"),
//       qty: genderBreakdown.maleQty,
//       value: parseFloat(((genderBreakdown.maleQty / total) * 100).toFixed(2)),
//     },
//     {
//       name: t("pages.DataAnalyzer.gender.female"),
//       qty: genderBreakdown.femaleQty,
//       value: parseFloat(((genderBreakdown.femaleQty / total) * 100).toFixed(2)),
//     },
//   ];
//   return (
//     <div className="data-analyzer-statistic">
//       <div className="kpis">
//         <Kpi
//           title={t("pages.DataAnalyzer.peopleArea")}
//           value={totalPeopleInArea}
//         />
//         <Kpi title={t("pages.DataAnalyzer.viewers")} value={totalViewersQty} />
//         <Kpi
//           title={t("pages.DataAnalyzer.interact")}
//           value={totalImpressionsQty}
//         />
//         <Kpi
//           title={t("pages.DataAnalyzer.gender.male.ageRange")}
//           value={genderBreakdown.getAgeRange(true)}
//         />
//         <Kpi
//           title={t("pages.DataAnalyzer.gender.female.ageRange")}
//           value={genderBreakdown.getAgeRange(false)}
//         />
//       </div>

//       <div className="data-analyzer-chart">
//         <div className="chart-title">
//           {t("pages.DataAnalyzer.demographics")}
//         </div>
//         <div className="demographics-charts">
//           <ResponsiveContainer width="50%" height={400}>
//             <PieChart>
//               <Pie
//                 data={genderPieChartData}
//                 dataKey="value"
//                 nameKey="name"
//                 cx="50%"
//                 cy="50%"
//                 outerRadius={150}
//                 fill="#8884d8"
//                 label={(item) => item.name}
//               >
//                 {genderPieChartData.map((entry, index) => (
//                   <Cell
//                     key={`cell-${index}`}
//                     fill={COLORS[index % COLORS.length]}
//                   />
//                 ))}
//               </Pie>
//               <Tooltip content={genderPieChartTooltip} />
//             </PieChart>
//           </ResponsiveContainer>
//           <ResponsiveContainer width="50%" height={400}>
//             <PieChart>
//               <Pie
//                 data={ageRangePieData}
//                 dataKey="value"
//                 nameKey="name"
//                 cx="50%"
//                 cy="50%"
//                 outerRadius={150}
//                 fill="#8884d8"
//                 label={(item) => item.name}
//               >
//                 {ageRangePieData.map((entry, index) => (
//                   <Cell
//                     key={`cell-${index}`}
//                     fill={COLORS[index % COLORS.length]}
//                   />
//                 ))}
//               </Pie>
//               <Tooltip content={agePieChartTooltip} />
//             </PieChart>
//           </ResponsiveContainer>
//         </div>
//       </div>
//     </div>
//   );
// };

// Statistic.propTypes = {
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       startAt: PropTypes.object.isRequired,
//       endAt: PropTypes.object.isRequired,
//       processed: PropTypes.string,
//       unprocessed: PropTypes.string,
//     })
//   ),
// };

// export default Statistic;
