import React from "react";
import PropTypes from "prop-types";
import Dropdown from "@client.core.components/Dropdown";
import { useTranslation } from "react-i18next";
import Roles from "@client.enums/roles";
import { useSelector } from "react-redux";
import { makeProfile } from "@client.pages/Login/reducer";

const RoleDropdown = ({ value, onSelect, label }) => {
  const { t } = useTranslation();
  const profile = useSelector(makeProfile); // This is the profile of the logged in user.. the role of the logged in user is used to determine the roles that can be assigned to a user
  const roles = [
    {
      value: Roles.Admin,
      label: t("components.Advertisers.roles.admin"),
    },
    {
      value: Roles.Manager,
      label: t("components.Advertisers.roles.manager"),
    },
    {
      value: Roles.Employee,
      label: t("components.Advertisers.roles.employee"),
    },
    {
      value: Roles.Technician,
      label: t("components.Advertisers.roles.technician"),
    },
    {
      value: Roles.User,
      label: t("components.Advertisers.roles.user"),
    },
  ];

  if (profile.role === Roles.Admin) {
    roles.unshift({
      value: Roles.Admin,
      label: t("components.Advertisers.roles.admin"),
    });
  }

  return (
    <Dropdown
      items={roles}
      defaultValue={value}
      label={label}
      valueKey="value"
      labelKey="label"
      onPrepareValue={(v) => parseInt(v)}
      onSelect={onSelect}
    />
  );
};

RoleDropdown.defaultProps = {
  value: null,
  label: null,
};

RoleDropdown.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default RoleDropdown;
