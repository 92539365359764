import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "@client.core.components/Button";
import Input from "@client.core.components/Input";
import { validateModel } from "@client.utils/form";
import WelcomeCard from "@client.components/WelcomeCard/WelcomeCard";
import { useQuery } from "@client.hooks/useStableParams";
import InviteUserModel from "@client.models/inviteUser";
import UnauthorizedPageCard from "@client.components/UnauthorizedPageCard/UnauthorizedPageCard";
import PasswordValidator from "@client.components/PasswordValidator/PasswordValidator";
import "./InviteUser.scss";

const InviteUser = () => {
  const { t } = useTranslation();
  const isLoading = false;
  const [model, setModel] = useState({});
  const dispatch = useDispatch();
  const query = useQuery();
  const contact = query.get("contact");
  const company = query.get("company");

  useEffect(() => {
    setModel(
      new InviteUserModel(
        {},
        {
          password: t("pages.InviteUser.validation.password"),
          email: t("pages.Login.validation.emailAddress"),
          invalidEmail: t("pages.Login.validation.invalidEmail"),
          firstName: t("pages.InviteUser.validation.firstName"),
          lastName: t("pages.InviteUser.validation.lastName"),
          phone: t("pages.InviteUser.validation.phone"),
        }
      )
    );
  }, [t]);

  const changeModel = useCallback(
    (v, field) => {
      setModel(
        new InviteUserModel({ ...model, [field]: v }, model.validationMsgs)
      );
    },
    [model]
  );

  const register = useCallback(() => {
    const isValid = validateModel(model);
    if (isValid) {
      alert("Valid = " + JSON.stringify(model));
    } else {
      alert("Invalid = " + JSON.stringify(model));
    }
  }, [dispatch, model]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      register();
    }
  };

  return (
    <div className="user-creation">
      {!isLoading && (
        <WelcomeCard>
          <div className="welcome">
            <div>{t("pages.InviteUser.welcome.title")}</div>
            <div className="welcome-description">
              {t("pages.InviteUser.welcome.description")}
            </div>
          </div>
        </WelcomeCard>
      )}
      <UnauthorizedPageCard isLoading={isLoading}>
        <div className="title">
          {t("pages.InviteUser.header", {
            contact,
            company,
          })}
        </div>
        <div className="description">{t("pages.InviteUser.description")}</div>
        <div className="title subtitle">
          {t("pages.InviteUser.getStarted")},&nbsp;
          <span className="signin">{t("pages.InviteUser.signin")}</span>
          &nbsp;
          {t("pages.InviteUser.createAccount")}
        </div>
        <div className="row">
          <Input
            id="firstName"
            placeholder={t("pages.InviteUser.firstName")}
            value={model.firstName}
            onChange={(v) => changeModel(v, "firstName")}
            label={t("pages.InviteUser.firstName")}
            onKeyDown={handleKeyPress}
          />
          <Input
            id="lastName"
            placeholder={t("pages.InviteUser.lastName")}
            value={model.lastName}
            onChange={(v) => changeModel(v, "lastName")}
            label={t("pages.InviteUser.lastName")}
            onKeyDown={handleKeyPress}
          />
        </div>
        <div className="row">
          <Input
            id="email"
            placeholder={t("pages.InviteUser.email")}
            value={model.email}
            onChange={(v) => changeModel(v, "email")}
            label={t("pages.InviteUser.email")}
            onKeyDown={handleKeyPress}
          />
          <Input
            id="phone"
            placeholder={t("pages.InviteUser.phone")}
            value={model.phone}
            onChange={(v) => changeModel(v, "phone")}
            label={t("pages.InviteUser.phone")}
            onKeyDown={handleKeyPress}
          />
        </div>
        <PasswordValidator
          id="pswrd"
          value={model.password}
          placeholder={t("pages.InviteUser.password")}
          onChange={(v) => changeModel(v, "password")}
          onKeyDown={handleKeyPress}
        />
        <div className="btn">
          <Button
            text={t("pages.InviteUser.signinForFree")}
            onClick={register}
          />
        </div>
      </UnauthorizedPageCard>
    </div>
  );
};

export default InviteUser;
