import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "@client.pages/Login/reducer";
import advertiserReducer from "@client.pages/Advertiser/reducer";
import campaignReducer from "@client.pages/Campaign/reducer";
import deviceReducer from "@client.pages/Devices/reducer";
import profileReducer from "@client.pages/ProfileGeneral/reducer";
import profileUserEditReducer from "@client.pages/ProfileGeneralEditUser/reducer";
import locationReducer from "@client.pages/Zones/reducer";
import vpnManagementReducer from "@client.pages/VpnManagement/reducer";
import usersReducer from "@client.pages/Users/reducer";
import userLogsReducer from "@client.pages/UserLogs/reducer";
import systemLogsReducer from "@client.pages/SystemLogs/reducer";
import accountReducer from "@client.pages/Accounts/reducer";
import softwareVersionsReducer from "@client.features/analytics-tools/softwareVersions";
import plansReducer from "@client.features/billing/plans";
import dashboardReducer from "@client.features/main/dashboard";

const rootReducer = {
  login: loginReducer,
  campaign: campaignReducer,
  advertiser: advertiserReducer,
  dashboard: dashboardReducer,
  device: deviceReducer,
  profile: profileReducer,
  profileUserEdit: profileUserEditReducer,
  location: locationReducer,
  vpnManagement: vpnManagementReducer,
  user: usersReducer,
  userLogs: userLogsReducer,
  systemLogs: systemLogsReducer,
  account: accountReducer,
  softwareVersions: softwareVersionsReducer,
  plans: plansReducer,
};

export const getStore = (initialState = null) => {
  const optionalSettings: Record<string, unknown> = {};
  if (initialState) {
    optionalSettings.preloadedState = initialState;
  }
  return configureStore({
    reducer: rootReducer,
    ...optionalSettings,
  });
};

export type AppDispatch = ReturnType<typeof getStore>["dispatch"];
