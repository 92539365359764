import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import "./StatisticView.scss";

const StatisticView = ({ title, data, children, className }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames("statistic-view", className)}>
      <div 
        className="statistic-header d-flex justify-content-between" 
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "30px",
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: "18px" }}>
          {t(title)}
        </div>
        <div style={{ minWidth: "200px", textAlign: "right" }}>
          {children}
        </div>
      </div>
      <div className="overview" style={{ 
        marginTop: "10px" ,
        marginBottom: "60px",
        }}>
        <div className="block green">
          {data.green.value}
          <span>{t(data.green.title)}</span>
        </div>
        <div className="block orange">
          {data.orange.value}
          <span>{t(data.orange.title)}</span>
        </div>
        <div className="block blue">
          {data.blue.value}
          <span>{t(data.blue.title)}</span>
        </div>
      </div>
    </div>
  );
};

const contentType = PropTypes.shape({
  title: PropTypes.string,
  value: PropTypes.node,
});

StatisticView.propTypes = {
  data: PropTypes.shape({
    green: contentType,
    orange: contentType,
    blue: contentType,
  }).isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default StatisticView;

















// import React from "react";
// import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";
// import classNames from "classnames";
// import "./StatisticView.scss";

// const StatisticView = ({ title, data, children, className }) => {
//   const { t } = useTranslation();

//   return (
//     <div className={classNames("statistic-view", className)}>
//       <div className="statistic-header d-flex justify-content-between ">
//         <div>{t(title)}</div>
//         {children}
//       </div>
//       <div className="overview">
//         <div className="block green">
//           {data.green.value}
//           <span>{t(data.green.title)}</span>
//         </div>
//         <div className="block orange">
//           {data.orange.value}
//           <span>{t(data.orange.title)}</span>
//         </div>
//         <div className="block blue">
//           {data.blue.value}
//           <span>{t(data.blue.title)}</span>
//         </div>
//       </div>
//     </div>
//   );
// };

// const contentType = PropTypes.shape({
//   title: PropTypes.string,
//   value: PropTypes.node,
// });

// StatisticView.propTypes = {
//   data: PropTypes.shape({
//     green: contentType,
//     orange: contentType,
//     blue: contentType,
//   }).isRequired,
//   title: PropTypes.string.isRequired,
//   children: PropTypes.node,
//   className: PropTypes.string,
// };

// export default StatisticView;
