import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDeviceError,
  makeDevicesError,
} from "@client.pages/Devices/reducer";
import ServiceProvider from "@client.services/provider";
import { TOAST_EVENT } from "@client.core.components/Toast";
import { clearLoginError, makeLoginError } from "@client.pages/Login/reducer";
import {
  clearAdvertiserError,
  makeAdvertiserError,
} from "@client.pages/Advertiser/reducer";
import {
  clearCampaignError,
  makeCampaignError,
} from "@client.pages/Campaign/reducer";
import {
  clearProfileError,
  makeProfileError,
} from "@client.pages/ProfileGeneral/reducer";
import {
  clearLocationsError,
  makeLocationsError,
} from "@client.pages/Zones/reducer";
import { clearUserError, makeUserError } from "@client.pages/Users/reducer";
import {
  clearSoftwareVersionsError,
  makeSoftwareVersionsError,
} from "@client.features/analytics-tools/softwareVersions";
import {
  clearPlansError,
  makePlansError,
} from "@client.features/billing/plans";

export const ErrorMessages = () => {
  const loginError = useSelector(makeLoginError);
  const devicesError = useSelector(makeDevicesError);
  const advertiserError = useSelector(makeAdvertiserError);
  const campaignError = useSelector(makeCampaignError);
  const profileError = useSelector(makeProfileError);
  const locationsError = useSelector(makeLocationsError);
  const userError = useSelector(makeUserError);
  const softwareVersionsError = useSelector(makeSoftwareVersionsError);
  const plansError = useSelector(makePlansError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      devicesError ||
      loginError ||
      campaignError ||
      advertiserError ||
      profileError ||
      userError ||
      locationsError ||
      softwareVersionsError ||
      plansError
    ) {
      const errors = [
        devicesError ||
          loginError ||
          campaignError ||
          advertiserError ||
          profileError ||
          userError ||
          locationsError ||
          softwareVersionsError ||
          plansError,
      ]
        .filter(Boolean)
        .map((e) => ({
          type: "error",
          msg: Array.isArray(e) ? e[0].message : e.message,
        }));
      ServiceProvider.EventEmitter.emit(TOAST_EVENT, errors);
    }
    if (devicesError) dispatch(clearDeviceError());
    if (loginError) dispatch(clearLoginError());
    if (advertiserError) dispatch(clearAdvertiserError());
    if (campaignError) dispatch(clearCampaignError());
    if (profileError) dispatch(clearProfileError());
    if (locationsError) dispatch(clearLocationsError());
    if (userError) dispatch(clearUserError());
    if (softwareVersionsError) dispatch(clearSoftwareVersionsError());
    if (plansError) dispatch(clearPlansError());
  }, [
    dispatch,
    devicesError,
    loginError,
    advertiserError,
    campaignError,
    profileError,
    userError,
    locationsError,
    softwareVersionsError,
    plansError,
  ]);

  return null;
};
