/* eslint-disable react/prop-types */
/* eslint-disable indent */
import React from "react";
import PropTypes from "prop-types";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";
import moment from "moment";

const ViewsChart = ({ data }) => {
  const { t } = useTranslation();

  if (!data || !data.ivpGraph || data.ivpGraph.length === 0) {
    return (
      <div className="data-analyzer-chart">
        <div className="chart-title">{t("pages.DataAnalyzer.viewsChart.title")}</div>
        <p>{t("pages.DataAnalyzer.noData")}</p>
      </div>
    );
  }

  // Transform `ivpGraph` data for use in the chart
  const chartData = data.ivpGraph.map((entry) => ({
    date: moment(entry.datetime).format("HH:mm:ss"), // Format datetime
    impressions: entry.impressions,
    qty: entry.peopleDetected,
    views: entry.views,
  }));

  return (
    <div className="data-analyzer-chart">
      <div className="chart-title">{t("pages.DataAnalyzer.viewsChart.title")}</div>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={chartData}
          margin={{ top: 20, right: 30, left: -20, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="impressions"
            stroke="#2D99FF"
            activeDot={{ r: 8 }}
            name="Impressions"
          />
          <Line type="monotone" dataKey="views" stroke="#2cd9c5" name="Views" />
          <Line type="monotone" dataKey="qty" stroke="#ff6c40" name="People Around" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

ViewsChart.propTypes = {
  data: PropTypes.shape({
    ivpGraph: PropTypes.arrayOf(
      PropTypes.shape({
        datetime: PropTypes.string.isRequired,
        impressions: PropTypes.number.isRequired,
        peopleDetected: PropTypes.number.isRequired,
        views: PropTypes.number.isRequired,
      })
    ),
  }),
};

export default ViewsChart;








// import React from "react";
// import PropTypes from "prop-types";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";
// import { useTranslation } from "react-i18next";
// import moment from "moment";

// const ViewsChart = ({ data }) => {
//   const { t } = useTranslation();

//   const dataToShow = data.reduce((acc, d) => {
//     d.viewers.forEach((view) => {
//       const date = view.date;
//       if (!acc[view.date]) {
//         const dateStr = moment(date).format("HH:mm:ss");
//         acc[date] = {
//           date: dateStr,
//           impressions: 0,
//           qty: 0,
//           views: 0,
//         };
//       }
//       acc[date].impressions += view.impressions;
//       acc[date].qty += view.peopleInArea;
//       acc[date].views += view.views;
//     });

//     return acc;
//   }, {});

//   return (
//     <div className="data-analyzer-chart">
//       <div className="chart-title">
//         {t("pages.DataAnalyzer.viewsChart.title")}
//       </div>
//       <ResponsiveContainer width="100%" height={400}>
//         <LineChart
//           data={Object.values(dataToShow)}
//           margin={{ top: 20, right: 30, left: -20, bottom: 20 }}
//         >
//           <CartesianGrid strokeDasharray="3 3" />
//           <XAxis dataKey="date" />
//           <YAxis />
//           <Tooltip />
//           <Legend />
//           <Line
//             type="monotone"
//             dataKey="impressions"
//             stroke="#2D99FF"
//             activeDot={{ r: 8 }}
//             name="Impressions"
//           />
//           <Line type="monotone" dataKey="views" stroke="#2cd9c5" name="Views" />
//           <Line
//             type="monotone"
//             dataKey="qty"
//             stroke="#ff6c40"
//             name="People Around"
//           />
//         </LineChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// ViewsChart.propTypes = {
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       startAt: PropTypes.object.isRequired,
//       endAt: PropTypes.object.isRequired,
//       processed: PropTypes.string,
//       unprocessed: PropTypes.string,
//     })
//   ),
// };

// export default ViewsChart;
