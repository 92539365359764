import { isObject, isArray, isUndefined, isDate } from "lodash";
import config from "@client.config";
import Pages from "@client.enums/pages";
import ServiceProvider from "./provider";
import LocalStorageKeys from "@client.enums/localStorageKeys";

export default class BaseService {
  async fetchData(endPoint, options = {}, isExternalService) {
    const token = ServiceProvider.LocalStorage.getItem(LocalStorageKeys.Token);
    const authHeader = {};
    if (token && !isExternalService) {
      authHeader["Authorization"] = `KGN ${token}`;
    }
    const requestOptions = {
      headers: {
        Accept: "application/json",
        ...options.headers,
        ...authHeader,
      },
      method: options.method || "GET",
      body: options.body,
    };
    const url = isExternalService ? endPoint : config.apiUrl;
    return fetch(url, requestOptions);
  }

  async api(endPoint, options = {}, isExternalService, optionalHeaders = {}) {
    const { headers, ...rest } = options;
    let url = endPoint;
    if (!rest.method && Object.keys(rest).length > 0) {
      url = `${endPoint}?${this.prepareRequestQuery("", rest, true)}`;
    }
    return this.fetchData(
      url,
      rest.method ? options : { headers: { ...headers, ...optionalHeaders } },
      isExternalService
    )
      .then(async (response) => {
        if (response.ok) {
          if (response.status === 204) {
            return Promise.resolve(null);
          }
          const res = await response.json();

          if (response.status !== 200 && res.error) {
            throw new Error(res.errors);
          }

          return res;
        }
        if (
          [401, 403].includes(response.status) &&
          window.location.pathname.indexOf(Pages.Login) === -1
        ) {
          window.location.replace(Pages.Login);
          return Promise.resolve(null);
        }
        // try {
        const res = await response.json();
        // if (response.status !== 200 && res.errors) {
        //   throw new Error(
        //     JSON.stringify(
        //       isArray(res.errors) ? res.errors : [{ msg: res.errors }]
        //     )
        //   );
        // }
        return res;
        // } catch (err) {
        //   if (response.status !== 403)
        //     console.error(`Something went wrong during ${endPoint}`);
        // }
        // return Promise.resolve(null);
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  }

  static getRequestOptions(httpMethod, data = null) {
    const options = {
      method: httpMethod,
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (data) {
      options.body = JSON.stringify(data);
    }

    return options;
  }

  async post(endPoint, data = null, isExternalService = false) {
    return this.api(
      endPoint,
      BaseService.getRequestOptions("POST", data),
      isExternalService
    );
  }

  async delete(endPoint, data = null, isExternalService = false) {
    return this.api(
      endPoint,
      BaseService.getRequestOptions("DELETE", data),
      isExternalService
    );
  }

  async put(endPoint, data = null, isExternalService = false) {
    return this.api(
      endPoint,
      BaseService.getRequestOptions("PUT", data),
      isExternalService
    );
  }

  async upload(
    endPoint,
    formData,
    isExternalService = false,
    additionalHeaders = {}
  ) {
    // Exclude Content-Type to let the browser set it automatically
    const options = {
      method: "POST",
      body: formData,
      headers: {
        ...additionalHeaders,
      },
    };
    return this.api(endPoint, options, isExternalService);
  }

  prepareRequestQuery = (prefix, val, top) => {
    if (isUndefined(top)) top = true;
    if (isArray(val)) {
      return val
        .map((value, key) => {
          return this.prepareRequestQuery(
            top ? key : `${prefix}[]`,
            value,
            false
          );
        })
        .join("&");
    } else if (isDate(val)) {
      return `${encodeURIComponent(prefix)}=${val.toISOString()}`;
    } else if (isObject(val)) {
      return Object.keys(val)
        .map((key) => {
          return this.prepareRequestQuery(
            top ? key : `${prefix}[${key}]`,
            val[key],
            false
          );
        })
        .join("&");
    } else {
      return `${encodeURIComponent(prefix)}=${encodeURIComponent(val)}`;
    }
  };
}
