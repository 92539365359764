import BaseService from "@client.services/baseService";

export default class SystemLogService extends BaseService {
  async getAll({ limit, offset, startDate, endDate, search, searchOn }) {
    return super.post("", {
      query: `
      query($pagination: PaginationInputType) {
        systemLogs(pagination: $pagination) {
          count
          results {
            Id
            createdAt
            userId
            userFullName
            type
            description
            ipAddress
            entity
            entityId
          }
        }
      }
      `,
      variables: {
        pagination: {
          limit,
          offset,
          search,
          searchOn,
          startDate,
          endDate,
        },
      },
    });
  }

  async create(systemLog) {
    return super.post("", {
      query: `mutation($fields: SystemLogsInputType){
        createSystemLog(fields: $fields){
          message
          systemLog{
            description
            Id
            userId
            userFullName
            entity
            entityId
            type
          }
        }
      }`,
      variables: {
        fields: {
          description: systemLog.description,
          type: systemLog.type,
          entity: systemLog.entity,
          entityId: systemLog.entityId || "",
        },
      },
    });
  }
}
