import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "@client.core.components/Button";

const DeleteZoneConfirmation = ({ onDelete, onCancel }) => {
  const { t } = useTranslation();
  return (
    <div className="delete-zone-confirmation">
      <span>
        {t("pages.Locations.components.ZonesTree.modal.confirmation")}
      </span>
      <div className="management-buttons">
        <Button
          text={t("pages.Locations.components.ZonesTree.modal.delete")}
          onClick={onDelete}
        />
        <Button
          text={t("pages.Locations.components.ZonesTree.modal.cancel")}
          onClick={onCancel}
          className="alternative"
        />
      </div>
    </div>
  );
};

DeleteZoneConfirmation.propTypes = {
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
};

export default DeleteZoneConfirmation;
