import React from "react";
import { useSelector } from "react-redux";
import { makeProfile } from "@client.pages/Login/reducer";
import StatisticView from "@client.components/StatisticView/StatisticView";
import Icon from "@client.core.components/Icon";
import { formatNumber } from "@client.utils/utils";

const ReportsOverview = () => {
  const profile = useSelector(makeProfile);
  return (
    <StatisticView
      className="reports-overview-statistic"
      title="pages.Reports.Overview.CampaignPerfomance"
      data={{
        green: {
          value: (
            <div className="blue-value">
              {formatNumber(1000, 0)}
              <Icon name="eye" />
            </div>
          ),
          title: "pages.Reports.Overview.totalView",
        },
        orange: {
          value: (
            <div>
              {formatNumber(0.5)} {profile.currency}
            </div>
          ),
          title: "pages.Reports.Overview.perView",
        },
        blue: {
          value: (
            <div>
              {formatNumber(500)} {profile.currency}
            </div>
          ),
          title: "pages.Reports.Overview.spent",
        },
      }}
    />
  );
};

export default ReportsOverview;
