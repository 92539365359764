import BaseModel from "./base";
import { get } from "lodash";

class DeviceModel extends BaseModel {
  constructor(device = {}, msg = {}) {
    super(msg);

    // Retain only necessary fields
    this.Id = get(device, "Id", "");
    this.deviceName = get(device, "deviceName", "");
    this.deviceType = get(device, "deviceType", 2); // Default to 0
    this.machineId = get(device, "machineId", "");

    // Define validation rules for the required fields
    this.validationRules = {
      deviceName: {
        presence: { allowEmpty: false, message: msg.name || "Device name is required" },
      },
      machineId: {
        presence: { allowEmpty: false, message: msg.machineId || "Machine ID is required" },
      },
      deviceType: {
        presence: { allowEmpty: false, message: msg.deviceType || "Device type is required" },
      },
    };
  }

  // Define the fields to be used in mutations or forms
  get fields() {
    return {
      deviceName: this.deviceName,
      deviceType: this.deviceType,
      machineId: this.machineId,
      Id: this.Id || null, // Add Id only if it exists
    };
  }
}

export default DeviceModel;
