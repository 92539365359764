import React from 'react';

const MapMarker = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="14" fill="#FF0066" />
      <mask
        id="mask0_737_76536"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="6"
        width="16"
        height="16"
      >
        <rect x="6" y="6" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_737_76536)">
        <path
          d="M11.3333 18.6668V9.3335L18.6666 14.0002L11.3333 18.6668Z"
          fill="white"
        />
      </g>
      <path
        d="M2 14C2 16.3734 2.70379 18.6935 4.02236 20.6668C5.34094 22.6402 7.21508 24.1783 9.4078 25.0866C11.6005 25.9948 14.0133 26.2324 16.3411 25.7694C18.6689 25.3064 20.8071 24.1635 22.4853 22.4853C24.1635 20.8071 25.3064 18.6689 25.7694 16.3411C26.2324 14.0133 25.9948 11.6005 25.0866 9.4078C24.1783 7.21509 22.6402 5.34094 20.6668 4.02237C18.6935 2.70379 16.3734 2 14 2V4.16387C15.9454 4.16387 17.8471 4.74075 19.4647 5.82156C21.0822 6.90237 22.3429 8.43856 23.0874 10.2359C23.8319 12.0332 24.0267 14.0109 23.6471 15.9189C23.2676 17.827 22.3308 19.5796 20.9552 20.9552C19.5796 22.3308 17.827 23.2676 15.9189 23.6471C14.0109 24.0267 12.0332 23.8319 10.2359 23.0874C8.43856 22.3429 6.90237 21.0822 5.82156 19.4647C4.74075 17.8471 4.16387 15.9454 4.16387 14H2Z"
        fill="white"
      />
    </svg>
  );
};

export default MapMarker;