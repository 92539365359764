import PropTypes from "prop-types";

const ZoneEntity = PropTypes.shape({
  lookupId: PropTypes.string,
  name: PropTypes.string,
  ppvRate: PropTypes.number,
  primeRate: PropTypes.number,
  currency: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
});

ZoneEntity.items = PropTypes.arrayOf(ZoneEntity);

export default ZoneEntity;
