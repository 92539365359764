import moment from "moment";
import config from "@client.config";
import CampaignStatuses from "@client.enums/campaignStatuses";

export const getStatus = (t, status) => {
  switch (parseInt(status)) {
    case CampaignStatuses.Queued:
      return t("CampaignStatuses.Queued");
    case CampaignStatuses.PendingApproval:
      return t("CampaignStatuses.PendingApproval");
    case CampaignStatuses.Draft:
      return t("CampaignStatuses.Draft");
    case CampaignStatuses.Paused:
      return t("CampaignStatuses.Paused");
    case CampaignStatuses.Active:
      return t("CampaignStatuses.Active");
    case CampaignStatuses.Archive:
      return t("CampaignStatuses.Archive");
  }
};

export const getColumns = (t) => [
  {
    field: "campaignName",
    tooltipField: "campaignName",
    headerName: t("pages.CampaignApproval.columns.name"),
  },
  {
    field: "companyName", // Updated to use companyName instead of advertiserId
    tooltipField: "companyName", // Tooltip also shows the companyName
    headerName: t("pages.CampaignApproval.columns.createdBy"), // Keeps the header text
  },
  {
    field: "createdAt",
    headerName: t("pages.CampaignApproval.columns.createdAt"),
    tooltipValueGetter: (params) =>
      moment(params.data.createdAt).format(config.dateTimeSecondsFormat),
    cellRenderer: (params) =>
      moment(params.data.createdAt).format(config.dateTimeSecondsFormat),
  },
  {
    field: "status",
    headerName: t("pages.CampaignApproval.columns.status"),
    tooltipValueGetter: (params) => getStatus(t, params.data.status),
    cellRenderer: (params) => getStatus(t, params.data.status),
  },
  {
    field: "notes",
    tooltipField: "notes",
    headerName: t("pages.CampaignApproval.columns.notes"),
  },
];
















// import moment from "moment";
// import config from "@client.config";
// import CampaignStatuses from "@client.enums/campaignStatuses";

// export const getStatus = (t, status) => {
//   switch (parseInt(status)) {
//     case CampaignStatuses.Queued:
//       return t("CampaignStatuses.Queued");
//     case CampaignStatuses.PendingApproval:
//       return t("CampaignStatuses.PendingApproval");
//     case CampaignStatuses.Draft:
//       return t("CampaignStatuses.Draft");
//     case CampaignStatuses.Paused:
//       return t("CampaignStatuses.Paused");
//     case CampaignStatuses.Active:
//       return t("CampaignStatuses.Active");
//     case CampaignStatuses.Archive:
//       return t("CampaignStatuses.Archive");
//   }
// };
// export const getColumns = (t) => [
//   {
//     field: "campaignName",
//     tooltipField: "campaignName",
//     headerName: t("pages.CampaignApproval.columns.name"),
//   },
//   {
//     field: "advertiserId",
//     tooltipField: "advertiserId",
//     headerName: t("pages.CampaignApproval.columns.createdBy"),
//   },
//   {
//     field: "createdAt",
//     headerName: t("pages.CampaignApproval.columns.createdAt"),
//     tooltipValueGetter: (params) =>
//       moment(params.data.createdAt).format(config.dateTimeSecondsFormat),
//     cellRenderer: (params) =>
//       moment(params.data.createdAt).format(config.dateTimeSecondsFormat),
//   },
//   {
//     field: "status",
//     headerName: t("pages.CampaignApproval.columns.status"),
//     tooltipValueGetter: (params) => getStatus(t, params.data.status),
//     cellRenderer: (params) => getStatus(t, params.data.status),
//   },
//   {
//     field: "notes",
//     tooltipField: "notes",
//     headerName: t("pages.CampaignApproval.columns.notes"),
//   },
// ];
