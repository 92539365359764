import React from "react";
import classNames from "classnames";
import "./Input.scss";

interface InputProps {
  id?: string;
  value?: string;
  type?: string;
  className?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  label?: string;
  maxLength?: number;
  optional?: React.InputHTMLAttributes<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  readOnly?: boolean;
}

const Input: React.FC<InputProps> = ({
  id,
  value = "",
  type = "text",
  className,
  onChange,
  placeholder,
  label = "",
  maxLength,
  optional,
  onKeyDown,
  readOnly,
}) => {
  return (
    <div className="input-container">
      {label && <span className="label">{label}</span>}
      <input
        type={type}
        id={id}
        className={classNames("input", className)}
        autoComplete="off"
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
        readOnly={readOnly}
        onChange={(event) => {
          if (onChange) onChange(event.target.value);
        }}
        {...optional}
      />
    </div>
  );
};

export default Input;
