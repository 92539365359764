import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";
import ServiceProvider from "@client.services/provider";
import { IPlan } from "./types";

const initialState = {
  items: [],
  error: null,
  loading: false,
};

export const getAllPlansAsync = createAsyncThunk("plans/getAll", async () => {
  return await ServiceProvider.Plan.getAll();
});

export const createPlanAsync = createAsyncThunk(
  "plans/create",
  async (model: IPlan) => {
    return ServiceProvider.Plan.create(model);
  }
);

export const updatePlanAsync = createAsyncThunk(
  "plans/update",
  async (model: IPlan) => {
    return ServiceProvider.Plan.update(model);
  }
);

export const deletePlanAsync = createAsyncThunk(
  "plans/delete",
  async (id: string) => {
    return ServiceProvider.Plan.delete(id);
  }
);

export const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    clearPlansError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPlansAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPlansAsync.fulfilled, (state, action) => {
        state.items = get(action, "payload.data.plans.results", []);
        state.error = getGraphqlResponseError(action);
        state.loading = false;
      })
      .addCase(getAllPlansAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
        state.loading = false;
      })
      .addCase(createPlanAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPlanAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(createPlanAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(updatePlanAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePlanAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
        state.loading = false;
      })
      .addCase(updatePlanAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
        state.loading = false;
      })
      .addCase(deletePlanAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePlanAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
        state.loading = false;
      })
      .addCase(deletePlanAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
        state.loading = false;
      });
  },
});

export const { clearPlansError } = plansSlice.actions;

export const makePlansError = (state) => state.plans.error;
export const makePlans = (state) => state.plans.items;
export const makePlansLoading = (state) => state.plans.loading;

export default plansSlice.reducer;
