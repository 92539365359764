


//best



import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import Loader from "@client.core.components/Loader";

const TimelineItem = ({ item, className, onClick }) => {
  // console.log("TimelineItem -> item", item);
  // const src = item.afterMediaUrl==="" ? "https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4": "https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4";
  // const src = item.afterMediaUrl==="" ? "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4": "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4";
  // const src = item.afterMediaUrl==="" ? "https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4": item.afterMediaUrl;
  // const src = item.afterMediaUrl==="" ? "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4": item.afterMediaUrl;
  const src = item.campaignMediaUrl==="" ? "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4": item.campaignMediaUrl;
  
  
  
  const [loading, setLoading] = useState(true);

  return (
    <div className={classNames("timeline-item", className)} onClick={onClick}>
      {src && (
        <div className="video-wrapper">
          {loading && <Loader loading={true} />}
          <video
            muted
            autoPlay={false}
            loop={false}
            preload="metadata"
            onLoadedMetadata={() => setLoading(false)}
            height={50}
          >
            <source src={`${src}#t=1`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      <div className="timeline-frame">
        <div className="track-segment" />
        <div className="track-separator" />
      </div>
      <div className={"time-range"}>
        {/* {moment(item.startDate).format("HH:mm:ss")} -{" "} */}
        {moment(item.startDate).utc().local().format("HH:mm:ss")} -{" "}
        {/* {moment(item.endDate).format("HH:mm:ss")} */}
        {moment(item.endDate).utc().local().format("HH:mm:ss")}
      </div>
    </div>
  );
};

TimelineItem.propTypes = {
  
  onClick: PropTypes.func,
  className: PropTypes.string,
  item: PropTypes.shape({
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    afterMediaUrl: PropTypes.string,
    campaignMediaUrl: PropTypes.string,
  }).isRequired,
};

export default TimelineItem;














// import React, { useState } from "react";
// import PropTypes from "prop-types";
// import classNames from "classnames";
// import moment from "moment";
// import Loader from "@client.core.components/Loader";

// const TimelineItem = ({ item, className, onClick }) => {
//   const src = item.afterMediaUrl; // Using afterMediaUrl instead of processed/unprocessed
//   const [loading, setLoading] = useState(true);

//   return (
//     <div className={classNames("timeline-item", className)} onClick={onClick}>
//       {src && (
//         <div className="video-wrapper">
//           {loading && <Loader loading={true} />}
//           <video
//             muted
//             autoPlay={false}
//             loop={false}
//             preload="metadata"
//             onLoadedMetadata={() => setLoading(false)}
//             height={50}
//           >
//             <source src={`${src}#t=1`} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         </div>
//       )}
//       <div className="timeline-frame">
//         <div className="track-segment" />
//         <div className="track-separator" />
//       </div>
//       <div className={"time-range"}>
//         {moment(item.startDate).format("HH:mm:ss")} -{" "}
//         {moment(item.endDate).format("HH:mm:ss")}
//       </div>
//     </div>
//   );
// };

// TimelineItem.propTypes = {
//   onClick: PropTypes.func,
//   className: PropTypes.string,
//   item: PropTypes.shape({
//     startDate: PropTypes.string.isRequired,
//     endDate: PropTypes.string.isRequired,
//     afterMediaUrl: PropTypes.string, // Changed to use afterMediaUrl
//   }).isRequired,
// };

// export default TimelineItem;


















// import React, { useState } from "react";
// import PropTypes from "prop-types";
// import classNames from "classnames";
// import moment from "moment";
// import Loader from "@client.core.components/Loader";

// const TimelineItem = ({ item, className, onClick }) => {
//   console.log("TimelineItem -> item", item);
//   const src = item.processed || item.unprocessed;
//   const [loading, setLoading] = useState(true);

//   return (
//     <div className={classNames("timeline-item", className)} onClick={onClick}>
//       {src && (
//         <div className="video-wrapper">
//           {loading && <Loader loading={true} />}
//           <video
//             muted
//             autoPlay={false}
//             loop={false}
//             preload="metadata"
//             onLoadedMetadata={() => setLoading(false)}
//             height={50}
//           >
//             <source src={`${src}#t=1`} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         </div>
//       )}
//       <div className="timeline-frame">
//         <div className="track-segment" />
//         <div className="track-separator" />
//       </div>
//       <div className={"time-range"}>
//         {moment(item.startAt).format("HH:mm:ss")} -{" "}
//         {moment(item.endAt).format("HH:mm:ss")}
//       </div>
//     </div>
//   );
// };

// TimelineItem.propTypes = {
//   onClick: PropTypes.func,
//   className: PropTypes.string,
//   item: PropTypes.shape({
//     startAt: PropTypes.object.isRequired,
//     endAt: PropTypes.object.isRequired,
//     processed: PropTypes.string,
//     unprocessed: PropTypes.string,
//   }).isRequired,
// };

// export default TimelineItem;
