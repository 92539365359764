



// working exept "All"(but Less)

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Dropdown from "@client.core.components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAdvertisersAsync,
  makeAdvertisers,
  makeAdvertisersLoading,
} from "@client.pages/Advertiser/reducer";
import "./AdvertiserDropdown.scss";

const AdvertiserDropdown = ({ value, onSelect, label, disabled ,fromCreate=null}) => {
  const dispatch = useDispatch();
  const advertisers = useSelector(makeAdvertisers);
  const isLoading = useSelector(makeAdvertisersLoading);

  useEffect(() => {
    dispatch(getAllAdvertisersAsync());
  }, [dispatch]);

  // Add "All" option to the advertiser list
  const advertisersWithAll = [
    { Id: null, companyName: "All" }, // Special option to represent "All"
    ...advertisers,
  ];

  const handleSelect = (selectedValue) => {
    // console.log("From AdvertiserDropdown", selectedValue);

    if (selectedValue === null) {
      // Explicitly set to null for "All"
      dispatch(getAllAdvertisersAsync());
      onSelect(null);
    } else {
      onSelect(selectedValue);
    }
  };

  return (
    <Dropdown
      items={fromCreate==true?advertisers:advertisersWithAll}
      disabled={isLoading || disabled}
      defaultValue={value}
      value={value} // Ensure the dropdown respects the current value
      className="advertiser-dropdown"
      labelClassName="advertiser-background"
      label={label}
      valueKey="Id"
      labelKey="companyName"
      onSelect={handleSelect} // Use the custom handleSelect function
    />
  );
};

AdvertiserDropdown.defaultProps = {
  value: null,
  label: null,
  disabled: false,
};

AdvertiserDropdown.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  fromCreate: PropTypes.bool,
};

export default AdvertiserDropdown;











// working exept "All"

// import React, { useEffect } from "react";
// import PropTypes from "prop-types";
// import Dropdown from "@client.core.components/Dropdown";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getAllAdvertisersAsync,
//   makeAdvertisers,
//   makeAdvertisersLoading,
// } from "@client.pages/Advertiser/reducer";
// import "./AdvertiserDropdown.scss";

// const AdvertiserDropdown = ({ value, onSelect, label, disabled }) => {
//   const dispatch = useDispatch();
//   const advertisers = useSelector(makeAdvertisers);
//   const isLoading = useSelector(makeAdvertisersLoading);

//   useEffect(() => {
//     dispatch(getAllAdvertisersAsync());
//   }, [dispatch]);

//   // Add "All" option to the advertiser list
//   const advertisersWithAll = [
//     { Id: null, companyName: "All" }, // Special option to represent "All"
//     ...advertisers,
//   ];

//   return (
//     <Dropdown
//       items={advertisersWithAll}
//       disabled={isLoading || disabled}
//       defaultValue={value}
//       className="advertiser-dropdown"
//       labelClassName="advertiser-background"
//       label={label}
//       valueKey="Id"
//       labelKey="companyName"
//       onSelect={onSelect}
//     />
//   );
// };

// AdvertiserDropdown.defaultProps = {
//   value: null,
//   label: null,
//   disabled: false,
// };

// AdvertiserDropdown.propTypes = {
//   value: PropTypes.string,
//   label: PropTypes.string,
//   disabled: PropTypes.bool,
//   onSelect: PropTypes.func.isRequired,
// };

// export default AdvertiserDropdown;










// erase after one second

// import React, { useEffect } from "react";
// import PropTypes from "prop-types";
// import Dropdown from "@client.core.components/Dropdown";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getAllAdvertisersAsync,
//   makeAdvertisers,
//   makeAdvertisersLoading,
// } from "@client.pages/Advertiser/reducer";
// import "./AdvertiserDropdown.scss";

// const AdvertiserDropdown = ({ value, onSelect, label, disabled }) => {
//   // console.log("From AdvertiserDropdown", value);
//   const dispatch = useDispatch();
//   const advertisers = useSelector(makeAdvertisers);
//   console.log("From AdvertiserDropdown", advertisers);
//   const isLoading = useSelector(makeAdvertisersLoading);

//   useEffect(() => {
//     dispatch(getAllAdvertisersAsync());
//   }, [dispatch]);

  
//   useEffect(() => {
//     if (!value && advertisers.length) {
//       onSelect(advertisers[0].Id); // Pass only the ID if needed
//     }
//   }, [advertisers, value, onSelect]);
  
//   return (
//     <Dropdown
//       items={advertisers}
//       disabled={isLoading || disabled}
//       defaultValue={value}
//       className="advertiser-dropdown"
//       labelClassName="advertiser-background"
//       label={label}
//       valueKey="Id"
//       labelKey="companyName"
//       onSelect={onSelect}
//     />
//   );
// };

// AdvertiserDropdown.defaultProps = {
//   value: null,
//   label: null,
//   disabled: false,
// };

// AdvertiserDropdown.propTypes = {
//   value: PropTypes.string,
//   label: PropTypes.string,
//   disabled: PropTypes.bool,
//   onSelect: PropTypes.func.isRequired,
// };

// export default AdvertiserDropdown;
















// import React, { useEffect } from "react";
// import PropTypes from "prop-types";
// import Dropdown from "@client.core.components/Dropdown";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getAllAdvertisersAsync,
//   makeAdvertisers,
//   makeAdvertisersLoading,
// } from "@client.pages/Advertiser/reducer";
// import "./AdvertiserDropdown.scss";

// const AdvertiserDropdown = ({ value, onSelect, label, disabled }) => {
//   // console.log("From AdvertiserDropdown", value);
//   const dispatch = useDispatch();
//   const advertisers = useSelector(makeAdvertisers);
//   console.log("From AdvertiserDropdown", advertisers);
//   const isLoading = useSelector(makeAdvertisersLoading);

//   useEffect(() => {
//     dispatch(getAllAdvertisersAsync());
//   }, [dispatch]);

//   useEffect(() => {
//     if (!value && advertisers.length) {
//       onSelect(advertisers[0].Id); // Pass only the ID if needed
//     }
//   }, [advertisers, value, onSelect]);
  
//   return (
//     <Dropdown
//       items={advertisers}
//       disabled={isLoading || disabled}
//       defaultValue={value}
//       // defaultValue={setDefaultAdvertiser}
//       className="advertiser-dropdown"
//       labelClassName="advertiser-background"
//       label={label}
//       valueKey="Id"
//       labelKey="companyName"
//       onSelect={onSelect}
//     />
//   );
// };

// AdvertiserDropdown.defaultProps = {
//   value: null,
//   label: null,
//   disabled: false,
// };

// AdvertiserDropdown.propTypes = {
//   value: PropTypes.string,
//   label: PropTypes.string,
//   disabled: PropTypes.bool,
//   onSelect: PropTypes.func.isRequired,
// };

// export default AdvertiserDropdown;
