import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Tabs from "@client.components/Tabs";

export const AccountTabsEnum = Object.freeze({
  Details: 0,
  Wallet: 1,
  Devices: 2,
  Users: 3,
});

const AccountTabs = ({ onSelect }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(AccountTabsEnum.Details);

  const tabs = [
    {
      id: AccountTabsEnum.Details,
      name: t("pages.Accounts.details"),
      icon: "profile",
    },
    {
      id: AccountTabsEnum.Wallet,
      name: t("pages.Accounts.wallet"),
      icon: "billing",
    },
    {
      id: AccountTabsEnum.Devices,
      name: t("pages.Accounts.devices"),
      icon: "device",
    },
    {
      id: AccountTabsEnum.Users,
      name: t("pages.Accounts.users"),
      icon: "users",
    },
  ];
  return (
    <Tabs
      tabs={tabs}
      className="account-tabs"
      value={selectedTab}
      onSelect={(v) => {
        setSelectedTab(v);
        onSelect(v);
      }}
    />
  );
};

AccountTabs.propTypes = {
  onSelect: PropTypes.func,
};

export default AccountTabs;
