import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { SketchPicker } from "react-color";
import { VoidFunc } from "types";
import "./ColorPicker.scss";

const ColorPicker = ({
  hexColor,
  onChange,
}: {
  hexColor: string;
  onChange: VoidFunc<string>;
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState("#FFFFFF");

  useEffect(() => {
    if (hexColor) {
      setColor(hexColor);
    }
  }, [hexColor]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (newColor) => {
    onChange(newColor.hex);
    setColor(newColor.hex);
  };

  return (
    <div className="color-picker">
      <div className="swatch" onClick={handleClick}>
        <div
          className="color"
          style={{
            background: color,
          }}
        />
      </div>
      {displayColorPicker ? (
        <div className="popover">
          <div className="cover" onClick={handleClose} />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
};

ColorPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  hexColor: PropTypes.string,
};

export default ColorPicker;
