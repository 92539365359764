import React, { useEffect, useState } from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { makeSelectedAccount } from "../reducer";
import Input from "@client.core.components/Input";
import { useTranslation } from "react-i18next";
import Card from "./Card";
import Button from "@client.core.components/Button";
import GoogleMap from "@client.components/GoogleMap";
import Loader from "@client.core.components/Loader";
import ServiceProvider from "@client.services/provider";
import "./Details.scss";

const DetailsTab = () => {
  const selectedAccount = useSelector(makeSelectedAccount);
  console.log("From Details.js: ", selectedAccount);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [zones, setZones] = useState([]);

  useEffect(() => {
    setLoading(true);
    ServiceProvider.Zone.getAllByAccountId(selectedAccount.Id)
      .then((resp) => {
        setZones(get(resp, "data.zones.results", []));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedAccount]);

  return (
    <div className="account-details-tab">
      <div className="details-content">
        <Card
          title={t("pages.Accounts.tabs.Details.accountDetails")}
          className="account-details"
        >
          <Input
            value={selectedAccount.companyName}
            readOnly={true}
            label={t("pages.Accounts.tabs.Details.accountName")}
          />
          <Input
            value={selectedAccount.companyRegistrationNumber}
            readOnly={true}
            label={t("pages.Accounts.tabs.Details.companyNumber")}
          />
          <Input
            value={selectedAccount.companyVatNumber || "N/A"}
            readOnly={true}
            label={t("pages.Accounts.tabs.Details.vatNumber")}
          />
          <Input
            value={selectedAccount.description || "N/A"}
            readOnly={true}
            label={t("pages.Accounts.tabs.Details.description")}
          />
        </Card>
        <Card
          title={t("pages.Accounts.tabs.Details.planDetails")}
          className="plan-details"
        >
          <div className="plan-description">₪ 14,000 per month</div>
          <Button
            className="btn-upgrade"
            text={t("pages.Accounts.tabs.Details.upgrade")}
            onClick={() => {
              alert("Under construction");
            }}
          />
        </Card>
      </div>
      {loading ? (
        <div className="account-details-map">
          <Loader loading={true} />
        </div>
      ) : (
        <GoogleMap
          options={{
            fullscreenControl: false,
            mapTypeControl: false,
          }}
          polygons={zones.map((z) => z.polygon)}
          className="account-details-map"
        ></GoogleMap>
      )}
    </div>
  );
};

DetailsTab.propTypes = {
  accountId: PropTypes.string,
};

export default DetailsTab;
