import React from "react";
import PropTypes from "prop-types";
import Dropdown from "@client.core.components/Dropdown";
import { useTranslation } from "react-i18next";
import CustomerType from "@client.enums/customerType";
import { useSelector } from "react-redux";
import { makeProfile } from "@client.pages/Login/reducer";

const AccountTypeDropdown = ({ value, onSelect, label }) => {
  const { t } = useTranslation();
  const profile = useSelector(makeProfile); // This is the profile of the logged in user.. the role of the logged in user is used to determine the roles that can be assigned to a user
  const customerType = [
    {
      value: CustomerType.Reseller,
      label: t("components.Advertisers.customerType.reseller"),
    },
    {
      value: CustomerType.Advertiser,
      label: t("components.Advertisers.customerType.advertiser"),
    },
    {
      value: CustomerType.Partner,
      label: t("components.Advertisers.customerType.partner"),
    }
  ];

  // if (profile.role === Roles.Admin) {
  //   roles.unshift({
  //     value: Roles.Admin,
  //     label: t("components.Advertisers.roles.admin"),
  //   });
  // }

  return (
    <Dropdown
      items={customerType}
      defaultValue={value}
      label={label}
      valueKey="value"
      labelKey="label"
      onPrepareValue={(v) => parseInt(v)}
      onSelect={onSelect}
    />
  );
};

AccountTypeDropdown.defaultProps = {
  value: null,
  label: null,
};

AccountTypeDropdown.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default AccountTypeDropdown;
