import React, { useState } from "react";
import PropTypes from "prop-types";
import SvgIcon from "@client.core.components/SvgIcon";
import Input from "@client.core.components/Input";
import "./Password.scss";

export const Password = ({
  id,
  value,
  className,
  onChange,
  placeholder,
  label,
  onKeyDown,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="password-container">
      <Input
        className={className}
        type={showPassword ? "text" : "password"}
        value={value}
        label={label}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        optional={{
          id: id,
          autoComplete: "new-password",
        }}
      />
      <SvgIcon
        name={"eye-cross"}
        className={showPassword ? "" : "hide"}
        onClick={() => setShowPassword(!showPassword)}
      />
      <SvgIcon
        name={"eye"}
        className={showPassword ? "hide" : ""}
        onClick={() => setShowPassword(!showPassword)}
      />
    </div>
  );
};

Password.defaultProps = {
  value: "",
  label: "",
  onKeyDown: undefined,
};

Password.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};
