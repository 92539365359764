import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WizardSteps from "@client.pages/CreateCampaign/WizardSteps";
import GeneralInformation from "./Steps/GeneralInformation";
import Budget from "./Steps/Budget";
import Locations from "./Steps/Locations";
import CampaignModel from "@client.models/campaign";
import CampaignWizardStages from "@client.enums/campaignWizardStages";
import {
  getEstimationAsync,
  makeEstimation,
  getCampaignByIdAsync,
  makeSelectedCampaign,
} from "@client.pages/Campaign/reducer";
import useStableParams from "@client.hooks/useStableParams";
import { resetSelectedDraft } from "@client.pages/Advertiser/reducer";
import Loader from "@client.core.components/Loader";
import { useTranslation } from "react-i18next";

import CreateCampaignSummary from "./CreateCampaignSummary";
import "./CreateCampaign.scss";

const CreateCampaignPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { draftId } = useStableParams();

  const estimation = useSelector(makeEstimation);
  const selectedDraftCampaign = useSelector(makeSelectedCampaign);
  const [model, setModel] = useState(new CampaignModel());

  useEffect(() => {
    dispatch(getEstimationAsync());
  }, [dispatch]);

  useEffect(() => {
    if (draftId) {
      dispatch(getCampaignByIdAsync(draftId));
    }

    return () => {
      dispatch(resetSelectedDraft());
    };
  }, [dispatch, draftId]);

  useEffect(() => {
    setModel(new CampaignModel(selectedDraftCampaign.campaign));
  }, [selectedDraftCampaign]);

  if (draftId && (selectedDraftCampaign.loading || !selectedDraftCampaign.campaign)) {
    return (
      <div className="create-campaign not-found">
        <Loader loading={selectedDraftCampaign.loading} />
        {!selectedDraftCampaign.loading &&
          !selectedDraftCampaign.campaign &&
          t("pages.CreateCampaign.draftNotFound")}
      </div>
    );
  }

  return (
    <div className="create-campaign">
      <WizardSteps
        model={model}
        setModel={(m) => {setModel(m)}}
        steps={[
          <GeneralInformation model={model} setModel={setModel} key={CampaignWizardStages.Info} />,
          <Budget model={model} setModel={setModel} key={CampaignWizardStages.Budget} />,
          <Locations model={model} setModel={setModel} key={CampaignWizardStages.Location} />,
        ]}
      />
      {/* {console.log("From CreateCampaignPage.js", model)} */}
      <CreateCampaignSummary model={model} estimation={estimation} />
    </div>
  );
};

export default CreateCampaignPage;
