import React, { useState } from "react";
import PropTypes from "prop-types";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import classNames from "classnames";
import "./HierarchyNode.scss";
import Pages from "@client.enums/pages";
import { useNavigate } from "react-router-dom";
const HierarchyNode = ({
  node,
  onSelect,
  selectedNode,
  isRoot,
  labelField = "name",
  valueField = "id",
  // key,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSelect = () => {
    onSelect(node);
  };
  const navigate = useNavigate();
 
    return (
    <div className="node-container">
      <div
        className={classNames("node-header", {
          selected:
            selectedNode && selectedNode[valueField] === node[valueField],
          "root-node": isRoot,
        })}
        onClick={() => {
          // console.log("key", key);
          // console.log("node", node);
          setIsExpanded(!isExpanded);
        }}
        onDoubleClick={() => navigate(`${Pages.Advertiser}/${node.Id}`)}
      >
        {node.children && node.children.length > 0 && (
          <span className="expand-icon">
            {isExpanded ? <MdOutlineExpandLess /> : <MdOutlineExpandMore />}
          </span>
        )}
        <div className="name" onClick={handleSelect}>
          {node[labelField]}
        </div>
      </div>

      <div
        className={classNames("children-container", {
          expanded: isExpanded,
          collapsed: !isExpanded,
        })}
      >
        {node.children &&
          node.children.map((childNode) => (
            <HierarchyNode
              key={childNode[valueField]}
              node={childNode}
              onSelect={onSelect}
              selectedNode={selectedNode}
              labelField={labelField}
              valueField={valueField}
            />
          ))}
      </div>
    </div>
  );
};

HierarchyNode.propTypes = {
  node: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedNode: PropTypes.object,
  labelField: PropTypes.string,
  valueField: PropTypes.string,
  isRoot: PropTypes.bool,
  key: PropTypes.string,
};

export default HierarchyNode;
