import BaseModel from "./base";
import { get } from "lodash";

class BaseUserModel extends BaseModel {
  constructor(user = {}, msg = {}) {
    super(msg);
    this.firstName = get(user, "firstName", "");
    this.lastName = get(user, "lastName", "");
    this.emailAddress = get(user, "emailAddress", "");
    this.password = get(user, "password", "");
    this.role = get(user, "role", 1);
    this.phoneNumber = get(user, "phoneNumber", "");
    this.avatarUrl = get(user, "avatarUrl", "");
    this.createdAt = get(user, "createdAt", null); // Add createdAt
    this.lastLoginTime = get(user, "lastLoginTime", null); // Add lastLoginTime

    this.validationMsgs = msg;
    this.validationRules = {
      firstName: {
        presence: { allowEmpty: false, message: msg.firstName || "" },
      },
      lastName: {
        presence: { allowEmpty: false, message: msg.lastName || "" },
      },
      emailAddress: {
        presence: { allowEmpty: false, message: msg.emailAddress || "" },
        email: { message: msg.invalidEmail },
      },
      role: {
        presence: { allowEmpty: false, message: msg.role || "" },
      },
      phoneNumber: {
        presence: { allowEmpty: false, message: msg.phoneNumber || "" },
      },
      password: {
        presence: { allowEmpty: false, message: msg.password || "" },
        length: {
          minimum: 8,
          message: msg.passwordLength,
        },
      },
    };
  }

  get fields() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      emailAddress: this.emailAddress,
      password: this.password,
      role: this.role,
      phoneNumber: this.phoneNumber,
      avatarUrl: this.avatarUrl,
    };
  }
}

export default BaseUserModel;














// import BaseModel from "./base";
// import { get } from "lodash";

// class BaseUserModel extends BaseModel {
//   constructor(user = {}, msg = {}) {
//     super(msg);
//     this.firstName = get(user, "firstName", "");
//     this.lastName = get(user, "lastName", "");
//     this.emailAddress = get(user, "emailAddress", "");
//     this.password = get(user, "password", "");
//     this.role = get(user, "role", 1);
//     this.phoneNumber = get(user, "phoneNumber", "");
//     this.avatarUrl = get(user, "avatarUrl", "");

//     this.validationMsgs = msg;
//     this.validationRules = {
//       firstName: {
//         presence: { allowEmpty: false, message: msg.firstName || "" },
//       },
//       lastName: {
//         presence: { allowEmpty: false, message: msg.lastName || "" },
//       },
//       emailAddress: {
//         presence: { allowEmpty: false, message: msg.emailAddress || "" },
//         email: { message: msg.invalidEmail },
//       },
//       role: {
//         presence: { allowEmpty: false, message: msg.role || "" },
//       },
//       phoneNumber: {
//         presence: { allowEmpty: false, message: msg.phoneNumber || "" },
//       },
//       password: {
//         presence: { allowEmpty: false, message: msg.password || "" },
//         length: {
//           minimum: 8,
//           message: msg.passwordLength,
//         },
//       },
//     };
//   }

//   get fields() {
//     return {
//       firstName: this.firstName,
//       lastName: this.lastName,
//       emailAddress: this.emailAddress,
//       password: this.password,
//       role: this.role,
//       phoneNumber: this.phoneNumber,
//       avatarUrl: this.avatarUrl,
//     };
//   }
// }

// export default BaseUserModel;
