import DurationDayTypes from "@client.enums/durationDayTypes";
import { TOAST_EVENT } from "@client.core.components/Toast";
import ServiceProvider from "@client.services/provider";

const validateModel = (model, rules) => {
  const validation = model.validate(rules);
  const isModelValid = Object.keys(validation).length === 0;

  if (!isModelValid) {
    ServiceProvider.EventEmitter.emit(
      TOAST_EVENT,
      Object.keys(validation).map((m) => ({
        type: "warning",
        msg: validation[m][0],
      }))
    );
  }

  return isModelValid;
};

const getDurationDays = (t) => {
  return [
    {
      value: DurationDayTypes.AllWeek,
      label: t("pages.CreateCampaign.dartion.days.all"),
    },
    {
      value: DurationDayTypes.Monday,
      label: t("pages.CreateCampaign.dartion.days.monday"),
    },
    {
      value: DurationDayTypes.Tuesday,
      label: t("pages.CreateCampaign.dartion.days.tuesday"),
    },
    {
      value: DurationDayTypes.Wednesday,
      label: t("pages.CreateCampaign.dartion.days.wednesday"),
    },
    {
      value: DurationDayTypes.Thursday,
      label: t("pages.CreateCampaign.dartion.days.thursday"),
    },
    {
      value: DurationDayTypes.Friday,
      label: t("pages.CreateCampaign.dartion.days.friday"),
    },
    {
      value: DurationDayTypes.Saturday,
      label: t("pages.CreateCampaign.dartion.days.Saturday"),
    },
    {
      value: DurationDayTypes.Sunday,
      label: t("pages.CreateCampaign.dartion.days.Sunday"),
    },
  ];
};

const getDurationDay = (value, t) => {
  const durationDay = getDurationDays(t).find((d) => d.value === value);
  return durationDay ? durationDay.label : "";
};

export { validateModel, getDurationDays, getDurationDay };
