import React from "react";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
import SvgIcon from "@client.core.components/SvgIcon";
import { useTranslation } from "react-i18next";
import Button from "@client.core.components/Button";
import { NavigationSection } from "./NavigationSection";
import { useSelector } from "react-redux";
import "./NavigationBar.scss";
import CustomerType from "@client.enums/customerType";
import { makeProfileAccount } from "@client.pages/Login/reducer";

export const NavigationBar = () => {
  const profileAccount = useSelector(makeProfileAccount);
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Platform Sidebar Sections
  const platformSections = [
    {
      title: t("components.NavigationBar.dashboard"),
      items: [
        {
          icon: "dashboard",
          url: Pages.Dashboard,
          name: t("components.NavigationBar.overview"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.deviceOperations"),
      items: [
        {
          icon: "device",
          url: Pages.Devices,
          name: t("components.NavigationBar.devices"),
        },
        {
          icon: "locations",
          url: Pages.Zones,
          name: t("components.NavigationBar.locations"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.campaignManagement"),
      items: [
        {
          icon: "circle-check",
          url: Pages.CampaignApprovals,
          name: t("components.NavigationBar.campaignApprovals"),
        },
        {
          icon: "active-campaigns",
          url: Pages.Campaigns,
          name: t("components.NavigationBar.campaigns"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.userAndAccountManagement"),
      items: [
        {
          icon: "sitemap",
          url: Pages.Accounts,
          name: t("components.NavigationBar.accounts"),
        },
        {
          icon: "profile",
          url: Pages.Users,
          name: t("components.NavigationBar.Users"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.billingAndSubscription"),
      items: [
        {
          icon: "billing",
          url: Pages.Plans,
          name: t("components.NavigationBar.plans"),
        },
        {
          icon: "billing",
          url: Pages.Plans,
          name: t("components.NavigationBar.subscriptions"),
        },
        {
          icon: "billing",
          url: Pages.Plans,
          name: t("components.NavigationBar.invoices"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.logsAndActivity"),
      items: [
        {
          icon: "locations",
          url: Pages.UserLogs,
          name: t("components.NavigationBar.userLogs"),
        },
        {
          icon: "data-analyzer",
          url: Pages.SystemLogs,
          name: t("components.NavigationBar.systemLogs"),
        },
      ],
    },
  ];

  // Advertiser Sidebar Sections
  const advertiserSections = [
    {
      title: t("components.NavigationBar.dashboard"),
      items: [
        {
          icon: "dashboard",
          url: Pages.Dashboard,
          name: t("components.NavigationBar.overview"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.campaignManagement"),
      items: [
        {
          icon: "active-campaigns",
          url: Pages.Campaigns,
          name: t("components.NavigationBar.myCampaigns"),
        },
        {
          icon: "media",
          url: Pages.MediaGallery,
          name: t("components.NavigationBar.mediaGallery"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.userManagement"),
      items: [
        {
          icon: "profile",
          url: Pages.Users,
          name: t("components.NavigationBar.myUsers"),
        },
        {
          icon: "activity",
          url: Pages.UserActivity,
          name: t("components.NavigationBar.userActivity"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.analyticsAndReporting"),
      items: [
        {
          icon: "reports",
          url: Pages.Reports,
          name: t("components.NavigationBar.reports"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.helpAndSupport"),
      items: [
        {
          icon: "faqs",
          url: Pages.FAQs,
          name: t("components.NavigationBar.faqs"),
        },
        {
          icon: "tickets",
          url: Pages.SupportTickets,
          name: t("components.NavigationBar.supportTickets"),
        },
        {
          icon: "contact-support",
          url: Pages.ContactSupport,
          name: t("components.NavigationBar.contactSupport"),
        },
      ],
    },
  ];

  return (
    <div className="navigation-bar" data-testid="NavigationBar">
      <div className="nav-top-bar">
        <div className="logo-container">
          <SvgIcon
            name="logo"
            className="logo"
            onClick={() => {
              navigate(Pages.Dashboard);
            }}
          />
        </div>
        <Button
          icon="plus"
          text={t("components.NavigationBar.createCampaign")}
          onClick={() => {
            navigate(Pages.CreateCampaign);
          }}
        />
      </div>

      {/* Platform Sidebar */}
      {profileAccount && profileAccount.entity === CustomerType.Platform && (
        // <div className="nav-main-content">
        <div className="nav-main-content">
          {platformSections.map((section) => (
            <NavigationSection
              key={section.title}
              title={section.title}
              items={section.items}
            />
          ))}
        </div>
      )}

      {/* Advertiser Sidebar */}
      {profileAccount && profileAccount.entity === CustomerType.Advertiser && (
        <div className="nav-main-content">
          {advertiserSections.map((section) => (
            <NavigationSection
              key={section.title}
              title={section.title}
              items={section.items}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default NavigationBar;
