import CampaignWizardStages from "@client.enums/campaignWizardStages";
import BaseModel from "./base";
import { get } from "lodash";
import BudgetLimit from "@client.enums/budgetLimit";
import CampaignStatuses from "@client.enums/campaignStatuses";
import LocationTypes from "@client.enums/locationTypes";
import config from "@client.config";
import moment from "moment";

class CampaignModel extends BaseModel {
  constructor(model = {}, msg) {
    super(msg);

    // Initialize model properties with default values if not provided
    this.campaignName = get(model, "campaignName", "");
    this.Id = get(model, "Id", null);
    this.target = get(model, "target", null);
    this.advertiserId = get(model, "advertiserId", "");

    this.budgetType = get(model, "budgetType", 1);
    this.paymentId = get(model, "paymentId", "");
    this.isDraft = get(model, "isDraft", false);
    
    this.budgetLimit = get(model, "budgetLimit", BudgetLimit.Total);
    this.budgetAmount = get(model, "budgetAmount", 300);
    this.startDate = get(model, "startDate", moment(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS"));
    this.endDate = get(model, "endDate", moment(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS"));
    this.locationType = get(model, "locationType", LocationTypes.Zones);
    this.zone = get(model, "zone", { Id: null, zoneName: "", latitude: null, longitude: null, radius: null });
    // this.lookupId = get(model, "lookupId", null);
    // this.file = get(model, "file", { id: "null", external_id: "" });  // Default values to avoid undefined errors
    // this.mediaId = get(model, "mediaId", "defaultMediaId");
    this.mediaId = get(model, "mediaId", "defaultMediaId");
    this.validationMsgs = msg;
    this.validationRules = {};
  }

  isStageFilled = (stage) => {
    switch (stage) {
      case CampaignWizardStages.Info: {
        const infoFilled = !!this.campaignName && !!this.target;
        // console.log("Info stage filled:", infoFilled, "displayName:", this.campaignName, "target:", this.target);
        return infoFilled;
      }
      case CampaignWizardStages.Budget: {
        const budgetFilled = this.isStageFilled(CampaignWizardStages.Info);
        // console.log("Budget stage filled:", budgetFilled);
        return budgetFilled;
      }
      case CampaignWizardStages.Location: {
        const locationFilled = this.isStageFilled(CampaignWizardStages.Budget) && this.zone && this.zone.Id;
        // console.log("Location stage filled:", locationFilled, "zone:", this.zone);
        // console.log("Zone Id:", this.zone?.Id);
        // console.log("Zone Name:", this.zone?.zoneName);
        // console.log("Zone validity:", !!this.zone?.Id && !!this.zone?.zoneName);
        return locationFilled;
      }
      default:
        return false;
    }
  };

  isValid = () => {
    const stagesFilled = [
      CampaignWizardStages.Info,
      CampaignWizardStages.Budget,
      CampaignWizardStages.Location,
    ].every((s) => this.isStageFilled(s));

    // console.log("Stages filled status:", stagesFilled);
    return stagesFilled;
  };

  // get mediaId() {
  //   return this.file?.external_id || "defaultMediaId";  // Default value if lookupId is missing
  // }

  get fields() {
    return {
      Id: this.Id,
      campaignName: this.campaignName,
      startDate: this.startDate,
      endDate: this.endDate,
      mediaId: `${config.downloadUrl}/${this.mediaId}`,
      currentBalance: 1500,

      budgetType: 1,
      paymentId: "",
      isDraft: false,
      
      oud: "xxxxxx",
      status: CampaignStatuses,
      geofenceZoneIds: this.zone?.Id ? [this.zone.Id] : [],
      budgetAmount: this.budgetAmount,  
      budgetLimit: this.budgetLimit,    
      advertiserId: this.advertiserId,
      target: parseInt(this.target),  
      // target: this.target,
      geofenceType: 1,
      geofenceRadiusLatitude: this.zone?.latitude || null,
      geofenceRadiusLongitude: this.zone?.longitude || null,
      geofenceRadiusRange: this.zone?.radius || null,
    };
  }
  
}

export default CampaignModel;












