import React, { forwardRef, useState, useImperativeHandle } from "react";
import Input from "@client.core.components/Input";
import { validateModel } from "@client.utils/form";
import BaseSystemLogModel from "@client.models/BaseSystemLogModel";
import ActionDropdown from "@client.components/ActionDropdown";
import LogTypeDropdown from "./components/LogTypeDropdown";
import "./SystemLogForm.scss";

const SystemLogForm = forwardRef((props, ref) => {
  const [model, setModel] = useState(
    new BaseSystemLogModel(
      {},
      {
        description: "Description is required",
        entity: "Entity is required",
        type: "Type is required",
      }
    )
  );

  // Ensure you are exposing the ref correctly
  useImperativeHandle(ref, () => ({
    validate: () => {
      const isValid = validateModel(model, model.validationRules);
      return isValid;
    },
    logData: model,
  }));

  const handleChange = (value, field) => {
    setModel(
      new BaseSystemLogModel({ ...model, [field]: value }, model.validationMsgs)
    );
  };

  return (
    <div className="create-advertiser-modal" ref={ref}>
      <label>Entity</label>
      <LogTypeDropdown
        value={model.entity}
        onSelect={(val) => handleChange(val, "entity")}
      />
      <label>Action Type</label>
      <ActionDropdown
        value={model.type}
        onSelect={(val) => handleChange(val, "type")}
      />
      <Input
        id="description"
        label="Description"
        value={model.description}
        onChange={(e) => handleChange(e, "description")}
      />
    </div>
  );
});

SystemLogForm.displayName = "SystemLogForm";

export default SystemLogForm;
