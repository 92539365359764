import BaseModel from "./base";
import { get } from "lodash";

class LocationModel extends BaseModel {
  constructor(location = {}, msg) {
    super(msg);

    this.lookupId = get(location, "lookupId", "");
    this.displayName = get(location, "displayName", "");
    this.pricePerView = get(location, "pricePerView", "");
    this.color = get(location, "color", "#ff6c40");
    this.polygon = [];

    this.validationMsgs = msg;
    this.validationRules = {
      displayName: {
        presence: { allowEmpty: false, message: msg.displayName },
      },
      pricePerView: {
        presence: { allowEmpty: false, message: msg.ppv },
      },
      polygon: {
        presence: { allowEmpty: false, message: msg.polygon },
      },
      color: {
        presence: { allowEmpty: false, message: msg.color },
      },
    };
  }

  get fields() {
    return {
      displayName: this.displayName,

      pricePerView: parseFloat(this.pricePerView),
      polygon: JSON.stringify(this.polygon),
    };
  }
}

export default LocationModel;
