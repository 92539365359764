import React, { useEffect } from "react";
import Loader from "@client.core.components/Loader";
import { useAuth } from "@client.hooks/useAuth";
import ServiceProvider from "@client.services/provider";
import LocalStorageKeys from "@client.enums/localStorageKeys";

const Logout = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
    ServiceProvider.LocalStorage.removeItem(LocalStorageKeys.Token);
  }, [logout]);

  return <Loader loading={true} />;
};

export default Logout;
