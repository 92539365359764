import React from "react";
import classNames from "classnames";
import "./Icon.scss";

const Icon = ({
  name,
  className,
  onClick,
  ...rest
}: {
  name: string;
  className?: string;
  onClick?: VoidFunction;
}) => {
  return (
    <span
      className={classNames(`icon-${name}`, className)}
      onClick={onClick}
      {...rest}
    ></span>
  );
};

export default Icon;
