const enum CampaignStatuses {
  All = "null",
  Draft=0,
  PendingApproval= 1,
  Queued= 2,
  Active= 3,
  Paused= 4,
  Archive= 5,
  Ended= 6,
}

export default CampaignStatuses;
