import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "@client.core.components/Button";
import UsersForm from "./UsersForm";
import Input from "@client.core.components/Input";
import Title from "@client.components/Title";
import DateTimeRangePicker from "@client.components/DateTimeRangePicker/DateTimeRangePicker";
import ServiceProvider from "@client.services/provider";
import { MODAL_EVENT } from "@client.components/Modal";
import { getGraphqlResponseError } from "@client.utils/error";
import { createUserAsync, getAllUsersAsync, makeUsers } from "./reducer";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
import NewDataTable from "@client.components/NewDataTable/NewDataTable";
import moment from "moment";
import "./Users.scss";

const ITEMS_PER_PAGE = 10;

const UsersPage = () => {
  const { t } = useTranslation();
  const users = useSelector(makeUsers);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState({
    query: "",
    startDate: "",
    endDate: "",
    role: "",
  });

  useEffect(() => {
    const offset = (currentPage - 1) * ITEMS_PER_PAGE;
    dispatch(
      getAllUsersAsync({
        ...filter,
        offset,
        limit: ITEMS_PER_PAGE,
        search: filter.query,
        searchOn: "email_address,first_name,last_name",
        startDate: filter.startDate,
        endDate: filter.endDate,
      })
    );
  }, [filter, currentPage, dispatch]);

  const onEditClick = useCallback(
    (id) => {
      navigate(`${Pages.ProfileGeneralEditUser}/${id}`);
    },
    [navigate]
  );
  
  const onEditAdvertiserClick = useCallback(
    (id) => {
      navigate(`${Pages.ProfileGeneral}/${id}`);
    },
    [navigate]
  );

  const createTxt = t("pages.Users.createNewUser");

  return (
    <div className="users page">
      <Title text="components.NavigationBar.Users">
        {/* Create New User Button */}
        <Button
          icon="plus"
          className="button-create"
          text={createTxt}
          onClick={() => {
            ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
              show: true,
              withBodyRef: true,
              title: createTxt,
              component: <UsersForm />,
              buttonSettings: {
                text: t("components.Advertisers.create"),
                onClick: async (component) => {
                  const isValid = component.validate();

                  return isValid
                    ? dispatch(
                      createUserAsync({
                        model: component.managementItem,
                        filter,
                      })
                    ).then((resp) => {
                      const error = getGraphqlResponseError(resp);
                      if (!error) {
                        ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
                          show: false,
                        });
                      }
                    })
                    : Promise.resolve();
                },
              },
            });
          }}
        />
      </Title>
      <div className="search">
        <Input
          label="Search"
          value={filter.query}
          onChange={(e) => {
            setFilter({
              ...filter,
              query: e,
            });
          }}
        />
        <DateTimeRangePicker
          dateFrom={filter.startDate}
          dateTo={filter.endDate}
          showTimeSelect={false}
          captionStart="Start Date"
          captionEnd="End Date"
          onChange={(startDate, endDate) => {
            setFilter({
              ...filter,
              startDate: startDate
                ? moment(startDate).format("YYYY-MM-DD")
                : "",
              endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
            });
          }}
        />
      </div>
      <div className="content">
        <NewDataTable
          data={users.results}
          isLoading={users.loading}
          pagination={{
            total: users.count,
            itemsPerPage: ITEMS_PER_PAGE,
            currentPage,
            onChange: setCurrentPage,
          }}
          onEditAdvertiserClick={onEditAdvertiserClick}
          onEditClick={onEditClick}
        />
      </div>
    </div>
  );
};

export default UsersPage;
