import React from "react";
import PropTypes from "prop-types";
import Dropdown from "@client.core.components/Dropdown";
import LogType from "@client.enums/logType";
import { useTranslation } from "react-i18next";

const LogTypeDropdown = ({ value, onSelect, label }) => {
  const { t } = useTranslation();
  const entities = [
    {
      value: LogType.Device,
      label: t("systemLogs.goToPage.Device"),
    },
    {
      value: LogType.User,
      label: t("systemLogs.goToPage.User"),
    },
    {
      value: LogType.Accounts,
      label: t("systemLogs.goToPage.Accounts"),
    },
  ];

  return (
    <Dropdown
      items={entities}
      defaultValue={value}
      label={label}
      valueKey="value"
      labelKey="label"
      onPrepareValue={(v) => parseInt(v)}
      onSelect={onSelect}
    />
  );
};

LogTypeDropdown.defaultProps = {
  value: null,
  label: null,
};

LogTypeDropdown.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default LogTypeDropdown;
