import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SvgIcon from "@client.core.components/SvgIcon";
import Loader from "@client.core.components/Loader";
import {
  makeLoginLoading,
  processForgotPasswordAsync,
} from "@client.pages/Login/reducer";
import Button from "@client.core.components/Button";
import Input from "@client.core.components/Input";
import ForgotPasswordModel from "models/forgotPassword";
import { NavLink } from "react-router-dom";
import Pages from "@client.enums/pages";
import "./ForgotPassword.scss";
import WelcomeCard from "@client.components/WelcomeCard/WelcomeCard";

const ForgotPasswordPage = () => {
  const loginLoading = useSelector(makeLoginLoading);
  const { t } = useTranslation();
  const [model, setModel] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setModel(
      new ForgotPasswordModel(
        {
          login: "",
          emailAddress: "",
        },
        {
          emailAddress: t("pages.ForgotPassword.validation.emailAddress"),
        }
      )
    );
  }, [t]);

  return (
    <div className="forgot-password">
      <div className="forgot-password-panel">
        <WelcomeCard>
          <div className="welcome">
            <div>{t("Adport")}</div>
            <div className="welcome-description">
              {t("pages.ForgotPassword.button.welcomeDescription")}
            </div>
          </div>
        </WelcomeCard>
        <div className="forgot-password-container">
          <SvgIcon name="logo" className="logo" />
          <div className="forgot-password-form">
            <div className="title">{t("pages.ForgotPassword.title")}</div>
            <div className="subtitle">{t("pages.ForgotPassword.subTitle")}</div>
            <Input
              id="forgot-password-email"
              placeholder={t("pages.ForgotPassword.button.resetPassword")}
              value={model.emailAddress}
              onChange={(v) =>
                setModel(
                  new ForgotPasswordModel(
                    { ...model, emial: v },
                    model.validationMsgs
                  )
                )
              }
            />
            <div className="btn">
              {!loginLoading && (
                <Button
                  text={t("pages.ForgotPassword.button.resetPassword")}
                  onClick={() => {
                    const validate = model.validate();

                    if (validate.length > 0) {
                      dispatch(processForgotPasswordAsync(model));
                    }
                  }}
                />
              )}
              {loginLoading && <Loader loading={true} />}
              <div className="back">
                <NavLink to={Pages.Login}>
                  {t("pages.Login.forgotPassword")}
                </NavLink>
              </div>
            </div>
          </div>
          <div className="forgot-password-footer"></div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
