import ZoneType from "@client.enums/zoneType";
import BaseService from "@client.services/baseService";

const ZONES = [
  {
    lookupId: "1",
    name: "Israel",
    type: ZoneType.Country,
    ppvRate: 35,
    primeRate: 17,
    currency: "₪",
    polygon: [
      { lat: 31.5, lng: 34.5 },
      { lat: 33.5, lng: 34.5 },
      { lat: 33.5, lng: 35.5 },
      { lat: 31.5, lng: 35.5 },
    ],
    items: [
      {
        lookupId: "11",
        name: "Tel Aviv District",
        type: ZoneType.Minicipality,
        ppvRate: 40,
        primeRate: 19,
        currency: "₪",
        parentId: "1",
        polygon: [
          { lat: 32, lng: 34.7 },
          { lat: 32.2, lng: 34.7 },
          { lat: 32.2, lng: 34.9 },
          { lat: 32, lng: 34.9 },
        ],
        items: [
          {
            lookupId: "111",
            name: "Tel Aviv",
            type: ZoneType.City,
            ppvRate: 45,
            primeRate: 20,
            currency: "₪",
            parentId: "11",
            polygon: [
              { lat: 32.05, lng: 34.75 },
              { lat: 32.15, lng: 34.75 },
              { lat: 32.15, lng: 34.85 },
              { lat: 32.05, lng: 34.85 },
            ],
          },
          {
            lookupId: "112",
            name: "Ramat Gan",
            type: ZoneType.City,
            ppvRate: 42,
            primeRate: 19.5,
            currency: "₪",
            parentId: "11",
            polygon: [
              { lat: 32.05, lng: 34.8 },
              { lat: 32.15, lng: 34.8 },
              { lat: 32.15, lng: 34.9 },
              { lat: 32.05, lng: 34.9 },
            ],
          },
        ],
      },
      {
        lookupId: "12",
        name: "Jerusalim District",
        type: ZoneType.Minicipality,
        ppvRate: 38,
        primeRate: 18,
        currency: "₪",
        parentId: "1",
        polygon: [
          { lat: 31.7, lng: 35.1 },
          { lat: 31.9, lng: 35.1 },
          { lat: 31.9, lng: 35.3 },
          { lat: 31.7, lng: 35.3 },
        ],
        items: [
          {
            lookupId: "121",
            name: "Jerusalim",
            type: ZoneType.City,
            ppvRate: 43,
            primeRate: 19.5,
            currency: "₪",
            parentId: "12",
            polygon: [
              { lat: 31.75, lng: 35.15 },
              { lat: 31.85, lng: 35.15 },
              { lat: 31.85, lng: 35.25 },
              { lat: 31.75, lng: 35.25 },
            ],
          },
        ],
      },
    ],
  },
  {
    lookupId: "5",
    name: "My Custom Zone",
    type: ZoneType.Custom,
    ppvRate: 35,
    primeRate: 17,
    currency: "₪",
    polygon: [
      { lat: 32.07578087285677, lng: 34.76404745470793 },
      { lat: 32.06123404964905, lng: 34.799066375606365 },
      { lat: 32.010593072924266, lng: 34.77572042834074 },
      { lat: 32.020490637683224, lng: 34.738641570918865 },
    ],
  },
];

export default class ZoneService extends BaseService {
  async getAllByAccountId(accountId) {
    const response = await super.post("", {
      query: `
        query (
          $accountLookupId: String
        ) {
          zones(accountId: $accountLookupId) {
            results {
              color
              polygon
              primePrice
              updatedAt
              zoneName
              parentZoneId
              ppvPrice
              isActive
              createdAt
              category
              Id
            }
          }
        }
      `,
      variables: {
        accountLookupId: accountId || "",
      },
    });

    return response;
  }

  async getAll() {
    return super.post("", {
      query: `query{
        zones{
          count
          results{
            Id
            zoneName
            category
            polygon
            color
            ppvPrice
            primePrice
            parentZoneId
          }
        }
      }`,
    });
  }

  async getById(id) {
    return super.post("", {
      query: `
      query($id: String){
  	  zone(id: $id){
        Id
        zoneName
        category
        polygon
        color
        ppvPrice
        primePrice
        parentZoneId
        }
      }`,
      variables: {
        id,
      },
    });
  }
  
  async create(model) {
    return super.post("", {
      query: `
        mutation($fields: ZoneInputType){
          createZone(fields: $fields){
            message
            zone{
              zoneName
              category
              polygon
              ppvPrice
              primePrice
              parentZoneId
              polygon
            }
          }
        }
      `,
      variables: {
        fields: model.fields,
      },
    });
  }

  async update(model) {
    return super.post("", {
      query: `mutation($id: String, $fields: ZoneInputType){
        updateZone(id: $id, fields: $fields){
          message
          zone{
            Id
            zoneName
            category
            polygon
            color
            ppvPrice
            primePrice
          }
        }
      }`,
      variables: {
        fields: {
          ...model.fields,
        },
        id: model.Id,
      },
    });
  }

  async delete(id) {
    return super.post("", {
      query: `mutation($id: String){
        deleteZone(id: $id)
      }`,
      variables: {
        id,
      },
    });
  }

  getLsZones() {
    const test = localStorage.getItem("zones");
    return test ? JSON.parse(test) : JSON.parse(JSON.stringify(ZONES));
  }
  async getZones() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: {
            fetchZones: this.getLsZones(),
          },
        });
      }, 500);
    });
  }

  async createZone(model) {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.updateZoneStub(model, this.getLsZones(), 0);
        resolve({});
      }, 500);
    });
  }

  async updateZone(model) {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.updateZoneStub(model, this.getLsZones(), 0);
        resolve({});
      }, 500);
    });
  }

  async deleteZone(id) {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.deleteZoneStub(id, this.getLsZones(), 0);
        resolve({});
      }, 500);
    });
  }

  deleteZoneStub(lookupId, items, lvl) {
    const newZones = items
      .map((node) => {
        if (node.items) {
          node.items = this.deleteZoneStub(lookupId, node.items, lvl + 1);
        }
        return node;
      })
      .filter((node) => node.lookupId !== lookupId);

    if (lvl === 0) localStorage.setItem("zones", JSON.stringify(newZones));

    return newZones;
  }

  updateZoneStub(model, items, lvl) {
    if (model.type === ZoneType.Country && !model.lookupId) {
      items.push({
        ...model.fields,
        lookupId: `stub ${new Date().getTime().toString()}`,
      });
    } else {
      for (let item of items) {
        if (item.lookupId === model.lookupId) {
          item.name = model.name;
          item.ppvRate = model.ppvRate;
          item.primeRate = model.primeRate;
          item.currency = model.currency;
        } else if (item.lookupId === model.parentId && !model.lookupId) {
          const newItems = item.items ? [...item.items] : [];
          newItems.push({
            ...model.fields,
            lookupId: `stub ${new Date().getTime().toString()}`,
          });
          item.items = newItems;
        } else if (item.items && item.items.length) {
          this.updateZoneStub(model, item.items, lvl + 1);
        }
      }
    }

    if (lvl === 0) {
      localStorage.setItem("zones", JSON.stringify(items));
    }
  }

  async getPolygon(zone) {
    const results = await this.api(
      `https://nominatim.openstreetmap.org/search.php?q=${zone}&polygon_geojson=1&format=jsonv2`,
      {},
      true
    );
    // console.log("Received polygon datasssssssssss:", results)
    const result = results.length > 0 ? results[0] : null;
    // console.log("Received polygon data:", Array.isArray(result.geojson.coordinates[0]))
    // console.log("Received polygon data:", result.geojson.coordinates[0].length>0 ? result.geojson.coordinates[0] : [] );


    
    // console.log("Received polygon datasssssssssss:", results[0])
    if (!Array.isArray(result.geojson.coordinates[0])&&result.geojson.coordinates[0] !== undefined) { // [lat,lan] ~ I.E Yenchim Garden Restaurant, Black Mountain Road, San Diego, CA, USA
      // return [result.geojson.coordinates[0]];
      // console.log("Received polygon datasssssssssss1:", [result.geojson.coordinates])
      return([result.geojson.coordinates])
    }
    // else if (Array.isArray(result.geojson.coordinates[0])&&result.geojson.coordinates[0].length==1) {
      // else if (Array.isArray(result.geojson.coordinates[0])) {
        else if (Array.isArray(result.geojson.coordinates)&&result.geojson.coordinates.length>2&&!Array.isArray(result.geojson.coordinates[0][0])){ //[[lat,lan],[lat,lan]] ~ East 14th Street, New York, NY, USA
      // console.log("Received polygon datasssssssssss2222:",result.geojson.coordinates.length) 
      // console.log("Received polygon datasssssssssss2:", result.geojson.coordinates)
      // console.log("Received polygon datasssssssssss2.1:",!Array.isArray(result.geojson.coordinates[0][0]))
      return result.geojson.coordinates;
    }

    else if (Array.isArray(result.geojson.coordinates[0][0])&&result.geojson.coordinates[0][0].length==2) {//[[[lat,lan],[lat,lan]]] ~ I.E Uganda
      // console.log("Received polygon datasssssssssss3:", result.geojson.coordinates)
      // console.log("Received polygon datasssssssssss4:", result.geojson.coordinates[0])
      // console.log("Received polygon datasssssssssss5:", result.geojson.coordinates[0].length)
      // console.log("Received polygon datasssssssssss6:", result.geojson.coordinates[0][0])
      // console.log("Received polygon datasssssssssss7:", result.geojson.coordinates[0][0].length)
      return result.geojson.coordinates[0];
    }
    else {//[[[[lat,lan],[lat,lan]]]] ~ I.E Denmark
      // console.log("Received polygon datasssssssssss8:", result.geojson.coordinates)
      // console.log("Received polygon datasssssssssss9:", result.geojson.coordinates[0])
      // console.log("Received polygon datasssssssssss10:", result.geojson.coordinates[0].length)
      // console.log("Received polygon datasssssssssss11:", result.geojson.coordinates[0][0])
      // console.log("Received polygon datasssssssssss12:", result.geojson.coordinates[0][0].length)
      return result.geojson.coordinates[0][0];
    }
    
    // return result && result.geojson ? result.geojson.coordinates[0] || [] : [];

    // const result = results.length > 0 ? results[0] : null;
    // return result && result.geojson && Array.isArray(result.geojson.coordinates[0]) ? result.geojson.coordinates[0] || [] : [];

    // console.log("Received polygon data:", Array.isArray(result.geojson.coordinates[0]));
    //  console.log("Received polygon data:", result.geojson.coordinates);
    //  return result && result.geojson && Array.isArray(result.geojson.coordinates[0]) ? result.geojson.coordinates[0]  : [result.geojson.coordinates];
  }
}








