import React, { useEffect, useState } from "react";
import { isNull } from "lodash";
import PropTypes from "prop-types";
import classNames from "classnames";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import "./Dropdown.scss";
import Icon from "@client.core.components/Icon";

const DropDownComponent = ({
  id,
  error,
  items,
  valueKey,
  labelKey,
  descriptionKey,
  defaultValue,
  onSelect,
  onPrepareValue,
  renderCustomContent,
  placeholder,
  disabled,
  label,
  payment,
  labelClassName,
  nameData,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);
  const renderTextWithDots = (text) => {
    if (!text) return "";
    const visiblePart = text.slice(-4);
    const hiddenPart = text.slice(0, -4).replace(/./g, "●");

    // Add space every 4 characters
    const addSpaces = (str) => str.match(/.{1,4}/g).join(" ");

    return addSpaces(hiddenPart) + " " + visiblePart;
  };
  const renderOverlay = () => (
    <Menu
      onSelect={({ key }) => {
        const value = onPrepareValue ? onPrepareValue(key) : key;
        setSelectedValue(value);
        if (onSelect) {
          onSelect(
            value,
            items.find((i) => i[valueKey] === value)
          );
        }
      }}
      className="dropdown-menu-rc"
    >
      {items.map((item) =>
        payment ? (
          <MenuItem key={item[valueKey]} className={"paymentMethod"}>
            {renderTextWithDots(item[labelKey])}
            {descriptionKey && item[descriptionKey] && (
              <span className="payment">{item[descriptionKey]}</span>
            )}
          </MenuItem>
        ) : (
          <MenuItem
            key={item[valueKey]}
            className={classNames({ "with-description": !!descriptionKey })}
          >
            {item[labelKey]}
            {descriptionKey && item[descriptionKey] && (
              <span className="description">{item[descriptionKey]}</span>
            )}
          </MenuItem>
        )
      )}
    </Menu>
  );

  const selectedItem =
    items && items.find((i) => i[valueKey] === selectedValue);
  return (
    <div className="dropdown-container">
      {label && (
        <span className={classNames(`label`, labelClassName)}>{label}</span>
      )}
      <Dropdown
        trigger={["click"]}
        overlay={renderOverlay}
        animation="slide-up"
      >
        {renderCustomContent ? (
          renderCustomContent()
        ) : (
          <button
            disabled={disabled}
            id={id}
            className={classNames("dropdown", { "is-invalid": !isNull(error) })}
          >
            {selectedItem === undefined ? (
              <span
                className={
                  nameData ? "placeholder placeholder-black" : "placeholder"
                }
              >
                {placeholder}
              </span>
            ) : payment ? (
              renderTextWithDots(selectedItem[labelKey])
            ) : (
              selectedItem[labelKey]
            )}
            <Icon name="chevron" />
          </button>
        )}
      </Dropdown>
    </div>
  );
};
DropDownComponent.defaultProps = {
  error: null,
  disabled: false,
};

DropDownComponent.propTypes = {
  id: PropTypes.string,
  labelClassName: PropTypes.string,
  error: PropTypes.array,
  label: PropTypes.string,
  items: PropTypes.array.isRequired,
  valueKey: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  descriptionKey: PropTypes.string,
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  onPrepareValue: PropTypes.func,
  renderCustomContent: PropTypes.func,
  disabled: PropTypes.bool,
  payment: PropTypes.bool,
  nameData: PropTypes.bool,
};

export default DropDownComponent;
