import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import HeatMap from "./HeatMap";
import CampaignStatistic from "./CampaignStatistic";
import StatisticChart from "./StatisticChart";
import useStableParams from "@client.hooks/useStableParams";
import Ads from "@client.components/Ads";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignMonitoringAsync,
  makeCampaignMonitor,
  fetchCampaignPlacesAsync,
  makeSelectedCampaignPlaces,
  getCampaignHeatmapAsync,
  makeCampaignHeatmap,
} from "./reducer";
import Loader from "@client.core.components/Loader";
import Select from "react-select";
import { getAllDevicesAsync } from "@client.pages/Devices/reducer";

export const dropdownStyles = {
  container: (provided) => ({
    ...provided,
    display: "inline-block",
    width: "auto",
    textAlign: "right",
  }),
  control: (provided) => ({
    ...provided,
    border: "1px #d9d9d9",
    borderRadius: "15px",
    boxShadow: "none",
    padding: "4px",
    fontSize: "14px",
    fontWeight: "500",
    backgroundColor: "#f5f5f5", // Matches Fugma background
    color: "#000", // Text color
    "&:hover": {
      borderColor: "#1890ff", // Hover border color
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#000", // Matches the Fugma arrow indicator color
    "&:hover": {
      color: "#40a9ff", // Slightly lighter hover effect
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#000", // Ensure text is clearly visible
  }),
  menu: (provided) => ({
    ...provided,
    width: "auto", // Adjust this value as needed
    border: "1px solid #d9d9d9",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 10,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#e6f7ff" : "#fff", // Focused background
    color: state.isFocused ? "#1890ff" : "#333", // Focused text
    fontWeight: '400',
    fontSize: '14px',
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#bae7ff", // Hover background color
    },
  }),
  input: (provided) => ({
    ...provided,
    color: '#000',
  })
};

const Overview = ({ campaign }) => {
  const dateFrom = campaign.startDate;
  const dateTo = campaign.endDate;

  const { t } = useTranslation();
  const { id } = useStableParams();
  const dispatch = useDispatch();

  const statistic = useSelector(makeCampaignMonitor);
  const campaignPlaces = useSelector(makeSelectedCampaignPlaces);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedHeatMap, setSelectedHeatMap] = useState(null);

  //  const statistic = {
  //   "data": {
  //     "fetchCampaignMonitoring": {
  //       "campaign": {
  //         "Id": "12345",
  //         "campaignName": "Sample Campaign"
  //       },
  //       "totalPlays": 5000,
  //       "totalViews": 4500,
  //       "totalSpent": 1200.5,
  //       "graphData": [
  //         {
  //           "date": "2024-11-01",
  //           "plays": 1000,
  //           "views": 950,
  //           "spent": 200.0
  //         },
  //         {
  //           "date": "2024-11-02",
  //           "plays": 1200,
  //           "views": 1100,
  //           "spent": 250.0
  //         },
  //         {
  //           "date": "2024-11-03",
  //           "plays": 900,
  //           "views": 850,
  //           "spent": 180.5
  //         },
  //         {
  //           "date": "2024-11-04",
  //           "plays": 1100,
  //           "views": 1000,
  //           "spent": 300.0
  //         },
  //         {
  //           "date": "2024-11-05",
  //           "plays": 800,
  //           "views": 750,
  //           "spent": 270.0
  //         }
  //       ]
  //     }
  //   }
  // }

  useEffect(() => {
    if (campaign && campaign.startDate && campaign.endDate) {
      dispatch(
        getCampaignMonitoringAsync({
          id: campaign.Id,
          startDate: campaign.startDate,
          endDate: campaign.endDate,
        })
      );
    }
  }, [dispatch, campaign]);

  useEffect(() => {
    const id = campaign.Id;
    const deviceId = ""; // Replace with actual deviceId
    dispatch(fetchCampaignPlacesAsync({ id, deviceId }));
  }, [dispatch]);

  const selectedCampaignHeatmap = useSelector(makeCampaignHeatmap);
  useEffect(() => {
    const id = campaign.Id;
    const zoneId = "";
    dispatch(getCampaignHeatmapAsync({ id, zoneId }));
  }, [dispatch]);

  const deviceMap = useMemo(
    () =>
      campaignPlaces.results.map((item) => ({
        Id: item.deviceId,
        deviceName: item.deviceName,
        latitude: item.latitude,
        longitude: item.longitude,
      })),
    [campaignPlaces.results]
  );
  
  const heatMapData = useMemo(
    () =>
      selectedCampaignHeatmap.heatmap.map((item) => ({
        Id: item.zoneId,
        zoneName: item.name,
        latitude: item.latitude,
        longitude: item.longitude,
      })),
    [selectedCampaignHeatmap.heatmap]
  );

  // const deviceMap = [
  //   {
  //     Id: "fake_lookup_id_19696",
  //     // deviceName: "Device 45432",
  //     deviceName: "Copenhagen, Denmark",
  //     latitude: 55.6761, // Copenhagen, Denmark
  //     longitude: 12.5683,
  //   },
  //   {
  //     Id: "fake_lookup_id_83822",
  //     // deviceName: "Device 88776",
  //     deviceName: "Hong Kong",
  //     latitude: 22.3193, // Hong Kong
  //     longitude: 114.1694,
  //   },
  //   {
  //     Id: "fake_lookup_id_13084",
  //     // deviceName: "Device 67812",
  //     deviceName: "New York, USA",
  //     latitude: 40.7128, // New York, USA
  //     longitude: -74.006,
  //   },
  // ];

  // const devices = useSelector(makeDevices);

  useEffect(() => {
    dispatch(getAllDevicesAsync());
  }, [dispatch]);

  const currentDate = new Date();
  const [endDate, setEndDate] = useState(false);

  // WTF?
  useEffect(() => {
    const endDate = new Date(campaign.endDate);

    const isEndDatePast = endDate < currentDate;
    setEndDate(isEndDatePast);
  }, [campaign, currentDate]);

  if (!statistic || statistic.loading) {
    return (
      <div>
        <Loader loading={true} />
      </div>
    );
  }

  const devicesOptions = deviceMap.map((item) => ({
    value: item.Id,
    label: item.deviceName,
  }));

  const heatMapOptions = heatMapData.map((item) => ({
    value: item.Id,
    label: item.zoneName,
  }));

  return (
    //3 square boxes
    <div className="campaign-statistic">
      <CampaignStatistic
        campaign={statistic}
        dateFrom={dateFrom}
        dateTo={dateTo}
        campaignId={id}
      />
      {/* graph data */}
      <StatisticChart
        statistic={statistic}
        campaignId={id}
        dateFrom={dateFrom}
        dateTo={dateTo}
      />

      {/* <PerformanceTable campaignId={id} /> */}

      {/* {!endDate && ( */}
      <>
        <div
          className="campaign-header d-flex justify-content-between"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "30px",
          }}
        >
          <div> {t("pages.campaign.ads")} </div>
          <Select
            styles={dropdownStyles}
            options={devicesOptions}
            placeholder="Select a device"
            onChange={(selectedOption) => {
              if (selectedOption) {
                setSelectedDevices(
                  deviceMap.filter((device) => device.Id === selectedOption.value)
                );
              } else {
                setSelectedDevices([]);
              }
            }}
          />
        </div>
        <Ads
          campaignId={id}
          deviceMap={selectedDevices.length ? selectedDevices : deviceMap}
        />

        {/* <Ads campaignId={id} deviceMap={deviceMap}/> */}
      </>
      {/* )} */}
      {/* {endDate && ( */}
      <>
        <div
          className="campaign-header d-flex justify-content-between "
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
          <div> {t("pages.Campaign.heatmap")} </div>
          <Select
            options={heatMapOptions}
            styles={dropdownStyles}
            placeholder="Select a city"
            onChange={(selectedOption) => {
              if (selectedOption) {
                // Retrieve selected heat map data
                const selectedHeatmapData = heatMapData.find(
                  (heatMapObject) => heatMapObject.Id === selectedOption.value
                );

                // Debugging to ensure the correct item is selected
                // console.log("Selected Option Value:", selectedOption.value);
                // console.log("HeatMap Data:", heatMapData);
                // console.log("Selected Heatmap Data:", selectedHeatmapData);

                // Update the state with the selected heat map data or null
                setSelectedHeatMap(selectedHeatmapData || null);
              } else {
                setSelectedHeatMap(null);
              }
            }}
          />
        </div>

        <HeatMap
          campaignId={id}
          selectedHeatMap={selectedHeatMap ? [selectedHeatMap] : heatMapData}
        />

        {/* <HeatMap campaignId={id} /> */}
        {/* <Ads campaignId={id} deviceMap={deviceMap}/> */}
      </>
      {/* )} */}

      {/* <div className="campaign-header">{t("pages.Campaign.heatmap")}</div>
      <HeatMap campaignId={id} dateFrom={dateFrom} dateTo={dateTo} /> */}
    </div>
  );
};

Overview.propTypes = {
  campaignId: PropTypes.string,
  campaign: PropTypes.any,
};

export default Overview;
