import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "@client.services/provider";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";

const DEFAULT_VPN_SERVERS = { results: [], loading: false, count: 0 };

const initialState = {
  servers: DEFAULT_VPN_SERVERS,
  error: null,
  loading: false,
  filter: {
    name: "",
    ipAddress: "",
    location: "",
  },
};

export const getAllVpnServersAsync = createAsyncThunk(
  "vpn/getAll",
  async (_query, thunkAPI) => {
    const state = thunkAPI.getState();
    const filter = makeVpnFilter(state);
    const response = await ServiceProvider.Vpn.getAll(true, filter);

    return response;
  }
);

export const createVpnAsync = createAsyncThunk(
  "advertiser/create",
  async (model, thunkAPI) => {
    const resp = await ServiceProvider.Vpn.create(model);

    if (!resp.errors) {
      thunkAPI.dispatch(getAllVpnServersAsync());
    }
    return resp;
  }
);

export const vpnSlice = createSlice({
  name: "vpn",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = get(action, "payload", false);
    },
    clearDeviceError: (state) => {
      state.error = null;
    },
    setFilter: (state, action) => {
      state.filter = get(action, "payload", false);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllVpnServersAsync.pending, (state) => {
        state.servers.results = [];
        state.servers.count = 0;
        state.servers.loading = true;
      })
      .addCase(getAllVpnServersAsync.fulfilled, (state, action) => {
        const response = get(
          action,
          "payload.data.vpnServers",
          DEFAULT_VPN_SERVERS.results
        );
        state.servers = response;
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAllVpnServersAsync.rejected, (state, action) => {
        state.servers = DEFAULT_VPN_SERVERS;
        state.servers.loading = false;
        state.error = getResponseError(action);
      })
      .addCase(createVpnAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createVpnAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = getGraphqlResponseError(action);
      })
      .addCase(createVpnAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = getResponseError(action);
      });
  },
});

export const { clearDeviceError, setLoading, setFilter } = vpnSlice.actions;

export const makeVpnFilter = (state) => state.vpnManagement.filter;
export const makeVpnServers = (state) => state.vpnManagement.servers;
export const makeVpnError = (state) => state.vpnManagement.error;
export const makeVpnLoading = (state) => state.vpnManagement.loading;

export default vpnSlice.reducer;
