import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";
import ServiceProvider from "@client.services/provider";
import { ISoftwareVersion } from "./types";

const initialState = {
  items: [],
  error: null,
  loading: false,
};

export const getAllSoftwareVersionsAsync = createAsyncThunk(
  "softwareVersions/getAll",
  async () => {
    return await ServiceProvider.SoftwareVersion.getAll();
  }
);

export const createSoftwareVersionAsync = createAsyncThunk(
  "softwareVersions/create",
  async (model: ISoftwareVersion) => {
    return ServiceProvider.SoftwareVersion.create(model);
  }
);

export const updateSoftwareVersionAsync = createAsyncThunk(
  "softwareVersions/update",
  async (model: ISoftwareVersion) => {
    return await ServiceProvider.SoftwareVersion.update(model);
  }
);

export const deleteSoftwareVersionAsync = createAsyncThunk(
  "softwareVersions/delete",
  async (id: string) => {
    return await ServiceProvider.SoftwareVersion.delete(id);
  }
);

export const softwareVersionsSlice = createSlice({
  name: "softwareVersions",
  initialState,
  reducers: {
    clearSoftwareVersionsError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSoftwareVersionsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSoftwareVersionsAsync.fulfilled, (state, action) => {
        state.items = get(action, "payload.data.softwareVersions.results", []);
        state.error = getGraphqlResponseError(action);
        state.loading = false;
      })
      .addCase(getAllSoftwareVersionsAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
        state.loading = false;
      })
      .addCase(createSoftwareVersionAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSoftwareVersionAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(createSoftwareVersionAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(updateSoftwareVersionAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSoftwareVersionAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
        state.loading = false;
      })
      .addCase(updateSoftwareVersionAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
        state.loading = false;
      })
      .addCase(deleteSoftwareVersionAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSoftwareVersionAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
        state.loading = false;
      })
      .addCase(deleteSoftwareVersionAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
        state.loading = false;
      });
  },
});

export const { clearSoftwareVersionsError } = softwareVersionsSlice.actions;

export const makeSoftwareVersionsError = (state) =>
  state.softwareVersions.error;
export const makeSoftwareVersions = (state) => state.softwareVersions.items;
export const makeSoftwareVersionsLoading = (state) =>
  state.softwareVersions.loading;

export default softwareVersionsSlice.reducer;
