import NoCreditCard from "@client.components/NoCreditCard";
import ProfileTabs from "@client.components/ProfileTabs";
import Title from "@client.components/Title";
import useStableParams from "@client.hooks/useStableParams";
import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";

const ProfilePaymentPage = () => {
  const CreditCard = () => {
    const [fields, setFields] = useState({
      cardnumber: "",
      cardholder: "",
      exp: "",
      cvc: "",
    });
    const [valid, setValid] = useState(false);

    const formatCardNumber = (number) => {
      return number
        .replace(/[^0-9]/gi, "")
        .slice(0, 16)
        .replace(/(.{4})/g, "$1 ")
        .trim();
    };

    const formatExp = (number) => {
      return number
        .replace(/[^0-9]/gi, "")
        .slice(0, 4)
        .replace(/(.{2})/, "$1/")
        .trim();
    };

    const validate = () => {
      let isValid = true;
      for (const value in fields) {
        if (fields[value].length < 1) {
          isValid = false;
          break;
        }
      }
      setValid(isValid);
    };

    const handleInputChange = (event, field) => {
      const { value } = event.target;
      let updatedFields = { ...fields, [field]: value };
      if (field === "cardnumber") {
        updatedFields.cardnumber = formatCardNumber(value);
      } else if (field === "exp") {
        updatedFields.exp = formatExp(value);
      }
      setFields(updatedFields);
      validate();
    };

    return (
      <div className="cc">
        <div className="cc__card">
          <div>
            <div>{selectedCard}</div>
            <FontAwesomeIcon icon={faCreditCard} />
          </div>
          <div className="cc__cardgroup cc__cardgroup--wide">
            <div className="cc__card-label">Card Number</div>
            <div className="cc__card-value cc__card-value--large">
              {fields.cardnumber}
            </div>
          </div>
          <div className="cc__cardgroup">
            <div className="cc__card-label">Cardholder</div>
            <div className="cc__card-value">{fields.cardholder}</div>
          </div>
          <div className="cc__cardgroup">
            <div className="cc__card-label">Expires</div>
            <div className="cc__card-value">{fields.exp}</div>
          </div>
          <div className="cc__cardgroup">
            <div className="cc__card-label">CVC</div>
            <div className="cc__card-value">{fields.cvc}</div>
          </div>
        </div>
        <form
          className="cc__form"
          onSubmit={(e) => e.preventDefault()}
          onKeyUp={validate}
        >
          <fieldset>
            <legend>Payment Details</legend>
            <div className="fieldgroup">
              <label htmlFor="card-number">Card Number</label>
              <input
                id="card-number"
                type="text"
                tabIndex="1"
                value={fields.cardnumber}
                onChange={(e) => handleInputChange(e, "cardnumber")}
              />
            </div>
            <div className="fieldgroup">
              <label htmlFor="cardholder">Cardholder</label>
              <input
                id="cardholder"
                type="text"
                tabIndex="2"
                value={fields.cardholder}
                onChange={(e) => handleInputChange(e, "cardholder")}
              />
            </div>
            <div className="fieldgroup fieldgroup--half">
              <label htmlFor="card-exp">Expires</label>
              <input
                id="card-exp"
                type="text"
                placeholder="MM/YY"
                tabIndex="3"
                value={fields.exp}
                onChange={(e) => handleInputChange(e, "exp")}
              />
            </div>
            <div className="fieldgroup fieldgroup--half">
              <label htmlFor="card-cvc">CVC</label>
              <input
                id="card-cvc"
                type="text"
                tabIndex="4"
                maxLength={3}
                value={fields.cvc}
                onChange={(e) => handleInputChange(e, "cvc")}
              />
            </div>
            <input
              type="submit"
              onClick={() => console.log(fields)}
              value="Add Card"
              tabIndex="5"
              disabled={!valid}
            />
          </fieldset>
        </form>
      </div>
    );
  };

  const { profileId } = useStableParams();
  const [selectedCard, setSelectedCard] = useState(null);
  const handleCardClick = (cardType) => {
    setSelectedCard(cardType);
  };
  return (
    <div className="profile-billing page">
      <Title text="pages.ProfileGeneral.myAccount" />
      <ProfileTabs profileId={profileId} />
      <>
        {selectedCard && (
          <div className="overlay">
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0, y: -900 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -100 }}
                transition={{ duration: 0.5 }}
                className="credit-container"
              >
                <span
                  className="close-btn"
                  onClick={() => setSelectedCard(null)}
                >
                  X
                </span>

                <CreditCard />
              </motion.div>
            </AnimatePresence>
          </div>
        )}
        <div className="d-flex">
          <NoCreditCard
            description="Add Primary Card"
            onclick={() => handleCardClick("primary")}
          />
          <NoCreditCard
            description="Add Secondary Card"
            onclick={() => handleCardClick("secondary")}
          />
        </div>
      </>
    </div>
  );
};

export default ProfilePaymentPage;
