import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SvgIcon from "@client.core.components/SvgIcon";
import SectionDescription from "@client.pages/CreateCampaign/SectionDescription";
import Button from "@client.core.components/Button";
import { useDispatch, useSelector } from "react-redux";
import { makeProfile } from "@client.pages/Login/reducer";
import Pages from "@client.enums/pages";
import {
  getCampaignDurationTimeSlotsAsync,
  getCampaignLocationsAsync,
  makeCampaignLoading,
  makeSelectedCampaign,
  saveCampaignAsync,
} from "@client.pages/Campaign/reducer";

import classNames from "classnames";
import Loader from "@client.core.components/Loader";
import MediaFile from "@client.components/MediaFile";
import useToast from "@client.hooks/useToast";
import Dropdown from "@client.core.components/Dropdown";
import "./CampaignInfo.scss";
import moment from "moment";
import config from "@client.config";
import { updateAdvertiserCampaignStatusAsync } from "@client.pages/Advertiser/reducer";
import {
  getLocationByIdAsync,
  makeLocations,
} from "@client.pages/Zones/reducer";
import CampaignProgressBar from "../CampaignProgressBar/CampaignProgressBar";

import CampaignStatuses from "@client.enums/campaignStatuses";

const PaymentProps = Object.freeze({
  4654654654654654: "4654654654654654",
  8419887487352765: "8419887487352765",
});
const CREDIT_CARD_DATASOURCE = Object.keys(PaymentProps).map((k) => ({
  id: parseInt(k),
  name: PaymentProps[k],
}));

const CampaignInfo = ({ model, estimation, approve = false ,infoData=null}) => {
  // console.log("From CampaignInfo.js approve: ", approve);
  // console.log("From CampaignInfo.js222222 campaignInfo: ", infoData);
  // console.log("From CampaignInfo.js: ", model);
  const { t } = useTranslation();
  const { showToastAfterRequest } = useToast();
  const [creditCard, setCreditCard] = useState(CREDIT_CARD_DATASOURCE[0].id);
  const profile = useSelector(makeProfile);
  const selectedCampaign = useSelector(makeSelectedCampaign);
  const isCampaignLoading = useSelector(makeCampaignLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentDate = new Date();
  const [endDate, setEndDate] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [daysLeft, setDaysLeft] = useState();
  const [daysAgo, setDaysAgo] = useState();
  const [locationNames, setLocationNames] = useState([]);
  const [reason, setReason] = useState(""); // Textbox state for reason
  const source = estimation
    ? `${config.downloadUrl}/${model.mediaId}`
    : model.mediaId;

  useEffect(() => {
    const fetchLocationNames = async () => {
      if (Array.isArray(model.geofenceZoneIds)) {
        // Check if geofenceZoneIds is defined and is an array
        const names = await Promise.all(
          model.geofenceZoneIds.map(async (id) => {
            const result = await dispatch(getLocationByIdAsync(id));
            return result.payload?.data?.zone?.zoneName || "Unknown"; // Adjust based on actual response structure
          })
        );
        setLocationNames(names);
      } else {
        setLocationNames([]);
      }
    };

    fetchLocationNames();
  }, [dispatch, model.geofenceZoneIds]);

  // useEffect(() => {
  //   if (!estimation) {
  //     dispatch(getCampaignDurationTimeSlotsAsync());
  //     dispatch(getCampaignLocationsAsync());

  //     if (selectedCampaign.loading) {
  //       return <Loader loading={selectedCampaign.loading} />;
  //     }
  //   }
  // }, [dispatch, estimation]);

  useEffect(() => {
    if (!estimation) {
      const startDate = new Date(model.startDate);
      const endDate = new Date(model.endDate);
      const daysLeft = Math.floor(
        (endDate - currentDate) / (1000 * 60 * 60 * 24)
      );
      const daysAgo = Math.floor(
        (currentDate - endDate) / (1000 * 60 * 60 * 24)
      );

      // Check if the endDate is in the past relative to the current date
      const isStartDateStarted = startDate > currentDate;
      const isEndDatePast = endDate < currentDate;
      setDaysLeft(daysLeft);
      setDaysAgo(daysAgo);
      setEndDate(isEndDatePast);
      setStartDate(isStartDateStarted);
    }
  }, [currentDate, estimation]);

  // const price = estimation ? estimation.price : model.totalSpent;
  const price = estimation ? estimation.price : 0;
  const plays = estimation ? estimation.plays : model.totalViews;
  const pricePerView = price === 0 || plays === 0 ? 0 : price / plays;

  const renderPaymentSection = () => {
    if (isCampaignLoading) {
      return <Loader loading={true} />;
    }
    return (
      <>

        <SectionDescription title="pages.CreateCampaign.paymentMethod" />

        <Dropdown
          items={CREDIT_CARD_DATASOURCE}
          valueKey="id"
          payment={true}
          labelClassName="credit-card"
          defaultValue={creditCard}
          onPrepareValue={(v) => parseInt(v)}
          labelKey="name"
          placeholder="●●●● ●●●● ●●●● ●●●●"
          label={t("pages.CreateCampaign.creditCards")}
          onSelect={setCreditCard}
        />

        <Button
          text={t("pages.CreateCampaign.launchCampaign")}
          disabled={creditCard === null || !model.isValid()}
          onClick={async () => {
            // console.log("model.userId: ", model);
            if (!model.userId) {
              model.userId = profile.Id;
            }
            const resp = await dispatch(saveCampaignAsync(model));
            showToastAfterRequest(
              resp,
              "pages.CreateCampaign.successCampaignCreation"
            ).then(() => {
              // navigate(`${Pages.Advertiser}/${model.userId}`);
              navigate(Pages.Campaigns);
            });
          }}
        />
      </>
    );
  };

  const handleApprove = () => {
    const updatedCampaign = { ...model, status: CampaignStatuses.Queued };

    dispatch(
      updateAdvertiserCampaignStatusAsync({
        campaign: updatedCampaign,
        filters: { status: CampaignStatuses.PendingApproval },
      })
    )
      .then(() => {
        alert(t("pages.CampaignApproval.successApprove"));
        window.location.reload(); // Reload the page after approval
      })
      .catch((err) => {
        // console.error("Approval failed: ", err);
        alert(t("pages.CampaignApproval.errorApprove"));
      });
  };

  const handleDeny = () => {
    // Define behavior for denying the campaign
    alert("Campaign denied.");
    window.location.reload(); // Reload the page after denying
  };

  const getCampaignStatusName = (status) => {
    console.log('status')
    console.log(status)
    switch (status) {
      case CampaignStatuses.PendingApproval:
        return t("pages.Campaign.status.pending");
      case CampaignStatuses.Queued:
        return t("pages.Campaign.status.queued");
      case CampaignStatuses.Active:
        return t("pages.Campaign.status.active");
      case CampaignStatuses.Ended:
        return t("pages.Campaign.status.ended");
      case CampaignStatuses.Draft:
        return t("pages.Campaign.status.draft");
      case CampaignStatuses.Archive:
        return t("pages.Campaign.status.archived");
      case CampaignStatuses.Paused:
        return t("pages.Campaign.status.paused");
      default:
        return t("pages.Campaign.status.unknown");
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case CampaignStatuses.PendingApproval:
        return "pending";
      case CampaignStatuses.Queued:
        return "queued";
      case CampaignStatuses.Active:
        return "live";
      case CampaignStatuses.Ended:
        return "ended";
      case CampaignStatuses.Draft:
        return "draft";
      case CampaignStatuses.Archive:
        return "archived";
      case CampaignStatuses.Paused:
        return "paused";
      default:
        return "unknown";
    }
  };

  const renderEndDate = () => {
    const today = new Date();
    const endDateObject = new Date(model.endDate);
    const endDateIsInTheFuture = endDateObject > today;
    
    if (model.status === CampaignStatuses.Archive && endDateIsInTheFuture) {
      return false;
    }

    if (startDate && endDate) {
      return <span>Ended {daysAgo} days ago</span>
    }

    return <span>Ends in {daysLeft} days</span>
  }

  return (
    <div className="campaign-info">
      {!estimation && <div className="name">{model.campaignName}</div>}
      {!estimation && (
        <div
          className="d-flex justify-content-between"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {/* <span
            className={`status ${
              startDate ? "pending" : endDate ? "ended" : "live"
            }`}
          > */}
          <span className={`status ${getStatusClass(model.status)}`}>
            {/* {startDate ? "Pending" : endDate ? "End" : "Live"} */}
            {getCampaignStatusName(model.status)}
          </span>
          {renderEndDate()}
        </div>
      )}
      <div className={classNames("bag", { "bag-with-name": !estimation })}>
        <div style={{ height: 256, width: 291, overflow: "hidden" }}>
          <div className="placeholder">
            <div className="thumb-img">
              <MediaFile
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover", // Ensures the image fills the div while maintaining aspect ratio
                }}
                key={model?.mediaId}
                // src={`${config.downloadUrl}/${model.mediaId}`}
                // src={`${model.mediaId}`}
                src={source}
                // src={model?.mediaId}
                className="thumb-img"
                fallbackElement={() => <SvgIcon name="file-not-found" />}
              />
            </div>
          </div>
        </div>
      </div>

      {estimation && (
        <SectionDescription title="pages.CreateCampaign.costEstimetor" />
      )}

      {model.status === CampaignStatuses.Active && (
        <div className="payment-details">
          {/* <div className="section-payment">
          <div className="price">{plays?.toLocaleString()}</div>
          <div className="description">
            {t("pages.CreateCampaign.totalPlays")}
          </div>
        </div> */}
          <div className="section-payment">
            <div className="price">
              {profile.currency} {infoData.peopleReached}
            </div>
            <div className="description">
              {t("pages.CreateCampaign.peopleReached")}
            </div>
          </div>
          <div className="section-payment">
            <div className="price">
              {profile.currency} {infoData.pricePerSlot}
            </div>
            <div className="description">
              {t("pages.CreateCampaign.pricePerSlot")}
            </div>
            {/* <div className="description">{t("pages.CreateCampaign.Spent")}</div> */}
          </div>
          <div className="section-payment no-border">
            <div className="price">
              {profile.currency} {infoData.budgetSpent}
            </div>
            {/* <div className="description">{t("pages.CreateCampaign.total")}</div> */}
            <div className="description">
              {t("pages.CreateCampaign.budgetSpent")}
            </div>
          </div>
        </div>
      )}

      {model.status === CampaignStatuses.PendingApproval && (
        <div className="payment-details">
          {/* <div className="section-payment">
          <div className="price">{plays?.toLocaleString()}</div>
          <div className="description">
            {t("pages.CreateCampaign.totalPlays")}
          </div>
        </div> */}
          <div className="section-payment">
            <div className="price">
              {profile.currency} {pricePerView?.toLocaleString()}
            </div>
            <div className="description">
              {t("pages.CreateCampaign.budget")}
            </div>
          </div>
          <div className="section-payment no-border">
            <div className="price">
              {profile.currency} {pricePerView?.toLocaleString()}
            </div>
            <div className="description">
              {t("pages.CreateCampaign.estimationPricePerSlot")}
            </div>
            {/* <div className="description">{t("pages.CreateCampaign.Spent")}</div> */}
          </div>
        </div>
      )}

      {model.status === CampaignStatuses.Ended && (
        <div className="payment-details">
          {/* <div className="section-payment">
          <div className="price">{plays?.toLocaleString()}</div>
          <div className="description">
            {t("pages.CreateCampaign.totalPlays")}
          </div>
        </div> */}
          <div className="section-payment">
            <div className="price">
              {profile.currency} {pricePerView?.toLocaleString()}
            </div>
            <div className="description">
              {t("pages.CreateCampaign.budget")}
            </div>
          </div>
          <div className="section-payment">
            <div className="price">
              {profile.currency} {pricePerView?.toLocaleString()}
            </div>
            <div className="description">
              {t("pages.CreateCampaign.pricePerPlay")}
            </div>
            {/* <div className="description">{t("pages.CreateCampaign.Spent")}</div> */}
          </div>
          <div className="section-payment no-border">
            <div className="price">
              {profile.currency} {price?.toLocaleString()}
            </div>
            {/* <div className="description">{t("pages.CreateCampaign.total")}</div> */}
            <div className="description">
              {t("pages.CreateCampaign.pricePerDay")}
            </div>
          </div>
        </div>
      )}

      {!estimation ? (
        <div className="area">
          <div>
            {t("pages.Campaign.Overview.duration")}
            <div className="d-flex flex-row justify-content-between">
              <span className="description">
                {moment(model.startDate).format(
                  config.dateTimeSecondsFormatReversed
                )}
              </span>
              <span className="description">Until</span>
              <span className="description">
                {moment(model.endDate).format(
                  config.dateTimeSecondsFormatReversed
                )}
              </span>
            </div>
          </div>

          {!approve && (
            <div>
              <CampaignProgressBar
                startDate={model.startDate}
                endDate={model.endDate}
              />
            </div>
          )}

          <div>
            {t("pages.Campaign.Overview.locations")}
            {locationNames.length > 0 ? (
              locationNames.map((name, index) => (
                <span key={index} className="description">
                  {name}
                </span>
              ))
            ) : (
              <span className="description">No Locations</span>
            )}
          </div>
        </div>
      ) : (
        renderPaymentSection()
      )}

      {approve && (
        <div className="approval-section">
          <textarea
            className="reason-textbox"
            placeholder={t("pages.CampaignApproval.enterReason")}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
          <div className="approval-buttons">
            <Button
              text={t("pages.CampaignApproval.deny")}
              className="small-button deny alternative"
              onClick={handleDeny}
            />
            <Button
              text={t("pages.CampaignApproval.approve")}
              className="small-button button"
              onClick={handleApprove}
            />
          </div>
        </div>
      )}

      {/* {estimation ? renderPaymentSection() : renderCampaignInfo()} */}
    </div>
  );
};

CampaignInfo.propTypes = {
  model: PropTypes.any,
  estimation: PropTypes.any,
  approve: PropTypes.any,
  infoData: PropTypes.any,
};

export default CampaignInfo;
