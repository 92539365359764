import { ISoftwareVersion } from "@client.features/analytics-tools/types";
import BaseService from "@client.services/baseService";
import { AllItemsResponse, ManagementResponse } from "types";

export default class SoftwareVersionService extends BaseService {
  async getAll(): Promise<AllItemsResponse<ISoftwareVersion>> {
    return super.post("", {
      query: `query{
        softwareVersions{
          count
          results{
            Id
            deviceType
            versionType
            versionNumber
            downloadLink
            totalDownloads
            releaseDate
            releaseNotes
          }
        }
      }`,
    });
  }

  async create(version: ISoftwareVersion): Promise<ManagementResponse> {
    return super.post("", {
      query: `mutation($fields: SoftwareVersionInputType){
        createSoftwareVersion(fields: $fields){
          message
          softwareVersion{
            Id
            deviceType
            versionType
            versionNumber
            downloadLink
            totalDownloads
            releaseDate
            releaseNotes
          }
        }
      }`,
      variables: {
        fields: version,
      },
    });
  }

  async update(version: ISoftwareVersion): Promise<ManagementResponse> {
    const { Id: id, ...fields } = version;
    return super.post("", {
      query: `mutation($id: String, $fields: SoftwareVersionInputType){
        updateSoftwareVersion(id: $id, fields: $fields){
          message
          softwareVersion{
            Id
            deviceType
            versionType
            versionNumber
            downloadLink
            totalDownloads
            releaseDate
            releaseNotes
          }
        }
      }`,
      variables: {
        id,
        fields,
      },
    });
  }

  async delete(id: string): Promise<ManagementResponse> {
    return super.post("", {
      query: `mutation($id: String){
        deleteSoftwareVersion(id: $id) {
          message
        }
      }`,
      variables: {
        id,
      },
    });
  }
}
