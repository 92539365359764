const enum Roles {
  Admin = 1,
  Manager = 2,
  Employee = 3,
  Technician = 4,
  User = 5,
}

export default Roles;

