import React, { forwardRef, useCallback, useState } from "react";
import Input from "@client.core.components/Input";
import { useTranslation } from "react-i18next";
import RoleDropdown from "@client.components/RoleDropdown";
import Password from "@client.components/Password";
import { validateModel } from "@client.utils/form";
import BaseUserModel from "@client.models/baseUser";
import "./UsersForm.scss";

const UsersForm = forwardRef((_, ref) => {
  const { t } = useTranslation();
  const [model, setModel] = useState(
    new BaseUserModel(
      {},
      {
        firstName: t("components.Advertisers.validation.firstName"),
        lastName: t("components.Advertisers.validation.lastName"),
        emailAddress: t("components.Advertisers.validation.emailAddress"),
        role: t("components.Advertisers.validation.role"),
        password: t("components.Advertisers.validation.password"),
        phoneNumber: t("components.Advertisers.validation.phoneNumber"),
        companyName: t("components.Advertisers.validation.companyName"),
        invalidEmail: t("components.Advertisers.validation.invalidEmail"),
        passwordLength: t("components.Advertisers.validation.passwordLength"),
      }
    )
  );

  React.useImperativeHandle(ref, () => ({
    managementItem: model,
    validate: () => {
      const isValid = validateModel(model, model.validationRules);
      return isValid;
    },
  }));

  const changeModel = useCallback(
    (v, field) => {
      setModel(
        new BaseUserModel({ ...model, [field]: v }, model.validationMsgs)
      );
    },
    [model]
  );

  return (
    <div className="create-advertiser-modal" ref={ref}>
      <Input
        label={t("pages.Users.columns.firstName")}
        value={model.firstName}
        onChange={(v) => changeModel(v, "firstName")}
      />
      <Input
        label={t("pages.Users.columns.lastName")}
        value={model.lastName}
        onChange={(v) => changeModel(v, "lastName")}
      />
      <Password
        id="pswrd"
        value={model.password}
        label={t("pages.Users.columns.password")}
        onChange={(v) => {
          changeModel(v, "password");
        }}
      />
      <Input
        value={model.emailAddress}
        onChange={(v) => changeModel(v, "emailAddress")}
        label={t("pages.Users.columns.email")}
      />
      <Input
        type="tel"
        value={model.phoneNumber}
        onChange={(v) => {
          changeModel(v, "phoneNumber");
        }}
        label={t("pages.Users.columns.phone")}
        maxLength={15}
      />
      <RoleDropdown
        value={model.role}
        label={t("pages.Users.columns.role")}
        onSelect={(val) => {
          changeModel(val, "role");
        }}
      />
    </div>
  );
});

UsersForm.displayName = "UsersForm";

export default UsersForm;
