import React from "react";
import PropTypes from "prop-types";
import "./PendingMessage.scss";
import { faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const PendingMessage = ({ campaign }) => {
  const { t } = useTranslation();
  // console.log("From PendingMessage.js: ", campaign);
  return (
    <div className="pending-message-content">
      <FontAwesomeIcon size={"5x"} icon={faHourglassHalf} />
      <div>{t("pages.Campaign.PendingMessage")}</div>
      <div className="pending-details">
        {t("pages.Campaign.PendingDetails", {
          name: campaign?.campaignName || t("pages.Campaign.DefaultName"),
        })}
      </div>
    </div>
  );
};

PendingMessage.propTypes = {
  campaign: PropTypes.any,
};

export default PendingMessage;
