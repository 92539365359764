const LS_PREFIX = "rw";

class LocalStorageService {
  setItem = (key: string, value: string) => {
    const fullKey = this.getKey(key);
    window.localStorage.setItem(fullKey, JSON.stringify(value));
  };

  getItem = (key: string, defaultValue: any = null) => {
    const fullKey = this.getKey(key);
    const item = window.localStorage.getItem(fullKey);

    return item ? JSON.parse(item) : defaultValue;
  };

  removeItem = (key: string) => {
    const fullKey = this.getKey(key);
    window.localStorage.removeItem(fullKey);
  };

  getKey = (key: string) => {
    return `${LS_PREFIX}:${key}`;
  };
}

export default LocalStorageService;
