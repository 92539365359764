import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "@client.services/provider";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";

const DEFAULT_USERS = { results: [], count: 0, loading: false };

const initialState = {
  users: DEFAULT_USERS,
  error: null,
  loading: false,
  selectedUser: {
    user: null,
    loading: false,
  },
};

export const getAllUsersAsync = createAsyncThunk(
  "users/getAll",
  async (filter) => {
    return await ServiceProvider.User.getAll(filter);
  }
);

//////////////////
export const getUserByIdAsync = createAsyncThunk(
  "users/getById",
  async (userId, thunkAPI) => {
    thunkAPI.dispatch(setSelectedUserLoading(true));
    try {
      return await ServiceProvider.User.getById(userId);
    } finally {
      thunkAPI.dispatch(setSelectedUserLoading(false));
    }
  }
);
//////////////////
export const updateUserAsync = createAsyncThunk(
  "users/update",
  async ({ id, fields }, thunkAPI) => {
    thunkAPI.dispatch(setSelectedUserLoading(true)); // Set loading state
    const resp = await ServiceProvider.User.updateUser(id, fields);
    thunkAPI.dispatch(setSelectedUserLoading(false)); // Unset loading state

    if (!resp.errors) {
      thunkAPI.dispatch(getAllUsersAsync()); // Refresh user list after update
    }
    return resp;
  }
);
//////////////////



export const createUserAsync = createAsyncThunk(
  "users/create",
  async ({ model, filter }, thunkAPI) => {
    const resp = await ServiceProvider.User.create(model);

    if (!resp.errors) {
      thunkAPI.dispatch(getAllUsersAsync(filter));
    }

    return resp;
  }
);

export const deleteUserAsync = createAsyncThunk(
  "users/delete",
  async (id, thunkAPI) => {
    const resp = await ServiceProvider.User.delete(id);
    if (!resp.errors) {
      // Dispatch to refresh the list of users
      thunkAPI.dispatch(getAllUsersAsync());
    }
    return resp;
  }
);

export const users = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUserError: (state) => {
      state.error = null;
    },
    setSelectedUserLoading: (state, action) => {
      state.selectedUser.loading = action.payload || false;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(createUserAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(createUserAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(getAllUsersAsync.pending, (state) => {
        state.users.loading = true;
      })
      .addCase(getAllUsersAsync.fulfilled, (state, action) => {
        state.users.loading = false;
        const data = get(action, "payload.data.users", {
          results: [],
          count: 0,
        })
        state.users.results = data.results.map((d, rowIndex) => ({
          ...d,
          rowIndex,
        }));
        state.users.count = data.count;
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAllUsersAsync.rejected, (state, action) => {
        state.users.loading = false;
        state.error = getResponseError(action);
      })
      
      
      
      
      //////////////////
      .addCase(getUserByIdAsync.pending, (state) => {
        state.selectedUser.loading = true;
      })
      .addCase(getUserByIdAsync.fulfilled, (state, action) => {
        state.selectedUser.user = get(action, "payload.data.user", null);
        state.error = getGraphqlResponseError(action);
        state.selectedUser.loading = false;
      })
      .addCase(getUserByIdAsync.rejected, (state, action) => {
        state.selectedUser = {
          ...DEFAULT_USERS,
        };
        state.error = getResponseError(action);
      })
    //////////////////
    .addCase(updateUserAsync.pending, (state) => {
      state.selectedUser.loading = true;
    })
    .addCase(updateUserAsync.fulfilled, (state, action) => {
      const updatedUser = get(action, "payload.data.updateUser.results[0]", null);
      if (updatedUser) {
        state.selectedUser.user = updatedUser; // Update the selected user
      }
      state.error = getGraphqlResponseError(action);
      state.selectedUser.loading = false;
    })
    .addCase(updateUserAsync.rejected, (state, action) => {
      state.selectedUser.loading = false;
      state.error = getResponseError(action);
    }
  );
  //////////////////  
  },
});

export const { clearUserError , setSelectedUserLoading

} = users.actions;

//////////////////
export const makeSelectedUser = (state) =>
  state.user.selectedUser.user || null;
//////////////////

export const makeUserError = (state) => state.user.error;
export const makeUsers = (state) => state.user.users;

export default users.reducer;
