import React from "react";
import { useTranslation } from "react-i18next";
import "./Title.scss";

interface Props {
  text: string;
  children?: React.ReactElement;
}
const Title = ({ text, children }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="page-title">
      {t(text)} {children}
    </div>
  );
};

export default Title;
