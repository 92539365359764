import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "@client.services/provider";
import LocalStorageKeys from "@client.enums/localStorageKeys";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";
import CurrencyTypes from "@client.enums/currencyTypes";

const initialState = {
  profile: null,
  account: null,
  error: null,
  loading: false,
};

export const processLoginAsync = createAsyncThunk(
  "login/login",
  async ({ password, email }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await ServiceProvider.User.login(email, password);
      const login = get(response, "data.login", null);
      if (login) {
        ServiceProvider.LocalStorage.setItem(
          LocalStorageKeys.Token,
          login.token
        );
      }
      return response;
    } finally {
      thunkAPI.dispatch(setLoading(false));
    }
  }
);

export const getProfileAsync = createAsyncThunk(
  "login/profile",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await ServiceProvider.User.profile();
      if (response.errors) {
        ServiceProvider.LocalStorage.removeItem(LocalStorageKeys.Token);
      }
      return response;
    } finally {
      thunkAPI.dispatch(setLoading(false));
    }
  }
);

export const processForgotPasswordAsync = createAsyncThunk(
  "login/forgotPassword",
  async ({ email }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      await ServiceProvider.User.forgotPassword(email);
    } finally {
      thunkAPI.dispatch(setLoading(false));
    }
  }
);

const getCurrencyByCode = (currencyCode) => {
  return currencyCode ? CurrencyTypes[currencyCode] : CurrencyTypes.USD;
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = get(action, "payload", null);
    },
    clearLoginError: (state) => {
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = get(action, "payload", false);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(processLoginAsync.fulfilled, (state, action) => {
        state.profile = get(action, "payload.data.login.profile", null);
        if (state.profile !== null) {
          state.profile.currency = getCurrencyByCode(state.profile.currency);
        }
        state.account = get(action, "payload.data.login.account", null);
        state.error = getGraphqlResponseError(action);
      })
      .addCase(processLoginAsync.rejected, (state, action) => {
        state.profile = null;
        state.account = null;
        state.error = getResponseError(action);
      })
      .addCase(getProfileAsync.fulfilled, (state, action) => {
        state.profile = get(action, "payload.data.profile", null);
        if (state.profile !== null) {
          state.profile.currency = getCurrencyByCode(state.profile.currency);
        }
        state.account = get(action, "payload.data.account", null);
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getProfileAsync.rejected, (state, action) => {
        state.profile = null;
        state.account = null;
        state.error = getResponseError(action);
      })
      .addCase(processForgotPasswordAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(processForgotPasswordAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      });
  },
});

export const { setProfile, setLoginError, setLoading, clearLoginError } =
  loginSlice.actions;

export const makeProfileAccount = (state) => state.login.account;
export const makeProfile = (state) => state.login.profile;
export const makeLoginError = (state) => state.login.error;
export const makeLoginLoading = (state) => state.login.loading;

export default loginSlice.reducer;
