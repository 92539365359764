import React from "react";

const renderPermissionIcon = (
  status,
  permission,
  permissionType,
  onTogglePermission
) => {
  return (
    <span
      onDoubleClick={() => onTogglePermission(permission, permissionType)}
      className={["icon", status ? "allowed" : "disallowed"].join(" ")}
    >
      {status ? "✔" : "✖"}
    </span>
  );
};

const renderTooltip = (t, status) => {
  return t(
    status
      ? "pages.Permissions.status.tooltip.available"
      : "pages.Permissions.status.tooltip.notAvailable"
  );
};

export const getColumns = (t, onTogglePermission) => [
  {
    field: "name",
    headerName: t("pages.Permissions.column.permission"),
    minWidth: 200,
  },
  {
    field: "view",
    headerName: t("pages.Permissions.column.view"),
    cellRenderer: (params) =>
      renderPermissionIcon(
        params.value,
        params.data.name,
        "view",
        onTogglePermission
      ),
    tooltipValueGetter: ({ value }) => renderTooltip(t, value),
  },
  {
    field: "update",
    headerName: t("pages.Permissions.column.update"),
    cellRenderer: (params) =>
      renderPermissionIcon(
        params.value,
        params.data.name,
        "update",
        onTogglePermission
      ),
    tooltipValueGetter: ({ value }) => renderTooltip(t, value),
  },
  {
    field: "create",
    headerName: t("pages.Permissions.column.create"),
    cellRenderer: (params) =>
      renderPermissionIcon(
        params.value,
        params.data.name,
        "create",
        onTogglePermission
      ),
    tooltipValueGetter: ({ value }) => renderTooltip(t, value),
  },
  {
    field: "delete",
    headerName: t("pages.Permissions.column.delete"),
    cellRenderer: (params) =>
      renderPermissionIcon(
        params.value,
        params.data.name,
        "delete",
        onTogglePermission
      ),
    tooltipValueGetter: ({ value }) => renderTooltip(t, value),
  },
];
